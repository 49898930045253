/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */

// @flow
import React, { Component } from 'react';
import css from './style.css';
import PubSub from 'pubsub-js';
import { getOS} from '../../managers/resolutionManager';


type PropsT = {
    percentage: number,
    isWebGlLoaded: any,
    showLobbyContainer: func,
};
type StateT = {};
export default class ProgressBar extends Component<PropsT, StateT> {
    constructor(props) {
        super(props);
        this.state = {
            percentage: 0,
            loadingComplete: false,
            isWebGlLoaded: props.isWebGlLoaded,
        };
        this.displayWebgl = this.displayWebgl.bind(this);
        this.startFirstProgress = this.startFirstProgress.bind(this);
        this.startSecondProgress = this.startSecondProgress.bind(this);
        //this.getProgressBarSimulation = this.getProgressBarSimulation.bind(this);
    }
    static getDerivedStateFromProps(props, state) {
        return {
            ...state,
            isWebGlLoaded: props.isWebGlLoaded,
        };
    }

    componentDidMount() {
        // PubSub.subscribe('UNITY_PROGRESS_INDICATOR', (target, msg) => {
        //     let percentageVal = '';
        //     percentageVal = msg * 100;

        //     if (percentageVal > 0 && percentageVal <= 80) {
        //         this.setState({ percentage: percentageVal });
        //     }
        // });
        // PubSub.subscribe('PROGRESS_INDICATOR', (target, msg) => {
        //     const webglVal = msg * 100;
        //     if (webglVal > 80) {
        //         this.setState({ percentage: webglVal });
        //     }
        // });

        PubSub.subscribe('UNITY_PROGRESS_INDICATOR', (target, msg) => {
            this.startFirstProgress();
        });
        PubSub.subscribe('PROGRESS_INDICATOR', (target, msg) => {
            this.startSecondProgress();
        });
        // window.setInterval(() => {
        //     this.getProgressBarSimulation();
        // }, 100);
    }

    // getProgressBarSimulation() {
    //     const val = this.state.percentage + 1;
    //     console.log('what is value, ', val);
    //     this.setState({ percentage: val < 79 ? val : 79 });
    // }
    componentWillUnmount() {
        PubSub.unsubscribe('UNITY_PROGRESS_INDICATOR');
        PubSub.unsubscribe('PROGRESS_INDICATOR');
    }
    displayWebgl() {
        this.props.showLobbyContainer();
    }
    startFirstProgress(){
        PubSub.unsubscribe('UNITY_PROGRESS_INDICATOR');
        let value = 0
        const firstHalf = setInterval(()=>{
            const value1 = parseFloat(value + 0.55).toFixed(2);
            value = parseFloat(value1);
            console.log('Progress of Unity Loader 1st ::', value);
            this.setState({ percentage: value });
            if(value >= 50){
                clearInterval(firstHalf);
            }
        },50);
    }
    startSecondProgress(){
        PubSub.unsubscribe('PROGRESS_INDICATOR');
        let value = 50;
        const secondHalf = setInterval(()=>{
            const value1 = parseFloat(value + 0.55).toFixed(2);
            value = parseFloat(value1);
            console.log('Progress of Unity Loader 2nd ::', value);
            this.setState({ percentage: value });
            if(value >= 98){
                clearInterval(secondHalf);
            }
        },50);
    }

    render() {
        const loadingComplete = this.state.loadingComplete;
        return (
            <div>
                <LinearProgressBar
                    percentage={this.props.isWebGlLoaded ? 98 : this.state.percentage}
                    isLoadingComplete={loadingComplete}
                    gotoLobby={this.displayWebgl}
                    loaderText={
                        this.props.isWebGlLoaded
                            ? 'Your games are loaded!'
                            : 'Your games are loading ...'
                    }
                />
            </div>
        );
    }
}

const particle =  process.env.AWS_URL + 'particle.png?ver=' + process.env.IMAGE_VERSION;
const progress_bar_base =  process.env.AWS_URL + 'progress-bar-base.png?ver=' + process.env.IMAGE_VERSION;
const progress_bar_fill =  process.env.AWS_URL + 'progress-bar-fill.png?ver=' + process.env.IMAGE_VERSION;

const LinearProgressBar = (props) => {
    return (
        <div>
             {!props.isLoadingComplete &&  getOS() === 'Windows' && (
                <div className={css['progress-bar-windows']} style={{backgroundImage: `url(${progress_bar_base})`}}>
                    <Filler percentage={props.percentage} />
                    <div className={css.text_loader}>
                        <p className={css['text-style']}>
                            {/* <b>{props.loaderText}</b> */}
                        </p>
                    </div>
                </div>
            )}
            {!props.isLoadingComplete &&  getOS() !== 'Windows' && (
                <div className={css['progress-bar']} style={{backgroundImage: `url(${progress_bar_base})`}}>
                    <Filler percentage={props.percentage} />
                    <div className={css.text_loader}>
                        <p className={css['text-style']}>
                            {/* <b>{props.loaderText}</b> */}
                        </p>
                    </div>
                </div>
            )}
        </div>
    );
};

const Filler = (props) => {
    return (
        <div className={css.filler} style={{ width: `${props.percentage}%`, backgroundImage: `url(${progress_bar_fill})` }}>
            {/* {props.percentage > 5 &&
                props.percentage <= 95  && getOS() !== 'Windows' && <div className={css['progress-animate-sprite']} style={{backgroundImage: `url(${particle})`}} />}
            {props.percentage > 5 &&
                props.percentage <= 95  && getOS() === 'Windows' && <div className={css['progress-animate-sprite-windows']} style={{backgroundImage: `url(${particle})`}} />} */}

        </div>
    );
};
