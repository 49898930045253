import { connect } from 'react-redux';
import ImagePreview from './ImagePreview';
import { closeModal } from '../../store/app/actions';

const mapStateToProps = () => ({ });

const mapDispatchToProps = {closeModal};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ImagePreview);
