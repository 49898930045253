'use strict';
class AudioManager {
    constructor() {
        if (!AudioManager.audioManagerInstance) {
            AudioManager.audioManagerInstance = this;
        }
        this._audioSourceMap = {
            CasinoAmbience: 'CasinoTheme_Disco.mp3',
            MouseOver: 'SFX_Mouse_Over_1.wav',
            PopUpSwipeIn: 'SFX_PopUP_SwipeIn.wav',
            PopUpSwipeOut: 'SFX_PopUp_Swipeout.wav',
            PageSwipe: 'SFX_Swipe_1.wav',
            HourlyBonusCollect: 'SFX_DOND_CollectionSound.mp3',
        };
        this._audioList = {};
        return AudioManager.audioManagerInstance;
    }
    init(options) {
        this.isLobbyMusicEnabled = options.lobbyMusicEnabled;
        this.sfxEnabled = options.isSfxEnabled;
        this.baseUrl = options.baseUrl;
        this.setupAudioForLobby();
    }
    setSFXmusic(isEnabled) {
        this.sfxEnabled = isEnabled;
    }
    setupAudioForLobby() {
        for (const key in this._audioSourceMap) {
            const url = this.baseUrl + '/' + this._audioSourceMap[key];
            this._audioList[key] = new Audio(url);
        }
    }
    playLobby() {
        this._audioList.CasinoAmbience.load();
        this._audioList.CasinoAmbience.play();
    }



    playLobbyMusic() {
        this._audioList.CasinoAmbience.play();
    }
    pauseLobbyMusic() {
        this._audioList.CasinoAmbience.pause();
    }

    playAudio(item) {
        if (this._audioList[item] && this.sfxEnabled) this._audioList[item].play();
    }
}

const audioManagerInstance = new AudioManager();
export default audioManagerInstance;
