// @flow
import * as React from 'react';
// import { connect } from 'react-redux';
//import Lobby from './components/Lobby';
import Host from './components/Host';
import ModalManager from './components/common/Modal/ModalManager';
import css from './App.css';
import { withCookies } from 'react-cookie';
// import { openHost } from './store/app/actions';

type PropsT = {
    cookies: any,
};

class App extends React.PureComponent<PropsT> {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        console.log(this.props.cookies);
        return (
            <div className={css.wrapper}>
                <ModalManager />
                {/* <Lobby cookies={this.props.cookies} /> */}
                <Host cookies={this.props.cookies} />
            </div>
        );
    }
}

export default withCookies(App);
