// @flow
import * as React from 'react';
import css from './style.css';
import HighLowIcon from '../HighLowIcon/HighLowIcon';
import analyticsManagerInstance from '../../managers/analyticsManager';
import analyticslog from '../../constants/analyticslog';
import { PLATFORM_GSNCOM } from '../../constants/matcher';

type PropsT = {
    closeModal: func,
    openMiniGame: func,
    isContainerDisplayed: boolean,
    openModal: func,
    displayContainer: func,
    seamSession: string,
    gsnCredentials: any,
    fbUserId: string,
    country: string,
    operatingSystem: string,
    platform: string,
};
type StateT = {};
const analyticsLogs = new analyticslog();

export default class PreLobbyLoader extends React.Component<PropsT, StateT> {
    constructor(props) {
        super(props);
        this.state = {
            isContainerDisplayed: props.isContainerDisplayed,
            // showHighLowTable: false,
            showPlayButton: false,
            showWinUpto: false,
            showFreeTokensText: false,
            // showHighLowText: false,
            // showHighLowButtons: false,
            // showGameText: false,
        };
        this.letsPlay = this.letsPlay.bind(this);
    }
    static getDerivedStateFromProps(props, state) {
        return {
            ...state,
            isContainerDisplayed: props.isContainerDisplayed,
        };
    }
    componentDidMount() {
        let userId;
        if(this.props.platform === PLATFORM_GSNCOM){
            userId = this.props.gsnCredentials && this.props.gsnCredentials.authResponse && this.props.gsnCredentials.authResponse.userID;
        }else{//FOR FB
            userId = this.props.fbUserId;
        }
        //Analytics Start
        const lodData = {
            eventGroup: 'STARTUP',
            eventCode: 'MINIGAME',
            eventSubCode: 'ENTER',
            sessionToken: this.props.seamSession.sessionToken,
            eventAttributes: {
                game_name: 'hi-low',
                from_where: 'seam_auth',
            },
            deviceId: userId,
            country: this.props.country,
            operatingSystem: this.props.operatingSystem,
            appVersion: this.props.appVersion
        };
        const payload = analyticsLogs.setAnalyticsData(lodData);
        analyticsManagerInstance.logAnalytics(payload).then(() => {});
        //Analytics End
        // this.showHighLowTableTimer = setTimeout(() => {
        //     this.setState({ showHighLowTable: true });
        // }, 10);
        // this.showHighLowTextTimer = setTimeout(() => {
        //     this.setState({ showHighLowText: true });
        // }, 2000);
        // this.showHighLowButtonsTimer = setTimeout(() => {
        //     this.setState({ showHighLowButtons: true });
        // }, 2000);
        // this.showGameTextTimer = setTimeout(() => {
        //     this.setState({ showGameText: true });
        // }, 20000);
        this.showPlayButtonTimer = setTimeout(() => {
            this.setState({ showPlayButton: true });
        }, 2000);
        this.showWinUptoTimer = setTimeout(() => {
            this.setState({ showWinUpto: true });
        }, 2000);
        this.showFreeTokenTimer = setTimeout(() => {
            this.setState({ showFreeTokensText: true });
        }, 1500);
    }

    componentWillUnmount() {
        // clearTimeout(this.showHighLowTableTimer);
        clearTimeout(this.showPlayButtonTimer);
        // clearTimeout(this.showHighLowTextTimer);
        // clearTimeout(this.showHighLowButtonsTimer);
        // clearTimeout(this.showGameTextTimer);
        clearTimeout(this.showWinUptoTimer);
        clearTimeout(this.showFreeTokenTimer);
    }

    letsPlay() {
        this.props.openMiniGame();
    }
    render() {
        const bg_prelobby =  process.env.AWS_URL + 'bg.png?ver=' + process.env.IMAGE_VERSION;
        const btn_lets_play =  process.env.AWS_URL + 'btn-lets-play.png?ver=' + process.env.IMAGE_VERSION;
        const winuptoImg =  process.env.AWS_URL + 'winupto-text.png?ver=' + process.env.IMAGE_VERSION;
        const freeGameImg =  process.env.AWS_URL + 'pre_lobby.png?ver=' + process.env.IMAGE_VERSION;

        return (
            <div>
                {!this.state.isContainerDisplayed && (
                    <div>
                        <div className={css.game_icon_placeholder}>
                            <div className={css.game_icon} style={{backgroundImage: `url(${bg_prelobby})`}}>
                                {this.state.showFreeTokensText && (
                                    <div className={css.text_placeholder_free_tokens}>
                                        <img src={freeGameImg} style={{ width: '100%' }} />
                                    </div>
                                )}
                                <HighLowIcon icon="main_page" />
                                {this.state.showWinUpto && (
                                    <div className={css.text_placeholder}>
                                        <img src={winuptoImg} style={{ width: '100%' }} />
                                    </div>
                                )}
                            </div>
                        </div>
                        {this.state.showPlayButton && (
                            <div className={css.lets_play_button_placeholder}>
                                <div className={css.lets_play_button} onClick={this.letsPlay} style={{backgroundImage: `url(${btn_lets_play})`}}/>
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    }
}
