import { connect } from 'react-redux';
import Settings from './Settings';
import { toggleLobbyMusic, toggleSFXmusic } from '../../store/app/actions';

const mapStateToProps = (state) => {
    return {
        lobbyMusicEnabled: state.app.userConfig.lobbyMusicEnabled,
        isEuCitizen: state.app.userConfig.isEuropeanCitizen,
        isSfxEnabled: state.app.userConfig.sfxEnabled,
        baseUrl: state.app.gsnConfig.cdnUrl,
    };
};


const mapDispatchToProps = {
    toggleLobbyMusic,
    toggleSFXmusic,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Settings);
