// @flow
import type { LocaleT } from './types';

export const ActionTypes = {
    SETLOCALE: 'app/set-locale',
    TOGGLE_LOBBY_MUSIC: 'app/toggle-lobby-music',
    TOGGLE_SFX_MUSIC: 'app/toggle-sfx-music',
    OPEN_MODAL: 'app/open-modal',
    CLOSE_MODAL: 'app/close-modal',
    SET_MODAL_STATE: 'app/set-modal-state',
    UPDATE_TOKEN_BALANCE: 'app/set-token-balance',
    OPEN_HOST: 'app/open-host',
    CLOSE_HOST: 'app/close-host',
    GET_TOKEN_BALANCE: 'app/get-token-balance',
    GET_SEAM_SESSION: 'app/get-seam-session',
    SET_SEAM_SESSION: 'app/set-seam-session',
    GET_GAME_REFRESH_RATE: 'app/get-game-refresh-rate',
    SET_GAME_REFRESH_RATE: 'app/set-game-refresh-rate',
    CHECK_CONTAINER: 'app/check-container',
    IS_WEBGL_LOADED: 'app/is-webgl-loaded',
    SET_FB_DATA: 'app/set-fb-data',
    UPDATE_GAME_PLAY_COUNT: 'app/update-game-play-count',
    MINI_GAME_OVER: 'app/mini-game-over',
    COUNT_WIN: 'app/count-win',
    UPDATE_GAME_ROUND: 'app/update_game_round',
    COUNT_WIN_TEXT: 'app/count_win_text',
    TOTAL_WIN_AMOUNT: 'app/total_win_amount',
    SET_FB_USER_ID: 'app/set_fb_user_id',
    TOKENS_WON_PER_ROUND: 'app/set_update_tokens_per_round',
    GAME_MATH_MODEL: 'app/set_game_math_model',
    SET_LEFT_CARD_VALUE: 'app/set_left_card_val',
    SET_RIGHT_CARD_VALUE: 'app/set_right_card_val',
    BUTTON_CLICKED: 'app/set_button_clicked',
    SET_GSN_CREDENTIALS: 'app/set_gsn_credentials',
};

export const setLocale = (locale: LocaleT) => ({
    type: ActionTypes.SETLOCALE,
    payload: locale,
});
export const updateTokenBalance = (v) => ({ type: ActionTypes.UPDATE_TOKEN_BALANCE, payload: v });
export const toggleLobbyMusic = (v) => ({ type: ActionTypes.TOGGLE_LOBBY_MUSIC, payload: v });
export const toggleSFXmusic = (v) => ({ type: ActionTypes.TOGGLE_SFX_MUSIC, payload: v });
export const openModal = (modalType, modalProps) => ({
    type: ActionTypes.OPEN_MODAL,
    payload: { modalType, modalProps },
});
export const closeModal = () => ({ type: ActionTypes.CLOSE_MODAL });
export const setModalState = (v) => ({ type: ActionTypes.SET_MODAL_STATE, payload: v });

export const openHost = (hostType, hostProps) => ({
    type: ActionTypes.OPEN_HOST,
    payload: { hostType, hostProps },
});
export const closeHost = () => ({ type: ActionTypes.CLOSE_HOST });
export const getTokenBalance = (v) => ({ type: ActionTypes.GET_TOKEN_BALANCE, payload: v });
export const getSeamSession = (v) => ({ type: ActionTypes.GET_SEAM_SESSION, payload: v });
export const setSeamSession = (v) => ({ type: ActionTypes.SET_SEAM_SESSION, payload: v });
export const setGSNCredentials = (v) => ({ type: ActionTypes.SET_GSN_CREDENTIALS, payload: v });
export const getRefreshRate = (v) => ({ type: ActionTypes.GET_GAME_REFRESH_RATE, payload: v });
export const setRefreshRate = (v) => ({ type: ActionTypes.SET_GAME_REFRESH_RATE, payload: v });
export const checkContainer = (v) => ({
    type: ActionTypes.CHECK_CONTAINER,
    payload: v,
});
export const checkifWebglLoaded = (v) => ({
    type: ActionTypes.IS_WEBGL_LOADED,
    payload: v,
});

export const setFbData = (v) => ({
    type: ActionTypes.SET_FB_DATA,
    payload: v,
});
export const registerGameCount = (v) => ({ type: ActionTypes.UPDATE_GAME_PLAY_COUNT, payload: v });
export const isMiniGameOver = (v) => ({ type: ActionTypes.MINI_GAME_OVER, payload: v });
export const updateWinCount = (v) => ({ type: ActionTypes.COUNT_WIN, payload: v });
export const updateGameRound = (v) => ({ type: ActionTypes.UPDATE_GAME_ROUND, payload: v });
export const updateWinText = (v) => ({ type: ActionTypes.COUNT_WIN_TEXT, payload: v });
export const calculateTotalWinAmount = (v) => ({ type: ActionTypes.TOTAL_WIN_AMOUNT, payload: v });
export const setFBUserId = (v) => ({ type: ActionTypes.SET_FB_USER_ID, payload: v });
export const updateTokensWonPerRound = (v) => ({
    type: ActionTypes.TOKENS_WON_PER_ROUND,
    payload: v,
});
export const setGameMathModel = (v) => ({
    type: ActionTypes.GAME_MATH_MODEL,
    payload: v,
});
export const setLeftCardVal = (v) => ({ type: ActionTypes.SET_LEFT_CARD_VALUE, payload: v });
export const setRightCardVal = (v) => ({ type: ActionTypes.SET_RIGHT_CARD_VALUE, payload: v });
export const setButtonClicked = (v) => ({ type: ActionTypes.BUTTON_CLICKED, payload: v });
