import { connect } from 'react-redux';
import PayPalCheckOut from './PayPalCheckOut';
import { closeModal} from '../../store/app/actions';

const mapStateToProps = (state) => {
    return state;
};

const mapDispatchToProps = { closeModal };

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PayPalCheckOut);
