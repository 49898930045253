// @flow
import * as React from 'react';
import css from './style.css';
import PubSub from 'pubsub-js';
import _ from 'lodash';

type PropsT = {
    cardDetails: any,
    gameRoundNo: number,
};

type StateT = {};
const findItems = (data, criteria) => {
    const x = data && _.find(data, criteria);
    return x[criteria];
};
export default class Card extends React.Component<PropsT, StateT> {
    constructor(props) {
        super(props);
        this.state = {
            showLeftCard: false,
            numberOfTimes: 0,
            showRightCard: false,
            shakeRightCard: false,
            leftCardUrl: '',
            rightCardUrl: '',
            imgUrlList: '',
        };
    }

    componentDidMount() {
        if (this.props.gameRoundNo === 1) {
            this.timerHandle = setTimeout(() => {
                this.setState({ showLeftCard: true });
            }, 500);
        } else {
            this.timerHandle = setTimeout(() => {
                this.setState({ showLeftCard: true });
            }, 50);
        }

        this.timerHandle2 = setTimeout(() => {
            this.setState({ showRightCard: true });
            this.timerHandle2 = 0;
        }, 500);

        PubSub.subscribe('SHAKE_CARD', (target, msg) => {
            if (msg) {
                this.setState({ shakeRightCard: true });
            }
        });

        PubSub.subscribe('CARD_LIST', (target, msg) => {
            if (msg) {
                this.setState({ imgUrlList: msg });
                const rightCardUrl = findItems(msg, 'cardBack');
                const leftCardUrl = findItems(msg, 'cardDisplayed');
                this.setState({ rightCardUrl: rightCardUrl });
                this.setState({ leftCardUrl: leftCardUrl });
            }
        });
    }

    componentWillUnmount = () => {
        clearTimeout(this.timerHandle);

        if (this.timerHandle2) {
            clearTimeout(this.timerHandle2);
            this.timerHandle2 = 0;
        }
        PubSub.unsubscribe('SHAKE_CARD');
        PubSub.unsubscribe('CARD_LIST');
    };

    processImgurlList() {
        const cardSymbol = this.props.cardDetails.cardSymbol;
        let urlVal = '';
        if (cardSymbol === 'h') {
            urlVal = findItems(this.state.imgUrlList, 'h');
        } else if (cardSymbol === 'd') {
            urlVal = findItems(this.state.imgUrlList, 'd');
        } else if (cardSymbol === 'c') {
            urlVal = findItems(this.state.imgUrlList, 'c');
        } else if (cardSymbol === 's') {
            urlVal = findItems(this.state.imgUrlList, 's');
        }
        return urlVal;
    }

    render() {
        const details = this.props.cardDetails;
        const cardClass =
            this.props.cardDetails.cssName === 'cardDetailsCss' ? css.leftCard : css.rightCard;
        const cardColor =
            this.props.cardDetails.cardSymbol === 'h' || this.props.cardDetails.cardSymbol === 'd'
                ? 'rgb(206, 63, 58)'
                : 'rgba(7, 15, 23, 0.9)';
        return (
            <div>
                {details.cardSymbol &&
                    details.cardValue && (
                        <div className={cardClass}>
                            <div
                                style={{
                                    backgroundImage: `url(${this.state.leftCardUrl})`,
                                    width: '100%',
                                    height: '100%',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: '100% 100%',
                                }}
                            >
                                {this.state.showLeftCard && (
                                    <div className={css.flexbox}>
                                        <div className={css['flex-style-value']}>
                                            <div
                                                style={{ color: cardColor, marginLeft: '4%' }}
                                                className={css.cardValue}
                                            >
                                                {details.cardValue}
                                            </div>
                                        </div>
                                        <div className={css['flex-style-symbol']}>
                                            <div
                                                style={{
                                                    backgroundImage: `url(${this.processImgurlList()})`,
                                                    width: '100%',
                                                    height: '100%',
                                                    backgroundRepeat: 'no-repeat',
                                                    backgroundSize: '100% 100%',
                                                }}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                {!details.cardSymbol &&
                    this.state.showRightCard && (
                        <div className={css['card-flipped-placeholder']}>
                            {this.state.shakeRightCard && (
                                <div className={css['card-flipped-animation']} />
                            )}
                            {!this.state.shakeRightCard && (
                                <div
                                    className={css['card-flipped']}
                                    style={{
                                        backgroundImage: `url(${this.state.rightCardUrl})`,
                                    }}
                                />
                            )}
                        </div>
                    )}
            </div>
        );
    }
}
