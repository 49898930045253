//@flow
import * as React from 'react';
import css from './style.css';
import { getOS } from '../../managers/resolutionManager';
import { updateMacLinks, updateWinLinks } from '../../constants/matcher';
import analyticsManagerInstance from '../../managers/analyticsManager';
import analyticslog from '../../constants/analyticslog';

type PropsT = {
    fbUserId: any,
    country: string,
    operatingSystem: string,
};
type StateT = {};
const analyticsLogs = new analyticslog();

export default class IncompatibleBrowser extends React.Component<PropsT, StateT> {
    constructor(props) {
        super(props);
        this.state = {
            seamUser:  this.props.seamUser,
        };
        this.continueFromError = this.continueFromError.bind(this);
    }
    componentDidMount() {
        const browserName = this.props.webGLSupport.browserDetails.browserName;
        const browserVersion = this.props.webGLSupport.browserDetails.browserVersion;
        const type = this.props.webGLSupport.type;
        const lobbyStartTime = this.props.lobbyStartTime;
        const time_from_start = new Date().getTime() - lobbyStartTime;

        //Analytics Start
        const lodData = {
            eventGroup: 'STARTUP',
            eventCode: 'SCREEN',
            eventSubCode: 'OPEN',
            sessionToken: this.state.seamUser && this.state.seamUser.session_token,
            eventAttributes: {
                ui_element: type === 'USB' ? 'browser_unsupported' : 'browser_upgrade',
                from_where: 'authorizeAppWithSeam',
                attr2: time_from_start,
            },
            deviceId: this.props.fbUserId,
            country: this.props.country,
            operatingSystem: this.props.operatingSystem,
        };
        const payload = analyticsLogs.setAnalyticsData(lodData);
        analyticsManagerInstance.logAnalytics(payload).then(() => {});
        //Analytics End
    }

    continueFromError() {
        //Analytics Start
        const lodData = {
            eventGroup: 'STARTUP',
            eventCode: 'BUTTON',
            eventSubCode: 'CLICK',
            sessionToken: this.state.seamUser && this.state.seamUser.session_token,
            eventAttributes: {
                ui_element: 'browser_upgrade',
                from_where: 'authorizeAppWithSeam',
            },
            deviceId: this.props.fbUserId,
            country: this.props.country,
            operatingSystem: this.props.operatingSystem,
        };
        const payload = analyticsLogs.setAnalyticsData(lodData);
        analyticsManagerInstance.logAnalytics(payload).then(() => {});
        //Analytics End
        const url =
            getOS() === 'Windows'
                ? updateWinLinks[this.props.webGLSupport.browserDetails.browserName]
                : updateMacLinks[this.props.webGLSupport.browserDetails.browserName];
        window.open(url, '_blank');
    }

    render() {
        const type =
            this.props.webGLSupport && this.props.webGLSupport.type === 'UPB' ? true : false;
        const errorClass =
            getOS() === 'Windows'
                ? css.lobby_notready_placeholder
                : css.lobby_notready_placeholder_other;
        return (
            <div className={`row ${errorClass}`}>
                {type && (
                    <div className={`col-md-12`}>
                        <div className={`row`}>
                            <div className={`col-md-12 ${css.text_1}`}>
                                Your current browser version does not support our game.
                            </div>
                        </div>
                        <div className={`row`}>
                            <div className={`col-md-12 ${css.text_2}`}>
                                Please update your browser to the latest version for the best GSN
                                Casino experience.
                            </div>
                        </div>
                        <div className={`row ${css.button_container}`}>
                            <div className={`col-md-5`} />
                            <div className={`col-md-4`}>
                                <button className={css.button} onClick={this.continueFromError}>
                                    <div>Update</div>
                                </button>
                            </div>
                            <div className={`col-md-3`} />
                        </div>
                    </div>
                )}
                {!type && (
                    <div className={`col-md-12`}>
                        <div className={`row`}>
                            <div className={`col-md-12 ${css.text1}`}>
                                Your current browser is not supported.
                            </div>
                        </div>
                        <div className={`row`}>
                            <div className={`col-md-12 ${css.text2}`}>
                                We recommend that you use the latest version of Chrome, Firefox,
                                Microsoft Edge or Safari for the best GSN Casino experience.
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}
