import { connect } from 'react-redux';
import Host from './Host';

const mapStateToProps = (state) => {
    return {
        hosts: state.app.hostQueue,
    };
};

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Host);
