// @flow
import * as React from 'react';
import { DFPSlotsProvider, AdSlot, DFPManager } from 'react-dfp';
import {PUBLISHER_ID_GSN_FB} from '../../constants/matcher';

type PropsT = {
    closeModal: func,
    setRefreshRate: func,
    getRefreshRate: func,
    seamSession: any,
    refreshRate:  number,
};
type StateT = {};
const getTag = ()=>{
    return `div-gpt-ad-${Math.random() * 1000000000000000000}`;
}

const Ad900Slot = (props)=> {
    return (
        <AdSlot
            sizes={props.data.adsSizes[0] ? props.data.adsSizes[0].sizes : []}
            adUnit={`${props.data.zoneIdAds}/${props.data.adsToken}`}
            shouldRefresh={() => false}
            onSlotRender={eventData => props.slotRender(eventData)}
        />
    );
}

const DFPHome =(props)=>{
    return (
        <DFPSlotsProvider
            dfpNetworkId={props.data.publisherId}
            targetingArguments={props.adsTargetArguments}
            sizeMapping={props.data.adsSizes}
            collapseEmptyDivs
        >
            <div id={props.data.gptTagHomeAds} className="gpt-container-banner">
                <Ad900Slot data ={props.data} slotRender={(data)=>props.slotRender(data)}/>
            </div>
        </DFPSlotsProvider>
    );
}

export default class AdsHome extends React.Component<PropsT, StateT> {
    constructor(props) {
        super(props);
        this.state = {
            tagId: '',
            adsSizes: [],
            adsTargetArguments: this.props.adsTargetArguments,
            publisherId: PUBLISHER_ID_GSN_FB,
            zoneIdAds: this.props.adsChannel,
            gptTagHomeAds: getTag(),
            getValue: this.props.refreshRate,
            seamSession: this.props.seamSession,
            adsToken: this.props.adsToken,
            refreshEnabled: this.props.refreshEnabled,
        };
        this.getSizeMap = this.getSizeMap.bind(this);
        this.createTagIdHomeAds = this.createTagIdHomeAds.bind(this);
        this.slotRender=this.slotRender.bind(this);
    }
    static getDerivedStateFromProps(props, state) {
        return {
            ...state,
            adsToken: props.adsToken,
            adsTargetArguments: props.adsTargetArguments,
        };
    }
    componentDidMount() {
        this.getSizeMap();
        const refreshTime = this.state.getValue ? parseInt(this.state.getValue) * 1000 : 60000;
        const refreshEnabled = this.state.refreshEnabled;
        console.log('Ads Refresh Rate', refreshTime);
        console.log('Ads Refresh Enabled', refreshEnabled);
        if (refreshTime > 0 && refreshEnabled) {
            this.interval = window.setInterval(() => {
                DFPManager.refresh();
                this.createTagIdHomeAds();
                console.log('Ads refreshed!!!');
            }, refreshTime);
        } else {
            window.clearInterval(this.interval);
        }
    }

    componentWillUnmount() {
        window.clearInterval(this.interval);
    }
    getSizeMap() {
        this.setState({
            adsSizes: [
                { viewport: [1024, 768], sizes: [[728, 90]] },
            ],
        });
    }
    adsRefresh() {
        // This value will come from, freature config - adsRefreshRate
        return 60000;
    }
    createTagIdHomeAds() {
        this.setState({ gptTagHomeAds: getTag()});
    }

    slotRender(event){
        if(!event.event.isEmpty){
            console.log('ADS AVAILABLE');
        }else{
            console.log('ADS NOT AVAILABLE');
        }
    }

    render() {
        const showGameAds = this.props.showGameAds;
        const adsToken = this.state.adsToken ? this.state.adsToken.split('_')[0] : this.state.adsToken;
        return (
            <div id='mesmo-gl-ads'>
                <div className={`row`} style={{marginTop:'5px'}}>
                    <div className={'col-12'}>
                        <DFPHome data={this.state} adsTargetArguments={this.state.adsTargetArguments} slotRender={(data)=>this.slotRender(data)} />
                    </div>
                </div>
            </div>
        );
    }
}
if (typeof window !== 'undefined') {
    DFPManager.load();
}
