// @flow
import * as React from 'react';
import css from './style.css';

type PropsT = {
    icon: string,
};
type StateT = {};

export default class HighLowIcon extends React.Component<PropsT, StateT> {
    constructor() {
        super();
        this.state = {
            showHighLowText: false,
            showHighLowButtons: false,
            showGameText: false,
        };
    }
    componentDidMount() {
        this.showHighLowTextTimer = setTimeout(() => {
            this.setState({ showHighLowText: true });
        }, 700);
        this.showHighLowButtonsTimer = setTimeout(() => {
            this.setState({ showHighLowButtons: true });
        }, 800);
        this.showGameTextTimer = setTimeout(() => {
            this.setState({ showGameText: true });
        }, 900);
    }

    componentWillUnMount() {
        clearTimeout(this.showHighLowTextTimer);
        clearTimeout(this.showHighLowButtonsTimer);
        clearTimeout(this.showGameTextTimer);
    }
    render() {
        let cssName = '';
        let hiPlaceholder = '';
        let loPlaceholder = '';
        let arrPlaceholder = '';
        let gameTxtPlaceholder = '';

        const down_arrow =  process.env.AWS_URL + 'down_arrow.png?ver=' + process.env.IMAGE_VERSION;
        const hi =  process.env.AWS_URL + 'hi.png?ver=' + process.env.IMAGE_VERSION;
        const lo =  process.env.AWS_URL + 'lo.png?ver=' + process.env.IMAGE_VERSION;
        const Text_game =  process.env.AWS_URL + 'Text_game.png?ver=' + process.env.IMAGE_VERSION;
        const up_arrow =  process.env.AWS_URL + 'up_arrow.png?ver=' + process.env.IMAGE_VERSION;

        if (this.props.icon === 'main_page') {
            cssName = css.hi_lo_game_main_placeholder;
        } else if (this.props.icon === 'try_again') {
            cssName = css.hi_lo_game_try_again_placeholder;
        } else {
            cssName = css['high-lo-small-icon-placeholder'];
        }

        if (this.props.icon === 'main_page') {
            hiPlaceholder = css.hi_img_placeholder_1;
        } else if (this.props.icon === 'try_again') {
            hiPlaceholder = css.hi_img_placeholder_2;
        } else {
            hiPlaceholder = css.hi_img_placeholder;
        }

        if (this.props.icon === 'main_page') {
            gameTxtPlaceholder = css.game_placeholder;
        } else if (this.props.icon === 'play_game') {
            gameTxtPlaceholder = css.game_placeholder_title;
        } else {
            gameTxtPlaceholder = css.game_placeholder_try_again;
        }

        if (this.props.icon === 'main_page') {
            loPlaceholder = css.lo_img_placeholder_1;
        } else {
            loPlaceholder = css.lo_img_placeholder;
        }

        if (this.props.icon === 'main_page') {
            arrPlaceholder = css.arrow_placeholder_1;
        } else {
            arrPlaceholder = css.arrow_placeholder;
        }

        return (
            <div className={cssName}>
                {this.state.showHighLowText && (
                    <div className={hiPlaceholder}>
                        <div className={css.hi_img} style={{backgroundImage: `url(${hi})`}}/>
                    </div>
                )}
                {this.state.showHighLowText && (
                    <div className={loPlaceholder}>
                        <div className={css.lo_img} style={{backgroundImage: `url(${lo})`}}/>
                    </div>
                )}
                {this.state.showHighLowButtons && (
                    <div className={arrPlaceholder}>
                        <div className={css.up_img_placeholder}>
                            <div className={css.up_img} style={{backgroundImage: `url(${up_arrow})`}}/>
                        </div>
                        <div className={css.down_img_placeholder}>
                            <div className={css.down_img} style={{backgroundImage: `url(${down_arrow})`}}/>
                        </div>
                    </div>
                )}
                {this.state.showGameText && (
                    <div className={gameTxtPlaceholder}>
                        <div className={css.game_text} style={{backgroundImage: `url(${Text_game})`}}/>
                    </div>
                )}
            </div>
        );
    }
}
