import { connect } from 'react-redux';
import HighLowGame from './HighLowGame';
import {
    openModal,
    registerGameCount,
    isMiniGameOver,
    updateGameRound,
} from '../../store/app/actions';

const mapStateToProps = (state) => ({
    noOfTimesPlayed: state.app.userConfig.noOfTimesPlayed,
    WebglLoaded: state.app.userConfig.isWebGlLoaded,
    gameIsOver: state.app.userConfig.gameIsOver,
    countWin: state.app.userConfig.countWin,
    gameRoundNo: state.app.userConfig.gameRoundNo,
    seamSession: state.app.userConfig.seamSession,
    gsnCredentials: state.app.userConfig.gsnCredentials,
    fbUserId: state.app.userConfig.fbUserId,
    seamTokenBalance: state.app.userConfig.seamTokenBalance,
    platform: state.app.platform,
});

const mapDispatchToProps = { openModal, registerGameCount, isMiniGameOver, updateGameRound };

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HighLowGame);
