import { connect } from 'react-redux';
import CommonError from './CommonError';

const mapStateToProps = (state) => {
    return state;
};

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CommonError);
