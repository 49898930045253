/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
// @flow
import React, { Component } from 'react';
import PubSub from 'pubsub-js';
import _ from 'lodash';
import countriesTimezones from 'countries-and-timezones';
import {PROPS} from '../../constants/props';
import audioManager from '../../managers/audioManager';
import loginToFacebook, { getFacebookUserProfile } from '../../services/common/facebook';
import loginToGSN, { getGSNUserProfile }  from '../../services/common/gsnLogin';
import {
    FC_ADS_TOGGLE,
    FC_ADS_REFRESH_RATE,
    COUNTRY_LOOKUP,
    ZONE_ID_ADS_FB,
    FC_MINI_GAME_MATH,
    FC_MINI_GAME_MAX_TOKENS,
    FC_EVENTS_ENGINEERING,
    FC_ADS_WHITELIST,
    FC_CONSENT_CONFIG,
    ENG_ERROR,
    ZOOM_REST_LIST,
    PLATFORM_GSNCOM,
    PLATFORM_FB,
    FC_SHOW_HILO,
    FC_LOADING_GAMESLIST,
    FC_WEBGL_ROLLOUT,
    ENG_EVENT
} from '../../constants/matcher';
import SeamServices from '../../services/seamService';
import analyticsManagerInstance from '../../managers/analyticsManager';
import analyticslog from '../../constants/analyticslog';
import NetworkServices from '../../services/networkServices';
import Gdpr from '../Gdpr';
import Ccpa from '../Ccpa';
import CommonError from '../CommonError';
import AccountInformation from '../AccountInformation';
import ImagePreview from '../ImagePreview'
import PayPalCheckOut from '../PayPalCheckOut'
import IncompatibleBrowser from '../IncompatibleBrowser';
import MiniLobbyLoader from '../MiniLobbyLoader';
import Promises from 'bluebird';
import css from './style.css';
import webglManager from '../../managers/webGlManager';
import AdsHome from '../AdsHome';
import AdsGame from '../AdsGame';
import Ads300X50_1 from '../Ads300X50_1';
import Ads300X50_2 from '../Ads300X50_2';
import AdsVideo from '../AdsVideo';
import Settings from '../Settings';
import PreLobbyLoader from '../PreLobbyLoader';
import ProgressBar from '../ProgressBar';
import HighLowGame from '../HighLowGame';
import Navigation from '../Navigation';
import {getOS, getCanvasResolutionOnLoad, setCustomResolution } from '../../managers/resolutionManager';
import {getOptionalBuild} from '../../constants/loadOptionalBuild';
import contactSupport from '../../managers/contactSupport';


type PropsT = PROPS;
type StateT = {};
const analyticsLogs = new analyticslog();
const findItem = (data, criteria) => _.find(data, ['_key', criteria]);
export default class Lobby extends Component<PropsT, StateT> {
    constructor(props) {
        super(props);
        this.state = {
            webglLoaded: false,
            showWebGL: false,
            authenticateComplete: false,
            adsRefreshRate: 0,
            showAds: false,
            showAds_ccpa_users: true,
            logEngineeringEvents: true,
            userProfileImage: '',
            showGameAds: true,
            adsToken: 'games_highlow',
            adsTargetArguments: { dept: 'games', title: 'highlow', pgid: 'play' },
            adsChannel: ZONE_ID_ADS_FB,
            showGoToLobby: false,
            userFbData: '',
            displayHiLoGame: false,
            displayPreLoader: false,
            FBResForDevice: '',
            seamUser: '',
            isFullScreen: false,
            fullscreenTrueCount: 0,
            isDisconnected: false,
            isContainerError: 0,
            isCommonError: false,
            gameIsOver: props.gameIsOver,
            osName: '',
            renderHeight: '100%',
            renderWidth: '100%',
            maxTokensAllowed: 0,
            tokensUserWon: 0,
            displayMaxTokensReached: false,
            startTime: 0,
            loginTimeSyncDelay: 0,
            screenW: 0,
            screenH: 0,
            miniLobby_debug_popup: false,
            miniH: '',
            miniW: '',
            userAgent: '',
            browserDetails: {},
            webGLSupport: {},
            lobbyStartTime: 0,
            refreshEnabled: false,
            lobbyMusicCached: '',
            fbUserCountryCode: '',
            operatingSystem: '',
            browserName: '',
            consentConfig: '',
            osVersion: '',
            browserVersion: '',
            isEnablePayment: false,
            isAccountInfo:false,
            gameLoadCount:0,
            currentGameloading:'',
            gameLoading:0,
            showHighLow: false,
            webglRolloutConfig:'',
            userIdToSendToRegister: '',
            imagePreview:false
        };
        this.fbEventHandler = this.fbEventHandler.bind(this);
        this.loginAsync = Promises.promisify(loginToFacebook);
        this.authProceed = Promises.promisify(function(loginResponse, cb){ cb(null, loginResponse);});
        this.getFacebookUserProfileAsync = Promises.promisify(getFacebookUserProfile);
        this.handleConnectionChange = this.handleConnectionChange.bind(this);
        this.showContainer = this.showContainer.bind(this);
        this.proceedAfterConsent = this.proceedAfterConsent.bind(this);
        this.proceedAfterGDPRConsent = this.proceedAfterGDPRConsent.bind(this);
        this.proceedToSeamAuth = this.proceedToSeamAuth.bind(this);
        this.optOutConsent = this.optOutConsent.bind(this);
        this.switchToGame = this.switchToGame.bind(this);
        this.escWinFullScreen = this.escWinFullScreen.bind(this);
        this.exitFromFullScreen = this.exitFromFullScreen.bind(this);
        this.registerEventsESCFullScreen = this.registerEventsESCFullScreen.bind(this);
        this.onInactive = this.onInactive.bind(this);
        this.getUserTokenMiniGame = this.getUserTokenMiniGame.bind(this);
        this.getFeatureConfigGlobalToggle = this.getFeatureConfigGlobalToggle.bind(this);
        this.getFeatureConfigEngineeringFlag = this.getFeatureConfigEngineeringFlag.bind(this);
        this.getFeatureConfigRefreshRate = this.getFeatureConfigRefreshRate.bind(this);
        this.getToken = this.getToken.bind(this);
        this.updateInputValueH = this.updateInputValueH.bind(this);
        this.updateInputValueW = this.updateInputValueW.bind(this);
        this.setResolution = this.setResolution.bind(this);//TEST CODE FOR CHECK RESOLUTION
        this.showDebugger = this.showDebugger.bind(this);
        this.proceedAfterCCPAConsent = this.proceedAfterCCPAConsent.bind(this);
        this.setPubSub = this.setPubSub.bind(this);

        this.gsnEventHandler = this.gsnEventHandler.bind(this);
        this.gsnLoginAsync = Promises.promisify(loginToGSN);
        this.getGSNUserProfileAsync = Promises.promisify(getGSNUserProfile);
        this.proceedAfterConsentForGSNCom = this.proceedAfterConsentForGSNCom.bind(this);
        this.proceedAfterGDPRConsentForGSNCom = this.proceedAfterGDPRConsentForGSNCom.bind(this);
        this.proceedToSeamAuthForGSNCom = this.proceedToSeamAuthForGSNCom.bind(this);
        this.proceedAfterCCPAConsentForGSNCom= this.proceedAfterCCPAConsentForGSNCom.bind(this);
        this.showAccountInfo = this.showAccountInfo.bind(this);
        this.closeAccountInfo = this.closeAccountInfo.bind(this);
        this.logout = this.logout.bind(this);
        this.paymentStatus= this.paymentStatus.bind(this);
        this.incrementGameNameCounter = this.incrementGameNameCounter.bind(this);
        this.proceedForGDPR = this.proceedForGDPR.bind(this);
        this.proceedForGDPRForGSNCom = this.proceedForGDPRForGSNCom.bind(this);
        this.getHighLowDisplayGameDetails = this.getHighLowDisplayGameDetails.bind(this);
        this.imagePreviewHandler = this.imagePreviewHandler.bind(this);
    }
    componentDidMount() {
        const musicEnabled = window.localStorage.getItem('musicEnabled');
        localStorage.setItem('musicEnabled', musicEnabled && musicEnabled ==='true' ? 'true' : 'false');
        this.setState({ lobbyStartTime: new Date().getTime() });
        this.registerEventsESCFullScreen();
        console.log('WHAT IS COUNTRY FOR countriesTimezones = ', this.getCountryCodeForFallBack());
        window.isMainLobby = true;
        audioManager.init({
            baseUrl: this.props.baseUrl,
            isSfxEnabled: this.props.sfxEnabled,
        });
        const { cookies } = this.props;
        let cookieForLobbyAudio = cookies.get('__gsn__lobbymusic');
        if (cookieForLobbyAudio) cookieForLobbyAudio = cookieForLobbyAudio === 'true';
        if (cookieForLobbyAudio) audioManager.playLobbyMusic();
        this.setPubSub();
    }
    componentWillUnmount() {
        document.removeEventListener('FBObjectReady', this.fbEventHandler);
        document.removeEventListener('GSNObjectReady', this.gsnEventHandler);
        document.removeEventListener('fullscreenchange', this.escWinFullScreen);
        document.removeEventListener('webkitfullscreenchange', this.escWinFullScreen);
        document.removeEventListener('mozfullscreenchange', this.escWinFullScreen);
        document.removeEventListener('MSFullscreenChange', this.escWinFullScreen);
        document.removeEventListener('ImagePreview', this.imagePreviewHandler);
        window.removeEventListener('online', this.handleConnectionChange);
        window.removeEventListener('offline', this.handleConnectionChange);
        //Unsubscribe all pubsubs
        PubSub.unsubscribe('UPDATE_BALANCE');
        PubSub.unsubscribe('NETWORK_ERROR');
        PubSub.unsubscribe('SEAM_SERVICE_ERROR');
        PubSub.unsubscribe('UNITY_LOADER_GAME');
        PubSub.unsubscribe('NEW_GAME');
        // PubSub.unsubscribe('WEBGL_LOADED');
        PubSub.unsubscribe('WEBGL_IS_LOADED');
        PubSub.unsubscribe('ESC_FULL_SCREEN');
        PubSub.unsubscribe('UNITY_LOADER_ERROR');
        PubSub.unsubscribe('BLANK_UNITY_LOADER_ERROR');
        PubSub.unsubscribe('OPEN_CONTACT_SUPPORT');
        PubSub.unsubscribe('BLOCK_USER_TYPING');
    }
/////////////////////////////// FOR FB /////////////////////////
    fbEventHandler() {
        console.log('CURTRENT PLATFORM = ', this.props.platform);
        document.body.style.backgroundColor = "#010B40";
        this.setState({imagePreview:false});
        this.setState({ userAgent: window && window.navigator.userAgent });
        const browserDetails = this.state.webGLSupport && this.state.webGLSupport.browserDetails;
        this.getHighLowDisplayGameDetails();
        if (window && window.location.href.search(`${process.env.BUILD_SECRET}`) >= 0) {
            //Do Nothing
        } else {
            this.loginAsync(process.env.FACEBOOK_APP_ID)
                .then((loginResponse) =>{
                    this.setState({ FBResForDevice: loginResponse });
                    //Analytics End
                    console.log('STEP 1: FB LOGIN ::', loginResponse);
                    const fbUserId =  loginResponse && loginResponse.authResponse && loginResponse.authResponse.userID;
                    this.props.setFBUserId(fbUserId);
                    this.setState({ userIdToSendToRegister: fbUserId });
                    const registerDevice = function(z){
                        return function() {
                            //Register Device:WEB for Seam
                            return SeamServices.registerDevice(z);
                        };
                    }
                    //Analytics Start
                    const lodData = {
                        eventGroup: 'STARTUP',
                        eventCode: 'BEGIN',
                        eventAttributes: {
                            ui_element: 'loginToFacebook',
                            web_user_agent: !browserDetails.userAgent
                                ? 'UNKNOWN'
                                : browserDetails.userAgent,
                            os_family: !browserDetails.osName ? 'UNKNOWN' : browserDetails.osName,
                            os_version: !browserDetails.osVersion
                                ? 'UNKNOWN'
                                : browserDetails.osVersion,
                            browser_family: !browserDetails.browserName
                                ? 'UNKNOWN'
                                : browserDetails.browserName,
                            browser_version: !browserDetails.browserVersion
                                ? 'UNKNOWN'
                                : browserDetails.browserVersion,
                            fb_uid: fbUserId,
                            os_bit_architecture: browserDetails && browserDetails.osBit ? browserDetails && browserDetails.osBit : '',
                        },
                        country: this.state.fbUserCountryCode,
                        operatingSystem: this.state.operatingSystem,
                        deviceId: fbUserId,
                        appVersion: process.env.APP_VERSION,
                    };
                    const payload = analyticsLogs.setAnalyticsData(lodData);
                    analyticsManagerInstance.logAnalytics(payload).then(() => {
                        console.log('STEP 2: STARTUP BEGIN LOGGED ::', loginResponse);
                    });
                    //Analytics End
                    const getFeatureConfigData = function(y) {
                        return function() {
                            return SeamServices.getFeatureConfigConsentConfig(y);
                        };
                    };
                    return Promises.resolve([
                        getFeatureConfigData(loginResponse),
                        registerDevice(fbUserId),
                    ]).mapSeries((method) => {
                        return method();
                    });
                }).then((resp)=>{
                    console.log('STEP 3: CONSENT CONFIG DATA ::', resp);
                    const mydata  = findItem(resp, FC_CONSENT_CONFIG);
                    const consentConfig = mydata && JSON.parse(mydata.getValue()[FC_CONSENT_CONFIG]);
                    this.setState({consentConfig: consentConfig});
                    return this.getFacebookUserProfileAsync();
                }).then((respUserProfile) => {
                    console.log('STEP 4: FB PROFILE SYNC ::', respUserProfile);
                    const fbUserId = respUserProfile && respUserProfile.id;
                    this.setState({ userProfileImage: respUserProfile && respUserProfile.picture && respUserProfile.picture.data && respUserProfile.picture.data.url });
                    this.setState({ userFbData: respUserProfile });
                    SeamServices.getCountryCodeByIPHandler()
                        .then((res) => {
                            const gdprCountry = res ? res.toUpperCase() : '';
                            this.setState({ fbUserCountryCode: gdprCountry });
                            console.log(`STEP 4-0: USER COUNTRY `, gdprCountry);
                            this.proceedForGDPR(gdprCountry, fbUserId, this.props.platform)
                        }).catch((error) => {
                            //Fallback for GDPR,
                            const gdprCountry = this.getCountryCodeForFallBack();
                            this.setState({ fbUserCountryCode: gdprCountry });
                            this.proceedForGDPR(gdprCountry, fbUserId, this.props.platform);
                            console.error('Error: Seam getCountryCodeByIPHandler', {message: error})
                            //Analytics - ENGINEERING-start
                            const errorLog = {
                                eventGroup: ENG_ERROR.eventGroup,
                                eventCode: ENG_ERROR.eventCode,
                                deviceId: fbUserId,
                                eventAttributes: {
                                    ui_element: 'getCountryCodeByIPHandler',
                                    from_where: 'loginToFacebook',
                                    fb_uid: fbUserId,
                                },
                                country: gdprCountry,
                                operatingSystem: this.state.operatingSystem,
                            };
                            this.state.logEngineeringEvents && analyticsManagerInstance
                                .logAnalytics(analyticsLogs.setAnalyticsData(errorLog))
                                .then(() => {});
                            //Analytics - ENGINEERING-End

                        });
                }).catch((error) => {
                    console.error('Error: Seam Service loginAsync', {message: error});
                    const fbUserId =  this.state.FBResForDevice && this.state.FBResForDevice.authResponse && this.state.FBResForDevice.authResponse.userID;
                    //Analytics Start
                    const lodData = {
                        eventGroup: 'STARTUP',
                        eventCode: 'LOGIN',
                        eventSubCode: 'FAIL',
                        sessionToken: '',
                        eventAttributes: {
                            ui_element: 'loginToFacebook',
                            from_where: 'fb_login',
                            attr1: 'seam',
                            attr2: '1',
                            fb_uid: fbUserId ? null : fbUserId,
                        },
                        country: this.state.fbUserCountryCode,
                        operatingSystem: this.state.operatingSystem,
                        deviceId: fbUserId ? null : fbUserId,
                        appVersion: this.state.webglRolloutConfig,
                    };
                    const payload = analyticsLogs.setAnalyticsData(lodData);
                    analyticsManagerInstance.logAnalytics(payload).then(() => { });
                    //Analytics End
                });
        }
    }
    proceedForGDPR(gdprCountry, fbUserId, platform){
        if (COUNTRY_LOOKUP.includes(gdprCountry)) {
            console.log('STEP 4-1: GDPR STATUS - Required for Country ', gdprCountry);
            SeamServices.validateAndRetrieveHandler(fbUserId, platform)
                .then((data) => {
                    console.log('STEP 4-1-1: GDPR Consent Need to Accept', data && data._requiresOptIn);
                    if (data && data._requiresOptIn) {
                        //Analytics Start
                        const lodData = {
                            eventGroup: 'STARTUP',
                            eventCode: 'SCREEN',
                            eventSubCode: 'OPEN',
                            sessionToken: this.state.seamUser && this.state.seamUser.session_token,
                            eventAttributes: {
                                ui_element: 'gdpr',
                                from_where: 'authorizeAppWithSeam',
                                fb_uid: fbUserId,
                            },
                            country: gdprCountry,
                            operatingSystem: this.state.operatingSystem,
                            deviceId: fbUserId,
                            appVersion: this.state.webglRolloutConfig,
                        };
                        const payload = analyticsLogs.setAnalyticsData(lodData);
                        analyticsManagerInstance.logAnalytics(payload).then(() => {
                            console.log('STEP 4-1-2: GDPR STARTUP SCREEN OPEN EVENT TRIGGRED');
                        });
                        //Analytics End
                        this.props.openModal('Gdpr', {
                            OptinResponse: data,
                            fbUserId: fbUserId,
                            SeamUser: this.state.seamUser,
                            proceedAfterConsent: this.proceedAfterGDPRConsent,
                            country: gdprCountry,
                            operatingSystem: this.state.operatingSystem,
                            platform: platform
                        });
                    } else {
                        this.proceedAfterGDPRConsent();
                    }
                })
                .catch((error) => {
                    console.error('Error: Seam gdpr validateAndRetrieveHandler', {message: error});
                    //Analytics - ENGINEERING-start
                    const errorLog = {
                        eventGroup: ENG_ERROR.eventGroup,
                        eventCode: ENG_ERROR.eventCode,
                        deviceId: fbUserId,
                        eventAttributes: {
                            ui_element: 'validateAndRetrieveHandler',
                            from_where: 'loginToFacebook',
                            fb_uid: fbUserId,
                        },
                        country: this.state.fbUserCountryCode,
                        operatingSystem: this.state.operatingSystem,
                        appVersion: this.state.webglRolloutConfig,
                    };
                    this.state.logEngineeringEvents && analyticsManagerInstance
                        .logAnalytics(analyticsLogs.setAnalyticsData(errorLog))
                        .then(() => { });
                    //Analytics - ENGINEERING-End
                    PubSub.publish('SEAM_SERVICE_ERROR', true);
                });
        } else {
            console.log('STEP 4-2: GDPR STATUS - NOT Required for Country ', gdprCountry);
            this.proceedAfterGDPRConsent();
        }
    }
    proceedAfterGDPRConsent(){
        const fbUserId = this.state.userFbData && this.state.userFbData.id;
        if(this.state.consentConfig && this.state.consentConfig.CCPA && this.state.consentConfig.CCPA.enabled){
            SeamServices.userConsentInfo(this.state.FBResForDevice, this.state.fbUserCountryCode).then((resp)=>{
                console.log('STEP 5: CCPA CONSENT REQUIRED ::', resp);
                const isShowAds = resp && resp.pi.toUpperCase() === "ACCEPTED" ? false : true;
                this.setState({showAds_ccpa_users: isShowAds});
                if(resp.ccpa === 'PENDING'){
                    this.props.openModal('Ccpa', {
                        ccpaResponse: this.state.consentConfig,
                        fbUserId: fbUserId,
                        SeamUser: this.state.seamUser,
                        proceedAfterCCPAConsent: this.proceedAfterCCPAConsent,
                        country: this.state.fbUserCountryCode,
                        operatingSystem: this.state.operatingSystem,
                    });
                    //Analytics Start
                    const lodData = {
                        eventGroup: 'STARTUP',
                        eventCode: 'CCPA',
                        eventSubCode: 'REQUEST',
                        sessionToken: this.state.seamUser && this.state.seamUser.session_token,
                        eventAttributes: {
                            ui_element: 'ccpa',
                            from_where: 'fb_login',
                            fb_uid: fbUserId,
                        },
                        country: this.state.fbUserCountryCode,
                        operatingSystem: this.state.operatingSystem,
                        deviceId: fbUserId,
                        appVersion: this.state.webglRolloutConfig,
                    };
                    const payload = analyticsLogs.setAnalyticsData(lodData);
                    analyticsManagerInstance.logAnalytics(payload).then(() => {
                        console.log('STEP 5-1: STARTUP CCPA REQUEST EVENT TRIGGRED');
                    });
                    //Analytics End

                }else{
                    console.log('STEP 5-1: CCPA CONSENT NOT REQUIRED ::', resp.ccpa);
                    this.proceedToSeamAuth(resp, resp, false, this.state.FBResForDevice);
                }
            }).catch((error)=>{
                console.error('Error: Seam Error proceedAfterGDPRConsent', {message: error});
                //Analytics - ENGINEERING-start
                const errorLog = {
                    eventGroup: ENG_ERROR.eventGroup,
                    eventCode: ENG_ERROR.eventCode,
                    deviceId: fbUserId,
                    eventAttributes: {
                        ui_element: 'ccpa_consent',
                        from_where: 'fb_login',
                        fb_uid: fbUserId,
                    },
                    country: this.state.fbUserCountryCode,
                    operatingSystem: this.state.operatingSystem,
                    appVersion: this.state.webglRolloutConfig,
                };
                this.state.logEngineeringEvents && analyticsManagerInstance
                    .logAnalytics(analyticsLogs.setAnalyticsData(errorLog))
                    .then(() => { });
                //Analytics - ENGINEERING-End
                PubSub.publish('SEAM_SERVICE_ERROR', true);
            });
        }else{
            //this.proceedAfterConsent({});
            this.proceedToSeamAuth({}, {}, false, this.state.FBResForDevice);
        }
    }
    proceedAfterCCPAConsent(consentPayload, updatedConsentPayload, isRecordConsent){
        //this.proceedAfterConsent(updatedConsentPayload);
        const isShowAds = updatedConsentPayload && updatedConsentPayload.pi.toUpperCase() === "ACCEPTED" ? false : true;
        this.setState({showAds_ccpa_users: isShowAds});
        console.log('STEP 6-1: AFTER CCPA - RECEIVED - consentPayload? ', consentPayload);
        console.log('STEP 6-2: AFTER CCPA - RECEIVED - updatedConsentPayload? ', updatedConsentPayload);
        console.log('STEP 6-3: AFTER CCPA - RECEIVED - isRecordConsent? ', isRecordConsent);
        this.proceedToSeamAuth(consentPayload, updatedConsentPayload, isRecordConsent, this.state.FBResForDevice);
    }
    proceedToSeamAuth(consentPayload, updatedConsentPayload,isRecordConsent, fbLogin){
        console.log('STEP 7: AFTER CCPA PROCEED TO SEAM AUTH');
        const fbUserId = fbLogin && fbLogin.authResponse && fbLogin.authResponse.userID;
        this.authProceed(fbLogin)
        .then((loginResponse)=>{
            const authorizeAppWithSeam = function (v) {
                return function () {
                    return SeamServices.authorizeSeam(v);
                };
            };
            return Promises.resolve([
                authorizeAppWithSeam(loginResponse),
            ]).mapSeries((method) => {
                return method();
            });
        }).then((authResp) => {
            const loginTimeSyncDelay = new Date().getTime();
            this.setState({ loginTimeSyncDelay:  loginTimeSyncDelay});
            this.getFeatureConfigMiniGameMath();
            const seamObj = authResp[0];//;NEED TO CHANGE AUTH
            console.log('STEP 7: SEAM AUTH RESPONSE ? ', authResp[0]);
            this.setState({ seamUser: seamObj });
            const seamUserId = seamObj && seamObj._id
            const sessionToken = seamObj && seamObj.sessionToken;
            isRecordConsent && SeamServices.recordUserConsent(consentPayload, seamUserId, sessionToken).then(()=>{});
            SeamServices.linkDevice( sessionToken, fbUserId)
            .then((resp) => {
                console.log('STEP 8-1: SEAM LINK DEVICE FOR WEB_ID:', fbUserId);
                //Analytics Start
                const lodData = {
                    eventGroup: 'STARTUP',
                    eventCode: 'LOGIN',
                    eventSubCode: 'SUCCESS',
                    sessionToken: sessionToken,
                    eventAttributes: {
                        ui_element: 'authorizeAppWithSeam',
                        from_where: 'fb_login',
                        attr1: 'seam',
                        attr2: '1',
                        fb_uid: fbUserId
                    },
                    country: this.state.fbUserCountryCode,
                    operatingSystem: this.state.operatingSystem,
                    deviceId: fbUserId,
                    appVersion: this.state.webglRolloutConfig,
                };
                const payload = analyticsLogs.setAnalyticsData(lodData);
                analyticsManagerInstance.logAnalytics(payload).then(() => { });
                //Analytics End
            }).catch((error)=>{
                console.error('Error: Seam linkDevice', {message: error});
                //Analytics - ENGINEERING-start
                const errorLog = {
                    eventGroup: ENG_ERROR.eventGroup,
                    eventCode: ENG_ERROR.eventCode,
                    deviceId: fbUserId,
                    eventAttributes: {
                        ui_element: 'authorizeAppWithSeam',
                        from_where: 'linkDevice',
                        fb_uid: fbUserId,
                    },
                    country: this.state.fbUserCountryCode,
                    operatingSystem: this.state.operatingSystem,
                    appVersion: this.state.webglRolloutConfig,
                };
                this.state.logEngineeringEvents && analyticsManagerInstance
                    .logAnalytics(analyticsLogs.setAnalyticsData(errorLog))
                    .then(() => { });
                //Analytics - ENGINEERING-End
            });
            console.log('STEP 8-2: SEAM AUTH SUCCESSFULL COMPLETED');
            SeamServices.getRollOutFC(sessionToken)
            .then((data) => {
                let appVersion;
                const appVersionValue = data.getValue()[FC_WEBGL_ROLLOUT];
                const appVersionObj = appVersionValue && JSON.parse(appVersionValue);
                if(appVersionObj && appVersionObj.enabled){
                    appVersion = appVersionObj.version;
                    SeamServices.registerDeviceWithAppid(this.state.userIdToSendToRegister, appVersion).then((res)=>{
                        SeamServices.linkDevice( sessionToken, this.state.userIdToSendToRegister )
                    });
                    this.setState({webglRolloutConfig: appVersion});
                    //Analytics Start
                    const lodData = {
                        eventGroup: ENG_EVENT.eventGroup,
                        eventCode: ENG_EVENT.eventCode,
                        eventSubCode: ENG_EVENT.eventSubCode,
                        sessionToken: sessionToken,
                        eventAttributes: {
                            ui_element: 'authorizeAppWithSeam',
                            from_where: 'fb_login',
                            attr1: 'seam',
                            attr2: '1',
                            fb_uid: fbUserId
                        },
                        country: this.state.fbUserCountryCode,
                        operatingSystem: this.state.operatingSystem,
                        deviceId: fbUserId,
                        appVersion:appVersion,
                    };
                    const payload = analyticsLogs.setAnalyticsData(lodData);
                    analyticsManagerInstance.logAnalytics(payload).then(() => { });
                    //Analytics End
                }
                window.TrackJS && TrackJS.configure({
                    userId: seamUserId,
                    sessionId: sessionToken,
                    version: appVersion ? appVersion : process.env.APP_VERSION,
                });
                this.proceedAfterConsent(updatedConsentPayload, loginTimeSyncDelay, appVersion);
            }).catch((error)=>{
                //Analytics Start
                const lodData = {
                    eventGroup: ENG_EVENT.eventGroup,
                    eventCode: ENG_EVENT.eventCode,
                    eventSubCode: ENG_EVENT.eventSubCode,
                    sessionToken: sessionToken,
                    eventAttributes: {
                        ui_element: 'authorizeAppWithSeam',
                        from_where: 'fb_login',
                        attr1: 'seam',
                        fb_uid: fbUserId
                    },
                    country: this.state.fbUserCountryCode,
                    operatingSystem: this.state.operatingSystem,
                    deviceId: fbUserId,
                    appVersion: this.state.webglRolloutConfig,
                };
                const payload = analyticsLogs.setAnalyticsData(lodData);
                analyticsManagerInstance.logAnalytics(payload).then(() => { });
                //Analytics End
                window.TrackJS && TrackJS.configure({
                    userId: seamUserId,
                    sessionId: sessionToken,
                    version: process.env.APP_VERSION,
                });
                this.proceedAfterConsent(updatedConsentPayload, loginTimeSyncDelay, undefined);
            });
        }).catch((error)=>{
            window.TrackJS && TrackJS.track(error);
            console.error('Error: SEAM AUTH FAILED RESPONSE', error);
            //Analytics Start
            const lodData = {
                eventGroup: 'STARTUP',
                eventCode: 'LOGIN',
                eventSubCode: 'FAIL',
                sessionToken: '',
                eventAttributes: {
                    ui_element: 'authorizeAppWithSeam',
                    from_where: 'fb_login',
                    attr1: 'seam',
                    attr2: '1',
                    fb_uid: fbUserId,
                },
                country: this.state.fbUserCountryCode,
                operatingSystem: this.state.operatingSystem,
                deviceId: fbUserId,
                appVersion: this.state.webglRolloutConfig,
            };
            const payload = analyticsLogs.setAnalyticsData(lodData);
            analyticsManagerInstance.logAnalytics(payload).then(() => { });
            //Analytics End
            PubSub.publish('SEAM_SERVICE_ERROR', true);
        });
    }
    proceedAfterConsent(consentPayload, loginTimeSyncDelay, appVersionRollOut) {
        const fbUserId = this.state.FBResForDevice && this.state.FBResForDevice.authResponse && this.state.FBResForDevice.authResponse.userID;
        const seamObject = this.state.seamUser;
        console.log('STEP 10: SEAM OBJECT AFTER LOGIN', seamObject);
        this.props.setSeamSession(seamObject);
        this.setState({ authenticateComplete: true });
        this.getFeatureConfigRefreshRate();
        this.getCountriesWhereAdsCanRefresh();
        this.getFeatureConfigGlobalToggle();
        this.getToken('mini_lobby_load');
        this.getUserTokenMiniGame();
        this.getFeatureConfigEngineeringFlag();
        const webglPayload = {
            facebookUserObject:{
                seamId: seamObject._id,
                name: seamObject.profile.fbName,
                email: seamObject.profile.fbEmail,
                pictureUrl: seamObject.profile.fbPictureUrl,
                sessionToken: seamObject.sessionToken,
                firstName: seamObject.profile.fbFirstName,
                countryCode: this.state.fbUserCountryCode ? this.state.fbUserCountryCode : 'DEFAULT',
                platformUserId: fbUserId,
                lastLoginDate: seamObject.profile.lastLoginDate,
                previousLogin: seamObject.profile.previousLogin,
                firstTimePlayer: seamObject.profile.firstTimePlayer ? true : false,
                outOfToken: seamObject.profile.outOfToken,
                consentPayload: consentPayload,
                platform:this.props.platform,
                loginTimeSyncDelay:0,
                profile:seamObject.profile,
                userData: seamObject.userData
            },
            analyticsLogs: analyticsLogs,
            FBResForDevice: this.state.FBResForDevice,
            logEngineeringEvents: this.state.logEngineeringEvents,
            country: this.state.fbUserCountryCode,
            operatingSystem: this.state.operatingSystem,
            webAppVersion: this.props.unityAppVersion,
            browserName: this.state.browserName,
            loginTimeSyncDelay: loginTimeSyncDelay,
            appVersionRollOut: appVersionRollOut
        };
        console.log('STEP 11: WEBGL PAYLOAD ', webglPayload);
        this.setState({ startTime: new Date().getTime() });
        setTimeout(() => {
            if (
                this.state.webGLSupport &&
                this.state.webGLSupport.flag &&
                !this.state.isCommonError
            ) {
                webglManager(webglPayload);
            }
        }, 2000);
        this.incrementGameNameCounter();
    }
    getHighLowDisplayGameDetails() {
        SeamServices.getFeatureConfigDisplayHighLowGame().then((data) => {
            const showHighLow = data.getValue()[FC_SHOW_HILO];
            this.setState({ showHighLow: showHighLow && showHighLow.toLowerCase() === 'true' ? true : false });
        });
    }
///////////////////////////// ALL COMMON FUNCTIONS

    setPubSub(){
        PubSub.subscribe('WEBGL_LOADED', (target, msg) => {
            if (msg) {
                this.setState({ webglLoaded: true });
                this.props.checkifWebglLoaded(true);
                if (
                    this.props.noOfTimesPlayed === 0 &&
                    this.props.gameRoundNo === 0 &&
                    !this.state.isCommonError
                ) {
                    this.showContainer('auto');
                }
                if (this.props.gameRoundNo !== 0 && !this.state.isCommonError) {
                    this.onInactive(10000, () => {
                        this.showContainer('auto');
                    });
                }
                if(this.state.isAccountInfo == true) {
                    PubSub.publish('BLOCK_USER_TYPING', 'false');
                }
            }
        });

        PubSub.subscribe('USER_ACTIVE', (target, msg) => {
            if (msg) {
                clearTimeout(this.wait);
                this.onInactive(10000, () => {
                    this.showContainer('auto');
                });
            }
        });

        PubSub.subscribe('UPDATE_BALANCE', (target, msg) => {
            if (msg) {
                this.getToken('pubsub_update_balance');
            }
        });
        PubSub.subscribe('NEW_GAME', (target, msg) => {
            const name = msg === 'HOME' ? 'home_home' : 'games_' + msg;
            this.setState({ adsToken: name });
            if (name === 'home_home') {
                this.setState({ adsTargetArguments: { dept: 'home', title: 'home', pgid: 'home' } });
                this.setState({ showGameAds: false });
            } else {
                this.setState({ adsTargetArguments: { dept: 'games', title: msg, pgid: 'play' } });
                this.setState({ showGameAds: true });
            }
        });
        PubSub.subscribe('NETWORK_ERROR', (target, e) => {
            if (e) {
                this.setState({ isContainerError: 1 });
                this.setState({ isCommonError: true });
            }
        });

        PubSub.subscribe('SEAM_SERVICE_ERROR', (target, e) => {
            if (e) {
                this.setState({ isContainerError: 2 });
                this.setState({ isCommonError: true });

            }
        });
        PubSub.subscribe('UNITY_LOADER_ERROR', (target, e) => {
            if (e) {
                this.setState({ isContainerError: 3 });
                this.setState({ isCommonError: true });
            }
        });

        PubSub.subscribe('BLANK_UNITY_LOADER_ERROR', (target, e) => {
            if (e) {
                this.setState({ isContainerError: 4 });
                this.setState({ isCommonError: true });
            }
        });
        PubSub.subscribe('GSN_PAYPAL_PAYMENT', (target, msg)=>{
            if(msg && msg.paymentInit){
                this.isEnablePayment ? this.setState({ isEnablePayment: false }) : this.setState({ isEnablePayment: true });;
                this.props.openModal('PayPalCheckOut', {
                    product: msg.item,
                    seamUser: this.state.seamUser,
                    userId: this.state.FBResForDevice.authResponse.userID,
                    paymentStatus: this.paymentStatus,
                });
            }
        })

        PubSub.subscribe('OPEN_CONTACT_SUPPORT', (target, msg) => {
            if (msg === 'NeedHelp') {
                const url = this.state.seamUser
                    ? contactSupport(
                        this.state.seamUser,
                        this.state.FBResForDevice,
                        this.state.userAgent,
                        this.props.seamTokenBalance, this.state.browserName,
                        this.state.browserVersion,
                        this.state.operatingSystem,
                        this.state.osVersion,
                        this.props.platform
                    )
                    : contactSupport(
                        '',
                        this.state.FBResForDevice,
                        this.state.userAgent,
                        this.props.seamTokenBalance,
                        this.state.browserName,
                        this.state.browserVersion,
                        this.state.operatingSystem,
                        this.state.osVersion,
                        this.props.platform
                    );
                window.open(url, '_blank');
            }
        });
    }

    registerEventsESCFullScreen() {
        document.addEventListener('FBObjectReady', this.fbEventHandler);
        document.addEventListener('GSNObjectReady', this.gsnEventHandler);
        document.addEventListener('ImagePreview', this.imagePreviewHandler);
        document.addEventListener('fullscreenchange', this.escWinFullScreen);
        document.addEventListener('webkitfullscreenchange', this.escWinFullScreen);
        document.addEventListener('mozfullscreenchange', this.escWinFullScreen);
        document.addEventListener('MSFullscreenChange', this.escWinFullScreen);
        window.addEventListener('online', this.handleConnectionChange);
        window.addEventListener('offline', this.handleConnectionChange);
    }
    setResolution() {
        const resolution  = setCustomResolution(this.state.miniW, this.state.miniH);
        this.setState({ renderWidth: resolution.renderWidth });
        this.setState({ renderHeight: resolution.renderHeight });
    }

    setCanvasResolutionOnLoad() {
        const isUserFBgameroom = this.state.webGLSupport && this.state.webGLSupport.browserDetails && this.state.webGLSupport.browserDetails.browserName && this.state.webGLSupport.browserDetails.browserName === 'Facebook Gameroom';
        setTimeout( () => {
            if (!this.state.isFullScreen) {
                const {renderWidth, renderHeight} = getCanvasResolutionOnLoad(isUserFBgameroom, this.props.platform);
                this.setState({ renderWidth: renderWidth });
                this.setState({ renderHeight: renderHeight });
            }
        },1000);
    }
    showDebugger() {
        if (this.props.usersToHandle) {
            const miniLobby_debug_popup = this.state.miniLobby_debug_popup;
            this.setState({ miniLobby_debug_popup: !miniLobby_debug_popup })
        } else {
            // NO ACTION NEEDED
        }
    }
    updateInputValueH(evt) {
        this.setState({
            miniH: evt.target.value
        });
    }
    updateInputValueW(evt) {
        this.setState({
            miniW: evt.target.value
        });
    }

    exitFromFullScreen() {
        if (
            document.fullscreenElement ||
            document.webkitFullscreenElement ||
            document.mozFullScreenElement
        ) {
            if (document.exitFullscreen) {
                this.setState({ isFullScreen: false });
                document.exitFullscreen();
            } else if (document.mozCancelFullScreen) {
                /* Firefox */
                this.setState({ isFullScreen: false });
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) {
                /* Chrome, Safari and Opera */
                this.setState({ isFullScreen: false });
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) {
                /* IE/Edge */
                this.setState({ isFullScreen: false });
                document.msExitFullscreen();
            }
        }
    }

    escWinFullScreen(event) {
        event.preventDefault();

        const fullscreenElement = document.fullscreenElement || document.mozFullScreenElement ||
            document.webkitFullscreenElement || document.msFullscreenElement;
        console.log("called OnFullScreenChange Value : ", fullscreenElement ? true : false);
        this.setState({
            isFullScreen: fullscreenElement ? true : false
        });
        PubSub.publish('ESC_FULL_SCREEN', fullscreenElement ? 'true' : 'false');
    }
    reloadApplication() {
        window && window.location.reload();
    }
    showContainer(triggerType) {
        this.setState({ adsToken: 'home_home' });
        this.setState({ adsTargetArguments: { dept: 'home', title: 'home', pgid: 'home' } });
        this.setState({ showWebGL: this.state.webglLoaded });
        this.setState({ showGameAds: false });
        this.setCanvasResolutionOnLoad();
        this.props.checkContainer(true);
        console.log('onDashboardEnter', this.props.seamTokenBalance);
        PubSub.publish('WEBGL_IS_LOADED', {
            seamTokenBalance: this.props.seamTokenBalance,
            timeSpent: new Date().getTime() - this.state.startTime,
            musicEnabled: window.localStorage.getItem('musicEnabled'),
            loadType: triggerType,
        });
        clearTimeout(this.wait);
        PubSub.unsubscribe('WEBGL_LOADED');
        PubSub.unsubscribe('USER_ACTIVE');
    }
    getUserTokenMiniGame() {
        const userId = this.props.platform === PLATFORM_GSNCOM ? this.state.FBResForDevice.authResponse.userID : this.state.FBResForDevice.authResponse.userID;
        SeamServices.getUserGameToken(this.state.seamUser)
            .then((data) => {
                console.log('DATA FROM GAME TOKEN', data);
                this.setState({ tokensUserWon: data });
                this.getFeatureConfigMaxTokens();
            })
            .catch((error) => {
                console.error('Error:: Seam getUserGameToken', {
                    message: error,
                });
                //Analytics - ENGINEERING-start
                const errorLog = {
                    eventGroup: ENG_ERROR.eventGroup,
                    eventCode: ENG_ERROR.eventCode,
                    eventSubCode: 'GET_USER_MINI_TOKEN',
                    eventAttributes: {
                        ui_element: 'proceedAfterConsentMethod',
                        from_where: 'mini_lobby',
                    },
                    country: this.state.fbUserCountryCode,
                    operatingSystem: this.state.operatingSystem,
                    deviceId: userId,
                    appVersion: this.state.webglRolloutConfig,
                };
                this.state.logEngineeringEvents && analyticsManagerInstance
                    .logAnalytics(analyticsLogs.setAnalyticsData(errorLog))
                    .then(() => { });
                //Analytics - ENGINEERING-End

                PubSub.publish('SEAM_SERVICE_ERROR', error);
            });
    }
    getToken(type) {
        const userId = this.props.platform === PLATFORM_GSNCOM ? this.state.FBResForDevice.authResponse.userID : this.state.FBResForDevice.authResponse.userID;
        SeamServices.tokenBalance(this.state.seamUser)
            .then((data) => {
                this.props.updateTokenBalance(data);
            })
            .catch((error) => {
                console.error('Error:: Seam tokenBalance', {
                    message: error,
                });
                const errorLog = {
                    eventGroup: ENG_ERROR.eventGroup,
                    eventCode: ENG_ERROR.eventCode,
                    eventSubCode: 'GET_SEAM_TOKEN',
                    eventAttributes: {
                        ui_element: 'proceedAfterConsentMethod',
                        from_where: type,
                    },
                    deviceId: userId,
                    country: this.state.fbUserCountryCode,
                    operatingSystem: this.state.operatingSystem,
                    appVersion: this.state.webglRolloutConfig,
                };
                this.state.logEngineeringEvents && analyticsManagerInstance
                    .logAnalytics(analyticsLogs.setAnalyticsData(errorLog))
                    .then(() => { });
                //Analytics - ENGINEERING-End

                PubSub.publish('SEAM_SERVICE_ERROR', error);
            });
    }
    getFeatureConfigGlobalToggle() {
        SeamServices.getFeatureConfigGlobalToggle().then((data) => {
            const adsShow = data.getValue()[FC_ADS_TOGGLE];
            this.setState({ showAds: adsShow && adsShow.toLowerCase() === 'true' ? true : false });
        });
    }
    getFeatureConfigEngineeringFlag() {
        const userId = this.props.platform === PLATFORM_GSNCOM ? this.state.FBResForDevice.authResponse.userID : this.state.FBResForDevice.authResponse.userID;
        SeamServices.getFeatureConfigEngineering().then((data) => {
            const legEggnEvent = data.getValue()[FC_EVENTS_ENGINEERING];
            this.setState({ logEngineeringEvents: legEggnEvent && legEggnEvent.toLowerCase() === 'true' ? true : false });
        }).catch((error) => {
            console.error('Error:: Seam getFeatureConfigEngineering', {
                message: error,
            });
            const errorLog = {
                eventGroup: ENG_ERROR.eventGroup,
                eventCode: ENG_ERROR.eventCode,
                eventSubCode: 'FC_EVENTS_ENGINEERING',
                eventAttributes: {
                    ui_element: 'getFeatureConfigEngineering',
                    from_where: 'getConfigurationWithParams',
                },
                country: this.state.fbUserCountryCode,
                operatingSystem: this.state.operatingSystem,
                deviceId: userId,
                appVersion: this.state.webglRolloutConfig,
            };
            this.state.logEngineeringEvents && analyticsManagerInstance
                .logAnalytics(analyticsLogs.setAnalyticsData(errorLog))
                .then(() => { });
        });
    }
    getFeatureConfigRefreshRate() {
        SeamServices.getFeatureConfigRefreshRate().then((resp) => {
            const adsRate = resp && resp.getValue ? resp.getValue()[FC_ADS_REFRESH_RATE] : 60;
            this.setState({ adsRefreshRate: adsRate });
            this.props.setRefreshRate(adsRate);
        });
    }
    getCountriesWhereAdsCanRefresh() {
        const userId = this.props.platform === PLATFORM_GSNCOM ? this.state.FBResForDevice.authResponse.userID : this.state.FBResForDevice.authResponse.userID;
        SeamServices.getCountriesWhereAdsCanRefresh().then((data) => {
            const countryList = data.getValue()[FC_ADS_WHITELIST] && JSON.parse(data.getValue()[FC_ADS_WHITELIST]);
            this.setState({ refreshEnabled: countryList && countryList.countries.includes(this.state.fbUserCountryCode) });
        }).catch((error) => {
            console.error('Error:: Seam getCountriesWhereAdsCanRefresh', {
                message: error,
            });
            const errorLog = {
                eventGroup: ENG_ERROR.eventGroup,
                eventCode: ENG_ERROR.eventCode,
                eventSubCode: 'FC_ADS_WHITELIST',
                eventAttributes: {
                    ui_element: 'getCountriesWhereAdsCanRefresh',
                    from_where: 'getConfigurationWithParams',
                },
                deviceId: userId,
                country: this.state.fbUserCountryCode,
                operatingSystem: this.state.operatingSystem,
                appVersion: this.state.webglRolloutConfig,
            };
            this.state.logEngineeringEvents && analyticsManagerInstance
                .logAnalytics(analyticsLogs.setAnalyticsData(errorLog))
                .then(() => { });
        });
    }
    getFeatureConfigMiniGameMath() {
        SeamServices.getFeatureConfigMiniGameMath().then((data) => {
            const gameMathModel = data.getValue()[FC_MINI_GAME_MATH];
            this.props.setGameMathModel(gameMathModel);
        });
    }
    getFeatureConfigMaxTokens() {
        const userId = this.props.platform === PLATFORM_GSNCOM ? this.state.FBResForDevice.authResponse.userID : this.state.FBResForDevice.authResponse.userID;
        const seamAuth = {
            id:this.state.seamUser._id,
            sessionToken:this.state.seamUser.sessionToken,
        }
        SeamServices.getFeatureConfigMaxTokens().then((data) => {
            let maxTokensAllowed = data.getValue()[FC_MINI_GAME_MAX_TOKENS];
            maxTokensAllowed = maxTokensAllowed && parseInt(maxTokensAllowed);
            if (this.state.tokensUserWon >= maxTokensAllowed) {
                //Analytics Start
                const lodData = {
                    eventGroup: 'STARTUP',
                    eventCode: 'SCREEN',
                    eventSubCode: 'OPEN',
                    eventAttributes: {
                        ui_element: 'max_minigame_win_reached',
                        from_where: 'authorizeAppWithSeam',
                        attr2: new Date().getTime() - this.state.startTime,
                    },
                    country: this.state.fbUserCountryCode,
                    operatingSystem: this.state.operatingSystem,
                    sessionToken: seamAuth.sessionToken,
                    deviceId: userId,
                    appVersion: this.state.webglRolloutConfig,
                };
                const payload = analyticsLogs.setAnalyticsData(lodData);
                analyticsManagerInstance.logAnalytics(payload).then(() => { });
                //Analytics End
                this.setState({ displayMaxTokensReached: true });
                this.setState({ displayPreLoader: false });
            } else {
                this.setState({ displayPreLoader: true });
                this.setState({ displayMaxTokensReached: false });
            }
        })
        .catch((error) => {
            console.error('Error: Seam getFeatureConfigMaxTokens', {
                message: error,
            });
            //Analytics - ENGINEERING-start
            const errorLog = {
                eventGroup: ENG_ERROR.eventGroup,
                eventCode: ENG_ERROR.eventCode,
                eventSubCode: 'GET_FC_MAX_TOKEN',
                eventAttributes: {
                    ui_element: 'proceedAfterConsentMethod',
                    from_where: 'mini_lobby',
                },
                country: this.state.fbUserCountryCode,
                operatingSystem: this.state.operatingSystem,
                deviceId: userId,
                appVersion: this.state.webglRolloutConfig,
            };
            this.state.logEngineeringEvents && analyticsManagerInstance
                .logAnalytics(analyticsLogs.setAnalyticsData(errorLog))
                .then(() => { });
            //Analytics - ENGINEERING-End

            PubSub.publish('SEAM_SERVICE_ERROR', error);
        });
    }
    optOutConsent() {
        SeamServices.optOutHandler(this.state.userFbData)
            .then((data) => {
                console.log('GDPR OptOut resp', { message: data });
                return data;
            })
            .catch((error) => {
                console.error('Error: Seam gdpr optOutHandler', { error: error.stack });
                PubSub.publish('SEAM_SERVICE_ERROR', error);
            });
    }
    switchToGame() {
        this.setState({ displayHiLoGame: true });
        this.setState({ displayPreLoader: false });
    }
    onInactive(ms, cb) {
        this.wait = setTimeout(cb, ms);
        document.onmousemove = document.mousedown = document.mouseup = document.onkeydown = document.onkeyup = document.focus = function () {
            PubSub.publish('USER_ACTIVE', true);
        };
    }
    handleConnectionChange = () => {
        console.log('navigator.onLine ? ', navigator.onLine);
        const condition = navigator.onLine ? 'online' : 'offline';
        //Commented to check firefox bug
        /*if (condition === 'online') {
            const webPing = setInterval(() => {
                NetworkServices.checkNetwork()
                    .then((_) => {
                        this.setState({ isDisconnected: false }, () => {
                            return clearInterval(webPing);
                        });
                    })
                    .catch((_) => {
                        this.setState({ isDisconnected: true });
                        PubSub.publish('NETWORK_ERROR', true);
                    });
            }, 2000);
            return;
        }*/
        this.setState({ gameIsOver: true });
        this.setState({ displayHiLoGame: false });
        this.setState({ displayPreLoader: false });
        PubSub.publish('NETWORK_ERROR', true);
        return this.setState({ isDisconnected: true });
    };
    getClassFooter(w) {
        if (getOS() !== 'Windows') {
            if (w >= 1920) {
                return css['footer-placeholder-container-1920'];
            } else if (w >= 1680) {
                return css['footer-placeholder-container'];
            } else if (w >= 1440) {
                return css['footer-placeholder-container-1440'];
            } else if (w >= 1280) {
                return css['footer-placeholder-container-1280'];
            } else {
                return css['footer-placeholder-container'];
            }
        } else {
            return css['footer-placeholder-container'];
        }
    }
    incrementGameNameCounter() {
        SeamServices.getFeatureConfigGamesList().then((data) => {
            const GAMES_TOUPDATE = data.getValue()[FC_LOADING_GAMESLIST].replace(/'/g,"").split(',');
            this.setState({gameLoading : setInterval(() => {
                const gameLoadCount = this.state.gameLoadCount;
                    if(gameLoadCount<=GAMES_TOUPDATE.length){
                        this.setState({gameLoadCount: gameLoadCount + 1});
                        this.setState({currentGameloading:GAMES_TOUPDATE[gameLoadCount]});
                        console.log(' GAME LOADING =', GAMES_TOUPDATE[gameLoadCount]);
                    }else{
                        console.log('========================== CLEAR GAME LOADING, ITS DONE=============');
                        clearInterval(this.gameLoading);
                    }
            }, 5000)});
        });
    }

    getCountryCodeForFallBack(){
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const countryForTimezone = countriesTimezones.getCountryForTimezone(timeZone);
        const gdprCountry = countryForTimezone && countryForTimezone.id ? countryForTimezone.id.toUpperCase() : '';
        console.log('WHAT IS COUNTRY FOR countriesTimezones = ', gdprCountry);
        return gdprCountry;
    }

    imagePreviewHandler () {
        console.log('I got fiered here!!!');
        this.setState({imagePreview:true});
    }

    ////////////////////////////// FOR GSN.COM ///////////////////
    gsnEventHandler() {
        console.log('CURTRENT PLATFORM = ', this.props.platform);
        document.body.style.backgroundColor = "#032c6E";
        document.body.style.overflow = "hidden";
        this.setState({imagePreview:false});
        this.setState({ userAgent: window && window.navigator.userAgent });
        const browserDetails = this.state.webGLSupport && this.state.webGLSupport.browserDetails;
        if (window && window.location.href.search(`${process.env.BUILD_SECRET}`) >= 0) {
            //Do Nothing
        } else {
            this.gsnLoginAsync(PLATFORM_GSNCOM)
                .then((loginResponse) =>{
                    this.setState({ FBResForDevice: loginResponse });
                    this.props.setGSNCredentials(loginResponse);
                    //Analytics End
                    console.log('STEP 1: GSN LOGIN ::', loginResponse);
                    const userId =  loginResponse && loginResponse.authResponse && loginResponse.authResponse.userID;
                    console.log('STEP 1: USER ID ::', userId);
                    this.props.setFBUserId(userId);
                    const registerDevice = function(z){
                        return function() {
                            //Register Device:WEB for Seam
                            return SeamServices.registerDeviceGsnCom(z);
                        };
                    }
                    //Analytics Start
                    const lodData = {
                        eventGroup: 'STARTUP',
                        eventCode: 'BEGIN',
                        eventAttributes: {
                            ui_element: 'loginToGSNCom',
                            web_user_agent: !browserDetails.userAgent
                                ? 'UNKNOWN'
                                : browserDetails.userAgent,
                            os_family: !browserDetails.osName ? 'UNKNOWN' : browserDetails.osName,
                            os_version: !browserDetails.osVersion
                                ? 'UNKNOWN'
                                : browserDetails.osVersion,
                            browser_family: !browserDetails.browserName
                                ? 'UNKNOWN'
                                : browserDetails.browserName,
                            browser_version: !browserDetails.browserVersion
                                ? 'UNKNOWN'
                                : browserDetails.browserVersion,
                            fb_uid: userId,
                            os_bit_architecture: browserDetails && browserDetails.osBit ? browserDetails && browserDetails.osBit : '',
                        },
                        country: this.state.fbUserCountryCode,
                        operatingSystem: this.state.operatingSystem,
                        deviceId: userId,
                        appVersion: process.env.GSNCOM_APP_VERSION,
                    };
                    const payload = analyticsLogs.setAnalyticsData(lodData);
                    analyticsManagerInstance.logAnalytics(payload).then(() => {
                        console.log('STEP 2: STARTUP BEGIN LOGGED ::', loginResponse);
                    });
                    //Analytics End
                    const getFeatureConfigData = function(y) {
                        return function() {
                            return SeamServices.getFeatureConfigConsentConfig(y);
                        };
                    };
                    this.setState({ userIdToSendToRegister: userId });
                    return Promises.resolve([
                        getFeatureConfigData(loginResponse),
                        registerDevice(userId),
                    ]).mapSeries((method) => {
                        return method();
                    });
                }).then((resp)=>{
                    console.log('STEP 3: GSN CONSENT CONFIG DATA ::', resp);
                    const gsnUserCredentials = this.state.FBResForDevice;
                    const userId = gsnUserCredentials && gsnUserCredentials.authResponse && gsnUserCredentials.authResponse.userID;
                    const mydata  = findItem(resp, FC_CONSENT_CONFIG);
                    const consentConfig = mydata && JSON.parse(mydata.getValue()[FC_CONSENT_CONFIG]);

                    this.setState({consentConfig: consentConfig});
                    this.setState({ userProfileImage: gsnUserCredentials && gsnUserCredentials.picture && gsnUserCredentials.picture.data && gsnUserCredentials.picture.data.url });
                    this.setState({ userFbData: gsnUserCredentials });
                    SeamServices.getCountryCodeByIPHandler()
                        .then((res) => {
                            const gdprCountry = res ? res.toUpperCase() : '';
                            this.setState({ fbUserCountryCode: gdprCountry });
                            console.log(`STEP 4-0: GSN USER COUNTRY `, gdprCountry);
                            this.proceedForGDPRForGSNCom(gdprCountry, userId, this.props.platform);

                        }).catch((error) => {
                            console.error('Error : Seam getCountryCodeByIPHandler', {message: error});
                            const gdprCountry = this.getCountryCodeForFallBack();
                            this.setState({ fbUserCountryCode: gdprCountry });
                            this.proceedForGDPRForGSNCom(gdprCountry, userId, this.props.platform);
                            //Analytics - ENGINEERING-start
                            const errorLog = {
                                eventGroup: ENG_ERROR.eventGroup,
                                eventCode: ENG_ERROR.eventCode,
                                deviceId: userId,
                                eventAttributes: {
                                    ui_element: 'getCountryCodeByIPHandler',
                                    from_where: 'loginToGSNCom',
                                    fb_uid: userId,
                                },
                                country: gdprCountry,
                                operatingSystem: this.state.operatingSystem,
                                appVersion: process.env.GSNCOM_APP_VERSION,
                            };
                            this.state.logEngineeringEvents && analyticsManagerInstance
                                .logAnalytics(analyticsLogs.setAnalyticsData(errorLog))
                                .then(() => {});
                            //Analytics - ENGINEERING-End

                        });
                }).catch((error) => {
                    console.error('Error: GSNCOM STARTUP BEGIN gsnLoginAsync', {message:error});
                    const gsnUserCredentials = this.state.FBResForDevice;
                    const userId =  gsnUserCredentials && gsnUserCredentials.authResponse && gsnUserCredentials.authResponse.userID;
                    //Analytics Start
                    const lodData = {
                        eventGroup: 'STARTUP',
                        eventCode: 'LOGIN',
                        eventSubCode: 'FAIL',
                        sessionToken: '',
                        eventAttributes: {
                            ui_element: 'loginToGSNCom',
                            from_where: 'fb_login',
                            attr1: 'seam',
                            attr2: '1',
                            fb_uid: userId ? null : userId,
                        },
                        country: this.state.fbUserCountryCode,
                        operatingSystem: this.state.operatingSystem,
                        deviceId: userId ? null : userId,
                        appVersion: process.env.GSNCOM_APP_VERSION,
                    };
                    const payload = analyticsLogs.setAnalyticsData(lodData);
                    analyticsManagerInstance.logAnalytics(payload).then(() => { });
                    //Analytics End
                });
        }
    }

    proceedForGDPRForGSNCom(gdprCountry, userId, platform){
        if (COUNTRY_LOOKUP.includes(gdprCountry)) {
            console.log('STEP 4-1: GSNCOM GDPR STATUS - Required for Country ', gdprCountry);
            SeamServices.validateAndRetrieveHandler(userId, platform)
                .then((data) => {
                    console.log('STEP 4-1-1: GSN GDPR Consent Need to Accept', data && data._requiresOptIn);
                    if (data && data._requiresOptIn) {
                        //Analytics Start
                        const lodData = {
                            eventGroup: 'STARTUP',
                            eventCode: 'SCREEN',
                            eventSubCode: 'OPEN',
                            sessionToken: null,
                            eventAttributes: {
                                ui_element: 'gdpr',
                                from_where: 'authorizeAppWithSeam',
                                fb_uid: userId,
                            },
                            country: gdprCountry,
                            operatingSystem: this.state.operatingSystem,
                            deviceId: userId,
                            appVersion: process.env.GSNCOM_APP_VERSION,
                        };
                        const payload = analyticsLogs.setAnalyticsData(lodData);
                        analyticsManagerInstance.logAnalytics(payload).then(() => {
                            console.log('STEP 4-1-2: GSN GDPR STARTUP SCREEN OPEN EVENT TRIGGRED');
                        });
                        //Analytics End
                        this.props.openModal('Gdpr', {
                            OptinResponse: data,
                            fbUserId: userId,
                            SeamUser: null,
                            proceedAfterConsent: this.proceedAfterGDPRConsentForGSNCom,
                            country: gdprCountry,
                            operatingSystem: this.state.operatingSystem,
                            platform: platform
                        });
                    } else {
                        this.proceedAfterGDPRConsentForGSNCom();
                    }
                })
                .catch((error) => {
                    console.error('Error : Seam validateAndRetrieveHandler', {message: error});
                    //Analytics - ENGINEERING-start
                    const errorLog = {
                        eventGroup: ENG_ERROR.eventGroup,
                        eventCode: ENG_ERROR.eventCode,
                        deviceId: userId,
                        eventAttributes: {
                            ui_element: 'validateAndRetrieveHandler',
                            from_where: 'loginToGSNCom',
                            fb_uid: userId,
                        },
                        country: this.state.fbUserCountryCode,
                        operatingSystem: this.state.operatingSystem,
                        appVersion: process.env.GSNCOM_APP_VERSION,
                    };
                    this.state.logEngineeringEvents && analyticsManagerInstance
                        .logAnalytics(analyticsLogs.setAnalyticsData(errorLog))
                        .then(() => { });
                    //Analytics - ENGINEERING-End
                    PubSub.publish('SEAM_SERVICE_ERROR', true);
                });
        } else {
            console.log('STEP 4-2: GSN GDPR STATUS - NOT Required for Country ', gdprCountry);
            this.proceedAfterGDPRConsentForGSNCom();
        }
    }
    proceedAfterGDPRConsentForGSNCom(){
        const gsnUserCredentials = this.state.FBResForDevice;
        console.log('WHAT IS CREDENTIALS COMING:::', gsnUserCredentials);
        const userId = gsnUserCredentials.authResponse && gsnUserCredentials.authResponse.userID && gsnUserCredentials.authResponse.userID;
        const credential = gsnUserCredentials.authResponse && gsnUserCredentials.authResponse.userModel && gsnUserCredentials.authResponse.userModel.credentials;
        if(this.state.consentConfig && this.state.consentConfig.CCPA && this.state.consentConfig.CCPA.enabled){
            SeamServices.userConsentInfoGSNCOM(credential, this.state.fbUserCountryCode).then((resp)=>{
                console.log('STEP 5: GSN CCPA CONSENT REQUIRED ::', resp);
                const isShowAds = resp && resp.pi.toUpperCase() === "ACCEPTED" ? false : true;
                this.setState({showAds_ccpa_users: isShowAds});
                if(resp.ccpa === 'PENDING'){
                    this.props.openModal('Ccpa', {
                        ccpaResponse: this.state.consentConfig,
                        fbUserId: userId,
                        SeamUser: null,
                        proceedAfterCCPAConsent: this.proceedAfterCCPAConsentForGSNCom,
                        country: this.state.fbUserCountryCode,
                        operatingSystem: this.state.operatingSystem,
                        platform: this.props.platform
                    });
                    //Analytics Start
                    const lodData = {
                        eventGroup: 'STARTUP',
                        eventCode: 'CCPA',
                        eventSubCode: 'REQUEST',
                        sessionToken: null,
                        eventAttributes: {
                            ui_element: 'ccpa',
                            from_where: 'loginToGSNCom',
                            fb_uid: userId,
                        },
                        country: this.state.fbUserCountryCode,
                        operatingSystem: this.state.operatingSystem,
                        deviceId: userId,
                        appVersion: process.env.GSNCOM_APP_VERSION,
                    };
                    const payload = analyticsLogs.setAnalyticsData(lodData);
                    analyticsManagerInstance.logAnalytics(payload).then(() => {
                        console.log('STEP 5-1: GSNCOM STARTUP CCPA REQUEST EVENT TRIGGRED');
                    });
                    //Analytics End

                }else{
                    console.log('STEP 5-1: GSNCOM CCPA CONSENT NOT REQUIRED ::', resp.ccpa);
                    this.proceedToSeamAuthForGSNCom(resp, resp, false, gsnUserCredentials);
                }
            }).catch((error)=>{
                console.log('Error: Seam gdpr userConsentInfoGSNCOM ', {message:error});
                //Analytics - ENGINEERING-start
                const errorLog = {
                    eventGroup: ENG_ERROR.eventGroup,
                    eventCode: ENG_ERROR.eventCode,
                    deviceId: userId,
                    eventAttributes: {
                        ui_element: 'ccpa_consent',
                        from_where: 'loginToGSNCom',
                        fb_uid: userId,
                    },
                    country: this.state.fbUserCountryCode,
                    operatingSystem: this.state.operatingSystem,
                    appVersion: process.env.GSNCOM_APP_VERSION,
                };
                this.state.logEngineeringEvents && analyticsManagerInstance
                    .logAnalytics(analyticsLogs.setAnalyticsData(errorLog))
                    .then(() => { });
                //Analytics - ENGINEERING-End
                PubSub.publish('SEAM_SERVICE_ERROR', true);
            });
        }else{
            //this.proceedAfterConsent({});
            this.proceedToSeamAuthForGSNCom({}, {}, false, this.state.FBResForDevice);
        }
    }
    proceedAfterCCPAConsentForGSNCom(consentPayload, updatedConsentPayload, isRecordConsent){
        //this.proceedAfterConsent(updatedConsentPayload);
        const isShowAds = updatedConsentPayload && updatedConsentPayload.pi.toUpperCase() === "ACCEPTED" ? false : true;
        this.setState({showAds_ccpa_users: isShowAds});
        console.log('STEP 6-1: AFTER GSN CCPA - RECEIVED - consentPayload? ', consentPayload);
        console.log('STEP 6-2: AFTER GSN CCPA - RECEIVED - updatedConsentPayload? ', updatedConsentPayload);
        console.log('STEP 6-3: AFTER GSN CCPA - RECEIVED - isRecordConsent? ', isRecordConsent);
        this.proceedToSeamAuthForGSNCom(consentPayload, updatedConsentPayload, isRecordConsent, this.state.FBResForDevice);
    }
    proceedToSeamAuthForGSNCom(consentPayload, updatedConsentPayload,isRecordConsent, gsnComLogin){
        console.log('STEP 7: GSN COM AFTER CCPA PROCEED TO SEAM AUTH');
        const userId = gsnComLogin && gsnComLogin.authResponse && gsnComLogin.authResponse.userID;
        const userModel = gsnComLogin && gsnComLogin.authResponse && gsnComLogin.authResponse.userModel;
        this.authProceed(gsnComLogin)
        .then((loginResponse)=>{
            const authorizeAppWithSeam = function (v) {
                return function () {
                    return SeamServices.authorizeSeamGSNCom(v);
                };
            };
            return Promises.resolve([
                authorizeAppWithSeam(userModel),
            ]).mapSeries((method) => {
                return method();
            });
        }).then((data)=> {
            const loginTimeSyncDelay = new Date().getTime();
            this.setState({ loginTimeSyncDelay:  loginTimeSyncDelay});
            console.log('WHAT IS AUTH RESPONSE ::: ', data[0]);
            this.getFeatureConfigMiniGameMath();
            const seamObj = data[0];
            console.log('STEP 8: GSN SEAM AUTH USER RESPONSE', seamObj);
            this.setState({ seamUser: seamObj });
            const seamUserId = seamObj && seamObj._id
            const sessionToken = seamObj && seamObj.sessionToken;
            window.TrackJS && TrackJS.configure({
                userId: seamUserId,
                sessionId: sessionToken,
                version: process.env.GSNCOM_APP_VERSION
            });
            isRecordConsent && SeamServices.recordUserConsent(consentPayload, seamUserId, sessionToken).then(()=>{});
            SeamServices.linkDevice( sessionToken, userId)
            .then((resp) => {
                console.log('GSN SEAM LINK DEVICE FOR WEB_ID:', userId);
                //Analytics Start
                const lodData = {
                    eventGroup: 'STARTUP',
                    eventCode: 'LOGIN',
                    eventSubCode: 'SUCCESS',
                    sessionToken: sessionToken,
                    eventAttributes: {
                        ui_element: 'authorizeAppWithSeam',
                        from_where: 'loginToGSNCom',
                        attr1: 'seam',
                        attr2: '1',
                        fb_uid: userId
                    },
                    country: this.state.fbUserCountryCode,
                    operatingSystem: this.state.operatingSystem,
                    deviceId: userId,
                    appVersion: process.env.GSNCOM_APP_VERSION,
                };
                const payload = analyticsLogs.setAnalyticsData(lodData);
                analyticsManagerInstance.logAnalytics(payload).then(() => { });
                //Analytics End
            }).catch((error)=>{
                console.error('Error: seam linkDevice', {message: error});
                //Analytics - ENGINEERING-start
                const errorLog = {
                    eventGroup: ENG_ERROR.eventGroup,
                    eventCode: ENG_ERROR.eventCode,
                    deviceId: userId,
                    eventAttributes: {
                        ui_element: 'authorizeAppWithSeam',
                        from_where: 'linkDevice',
                        fb_uid: userId,
                    },
                    country: this.state.fbUserCountryCode,
                    operatingSystem: this.state.operatingSystem,
                    appVersion: process.env.GSNCOM_APP_VERSION,
                };
                this.state.logEngineeringEvents && analyticsManagerInstance
                    .logAnalytics(analyticsLogs.setAnalyticsData(errorLog))
                    .then(() => { });
                //Analytics - ENGINEERING-End
            });
            console.log('STEP 8-2: GSN SEAM AUTH SUCCESSFULL COMPLETED');
            SeamServices.getRollOutFC(sessionToken)
            .then((data) => {
                let appVersion;
                const appVersionValue = data.getValue()[FC_WEBGL_ROLLOUT];
                const appVersionObj = appVersionValue && JSON.parse(appVersionValue);
                if(appVersionObj && appVersionObj.enabled){
                    appVersion = appVersionObj.version;
                    SeamServices.registerDeviceGsnComWithAppid(this.state.userIdToSendToRegister, appVersion).then((res)=>{
                        SeamServices.linkDevice( sessionToken, this.state.userIdToSendToRegister)
                    });
                    this.setState({webglRolloutConfig: appVersion});
                    //Analytics Start
                    const lodData = {
                        eventGroup: ENG_EVENT.eventGroup,
                        eventCode: ENG_EVENT.eventCode,
                        eventSubCode: ENG_EVENT.eventSubCode,
                        sessionToken: sessionToken,
                        eventAttributes: {
                            ui_element: 'authorizeAppWithSeam',
                            from_where: 'gsncom_login',
                            attr1: 'seam',
                            attr2: '1',
                            fb_uid: userId
                        },
                        country: this.state.fbUserCountryCode,
                        operatingSystem: this.state.operatingSystem,
                        deviceId: userId,
                        appVersion:appVersion,
                    };
                    const payload = analyticsLogs.setAnalyticsData(lodData);
                    analyticsManagerInstance.logAnalytics(payload).then(() => { });
                    //Analytics End
                }
                window.TrackJS && TrackJS.configure({
                    userId: seamUserId,
                    sessionId: sessionToken,
                    version: appVersion ? appVersion : process.env.GSNCOM_APP_VERSION,
                });
                this.proceedAfterConsentForGSNCom(updatedConsentPayload, loginTimeSyncDelay, appVersion);
            }).catch((error)=>{
                //Analytics Start
                const lodData = {
                    eventGroup: ENG_EVENT.eventGroup,
                    eventCode: ENG_EVENT.eventCode,
                    eventSubCode: ENG_EVENT.eventSubCode,
                    sessionToken: sessionToken,
                    eventAttributes: {
                        ui_element: 'authorizeAppWithSeam',
                        from_where: 'gsncom_login',
                        attr1: 'seam',
                        fb_uid: userId
                    },
                    country: this.state.fbUserCountryCode,
                    operatingSystem: this.state.operatingSystem,
                    deviceId: userId,
                    appVersion: process.env.GSNCOM_APP_VERSION,
                };
                const payload = analyticsLogs.setAnalyticsData(lodData);
                analyticsManagerInstance.logAnalytics(payload).then(() => { });
                //Analytics End
                window.TrackJS && TrackJS.configure({
                    userId: seamUserId,
                    sessionId: sessionToken,
                    version: process.env.GSNCOM_APP_VERSION,
                });
                this.proceedAfterConsentForGSNCom(updatedConsentPayload, loginTimeSyncDelay, undefined);
            });
        }).catch((error)=>{
            const e_error = error
            const l_error = {
                status_code: e_error._statusCode,
                message: e_error._message
            }
            const messageToSend = {
                type : 'Error',
                msg : l_error
            }
            window.parent.postMessage(JSON.stringify(messageToSend), '*');
            console.error('Error: GSN SEAM AUTH FAILED RESPONSE', l_error);
            //Analytics Start
            const lodData = {
                eventGroup: 'STARTUP',
                eventCode: 'LOGIN',
                eventSubCode: 'FAIL',
                sessionToken: '',
                eventAttributes: {
                    ui_element: 'authorizeAppWithSeam',
                    from_where: 'loginToGSNCom',
                    attr1: 'seam',
                    attr2: '1',
                    fb_uid: userId,
                },
                country: this.state.fbUserCountryCode,
                operatingSystem: this.state.operatingSystem,
                deviceId: userId,
                appVersion: process.env.GSNCOM_APP_VERSION,
            };
            const payload = analyticsLogs.setAnalyticsData(lodData);
            analyticsManagerInstance.logAnalytics(payload).then(() => { });
            //Analytics End
            PubSub.publish('SEAM_SERVICE_ERROR', true);
        });
    }
    proceedAfterConsentForGSNCom(consentPayload, loginTimeSyncDelay, appVersionRollOut) {
        const userId = this.state.FBResForDevice && this.state.FBResForDevice.authResponse && this.state.FBResForDevice.authResponse.userID;
        const seamObject = this.state.seamUser;
        console.log('STEP 10: GSN SEAM OBJECT AFTER LOGIN', seamObject);
        this.props.setSeamSession(seamObject);
        this.setState({ authenticateComplete: true });
        this.getFeatureConfigRefreshRate();
        this.getCountriesWhereAdsCanRefresh();
        this.getFeatureConfigGlobalToggle();
        this.getToken('mini_lobby_load');
        this.getUserTokenMiniGame();
        this.getFeatureConfigEngineeringFlag();
        const appVersion = getOptionalBuild('gsncom');
        console.log('OPTIONAL BUILD DETAILS::', appVersion);

        const webglPayload = {
            facebookUserObject:{
                seamId: seamObject._id,
                name: seamObject.profile.gsnUsername,
                email: seamObject.profile.gsnEmail,
                pictureUrl: seamObject.profile.gsnPictureUrl,
                sessionToken: seamObject.sessionToken,
                firstName: seamObject.profile.gsnUsername,
                countryCode: this.state.fbUserCountryCode ? this.state.fbUserCountryCode : 'DEFAULT',
                platformUserId: userId,
                lastLoginDate: seamObject.profile.lastLoginDate,
                previousLogin: seamObject.profile.previousLogin,
                firstTimePlayer: seamObject.profile.firstTimePlayer ? true : false,
                outOfToken: seamObject.profile.outOfToken,
                consentPayload: consentPayload,
                platform : this.props.platform,
                loginTimeSyncDelay:0,
                profile: seamObject.profile,
                userData: seamObject.userData
            },
            analyticsLogs: analyticsLogs,
            FBResForDevice: this.state.FBResForDevice,
            userId: userId,
            logEngineeringEvents: this.state.logEngineeringEvents,
            country: this.state.fbUserCountryCode,
            operatingSystem: this.state.operatingSystem,
            webAppVersion: appVersion ? appVersion : (appVersionRollOut ? appVersionRollOut : process.env.GSNCOM_APP_VERSION),
            browserName: this.state.browserName,
            loginTimeSyncDelay: loginTimeSyncDelay,
            appVersionRollOut: appVersionRollOut
        };
        console.log('STEP 11: WEBGL PAYLOAD ', webglPayload);
        this.setState({ startTime: new Date().getTime() });
        setTimeout(() => {
            if (
                this.state.webGLSupport &&
                this.state.webGLSupport.flag &&
                !this.state.isCommonError
            ) {
               webglManager(webglPayload);
            }
        }, 2000);
        this.incrementGameNameCounter();
    }

    showAccountInfo(){
        this.setState({isAccountInfo:true});
        PubSub.publish('BLOCK_USER_TYPING', 'false');
    }
    closeAccountInfo()
    {
        this.setState({isAccountInfo:false});
        PubSub.publish('BLOCK_USER_TYPING', 'true');
    }

    logout(){
        const messageToSend = {
            type : 'info',
            msg : {
                data: "logout",
            }
        }
        window.parent.postMessage(JSON.stringify(messageToSend), '*');
        console.log('I clicked logout ....');
    }
    paymentStatus(status){
        const l_status = status;
        PubSub.publish("GSN_PAYPAL_PAYMENT_DONE",{status:l_status});
    }
    render() {
        const bg_reset =  process.env.AWS_URL + 'bg-reset.jpg?ver=' + process.env.IMAGE_VERSION;
        let bg_with_frame =  process.env.AWS_URL + 'Bg-with-frame-tiny.png?ver=' + process.env.IMAGE_VERSION;
        let tiles_game_loading =  process.env.AWS_URL + 'tiles_games_loading.png?ver=' + process.env.IMAGE_VERSION;
        let buy_tokens_btn_disabled =  process.env.AWS_URL + 'buy_tokens_btn_disabled.png?ver=' + process.env.IMAGE_VERSION;
        let coinImg =  process.env.AWS_URL + 'coin_icon.png?ver=' + process.env.IMAGE_VERSION;
        let max_token_limit =  process.env.AWS_URL + 'max-token-limit.png?ver=' + process.env.IMAGE_VERSION;
        let skipBtn =  process.env.AWS_URL + 'skip.png?ver=' + process.env.IMAGE_VERSION;
        let sprite_footer =  process.env.AWS_URL + 'sprite-newlobby-footer.png?ver=' + process.env.IMAGE_VERSION;
        let userIcon =  process.env.AWS_URL + 'user_icon.png?ver=' + process.env.IMAGE_VERSION;
        let bgFrameCls = 'frame-bg';
        const showAds = this.state.showAds;
        const showAds_ccpa_users = this.state.showAds_ccpa_users;
        const showGameAds = this.state.showGameAds;
        const authenticateCompleted = this.state.authenticateComplete;
        const imagePreview = this.state.imagePreview;
        const adsRefreshRate = this.state.adsRefreshRate;
        const homeIcon = this.state.webglLoaded && !this.state.isCommonError
            ? css['home-pic-placeholder']
            : css['home-pic-placeholder-disabled'];
        const footerMargin =   { marginTop: this.state.showWebGL ? `8.5%` : '9.5%'}
        const profileImage = this.state.userProfileImage ? this.state.userProfileImage : userIcon;
        let footerPlaceholderCSS = this.state.webglLoaded
            ? this.getClassFooter(this.state.screenW)
            : css['footer-placeholder'];
        const url = this.state.seamUser
            ? contactSupport(
                  this.state.seamUser,
                  this.state.FBResForDevice,
                  this.state.userAgent,
                  this.props.seamTokenBalance,
                  this.state.browserName,
                  this.state.browserVersion,
                  this.state.operatingSystem,
                  this.state.osVersion,
                  this.props.platform
              )
            : contactSupport(
                  '',
                  this.state.FBResForDevice,
                  this.state.userAgent,
                  this.props.seamTokenBalance,
                  this.state.browserName,
                  this.state.browserVersion,
                  this.state.operatingSystem,
                  this.state.osVersion,
                  this.props.platform,
              );
        let high_low_game_visbale, pre_lobby_loader_visbale, acctInfoHeight, acctInfoWidth;
        if(this.props.platform === PLATFORM_FB && this.state.showHighLow){
            high_low_game_visbale = this.state.displayHiLoGame && !this.state.isCommonError && this.state.webGLSupport && this.state.webGLSupport.flag && this.props.noOfTimesPlayed <= 2 ? {display:'block'} : {display:'none'};
            pre_lobby_loader_visbale = authenticateCompleted && this.state.displayPreLoader && !this.state.isCommonError && this.state.webGLSupport && this.state.webGLSupport.flag ? {display:'block'} : {display:'none'};
            bgFrameCls = 'frame-bg';
            bg_with_frame =  process.env.AWS_URL + 'Bg-with-frame-tiny.png?ver=' + process.env.IMAGE_VERSION;
        } else {
            high_low_game_visbale = this.state.displayHiLoGame && !this.state.isCommonError && this.state.webGLSupport && this.state.webGLSupport.flag && this.props.noOfTimesPlayed <= 2 && !this.state.isAccountInfo ? {display:'block'} : {display:'none'};
            pre_lobby_loader_visbale = authenticateCompleted && this.state.displayPreLoader && !this.state.isCommonError && this.state.webGLSupport && this.state.webGLSupport.flag && !this.state.isAccountInfo ? {display:'block'} : {display:'none'};
            acctInfoHeight = !this.state.isCommonError && this.state.isAccountInfo && this.state.showWebGL ? `${parseFloat(this.state.renderHeight)-20}px` : this.state.renderHeight;
            acctInfoWidth = !this.state.isCommonError && this.state.isAccountInfo && this.state.showWebGL ? `${parseFloat(this.state.renderWidth)-10}px` : this.state.renderWidth;
            bgFrameCls = 'frame-bg-gsn';
            bg_with_frame =  process.env.AWS_URL + 'Bg-with-frame-gsn-tinny.png?ver=' + process.env.IMAGE_VERSION;
        }
        return (
            <div
                className={`${css['lobby-container']}`}
                style={{
                    height: '100%',
                    width: '100%',
                    margin: '0 auto',
                    overflow: 'visible',
                }}
            >
                {!imagePreview && <div id="lobby" className={css['main-lobby-parent-div']}>
                    <div className={`row ${css['header-parent-div']}`} />
                    {!authenticateCompleted && <MiniLobbyLoader />}
                    {authenticateCompleted && (
                        <div className={`row ${css['middle-parent-div']}`} id="parentDiv">
                            {!this.state.showWebGL && (
                                <div className={`${css[bgFrameCls]}`} style={{width:`${this.state.renderWidth}`, height:`${this.state.renderHeight}`,  backgroundImage: `url(${bg_with_frame})`}}>
                                    <div className={`row ${css['top-hud-parent-div']} `} style={{ justifyContent: 'center' }}>
                                        <div
                                            style={{
                                                display: 'flex',
                                                width: '96.6%',
                                            }}
                                        >
                                            <div className={css['flex-style-1']}>
                                                <div
                                                    onClick={this.showContainer.bind(
                                                        this,
                                                        'home_button'
                                                    )}
                                                    className={homeIcon}
                                                />
                                            </div>
                                            <div className={css['flex-style-1']}>
                                                <img
                                                    src={profileImage}
                                                    className={css['profile-pic-icon']}
                                                />
                                            </div>
                                            <div className={css['flex-style-1']}>
                                                <img
                                                    src={coinImg}
                                                    className={css['coin-placeholder']}
                                                />
                                            </div>

                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flex: '7',
                                                    justifyContent: 'left',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <div className={css['token-placeholder']}>
                                                    {this.props.seamTokenBalance &&
                                                        this.props.seamTokenBalance.toLocaleString()}
                                                </div>
                                            </div>
                                            <div className={css['flex-style-1']} />
                                            <div
                                                style={{ flex: '4' }}
                                                className={css['flex-style-generic']}
                                            >
                                                <div className={css.buy_tokens_button} onClick={this.showDebugger} style={{backgroundImage: `url(${buy_tokens_btn_disabled})`}}/>
                                            </div>
                                            <div
                                                style={{ flex: '10' }}
                                                className={css['flex-style-generic']}
                                            />
                                            <div className={css['flex-style-1']}>
                                                <Settings
                                                    cookies={this.props.cookies}
                                                    optOutConsent={this.optOutConsent}
                                                    userData={this.state.userFbData}
                                                    seamUser={this.state.seamUser}
                                                    userCountryCode={this.state.fbUserCountryCode}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                    {this.props.platform === PLATFORM_FB && this.state.showHighLow && (<div className={`row ${css['game-height']}`} style={high_low_game_visbale}>
                                        <HighLowGame
                                            logEngineeringEvents={this.state.logEngineeringEvents}
                                            country={this.state.fbUserCountryCode}
                                            operatingSystem={this.state.operatingSystem}
                                            appVersion = {this.state.webglRolloutConfig} />
                                    </div>)}
                                    {this.state.webglLoaded &&
                                        this.state.webGLSupport &&
                                        !this.state.isCommonError &&
                                        this.state.webGLSupport.flag &&
                                        !this.state.isAccountInfo && this.props.platform === PLATFORM_FB && this.state.showHighLow &&(
                                            <div className={css.skip_placeholder}>
                                                <div
                                                    className={css.skip_button}
                                                    onClick={this.showContainer.bind(
                                                        this,
                                                        'skip_button'
                                                    )}
                                                    style={{backgroundImage: `url(${skipBtn})`}}
                                                />
                                            </div>
                                        )}
                                        {this.props.platform === PLATFORM_FB && this.state.showHighLow && (<div className={`row ${css.pre_lobby_loader_height}`} style={pre_lobby_loader_visbale}>
                                            <PreLobbyLoader
                                                openMiniGame={this.switchToGame}
                                                displayContainer={this.showContainer.bind(this,'auto')}
                                                country={this.state.fbUserCountryCode}
                                                operatingSystem={this.state.operatingSystem}
                                                appVersion = {this.state.webglRolloutConfig} />
                                        </div>)}
                                        {this.state.displayMaxTokensReached &&
                                            this.state.webGLSupport && this.state.webGLSupport.flag &&
                                            !this.state.isCommonError && this.props.platform === PLATFORM_FB && this.state.showHighLow && (
                                                <div className={css.reached_max_tokens}>
                                                    <div className={css.max_tokens} style={{backgroundImage: `url(${max_token_limit})`}}/>
                                                </div>
                                            )}
                                        {this.state.webGLSupport && this.state.webGLSupport.flag &&
                                        !this.state.isCommonError && !this.state.isAccountInfo && this.props.platform === PLATFORM_GSNCOM && (
                                            <div className={css.loading_Game_tiles}>
                                                <div className={css.game_tiles} style={{ backgroundImage: `url(${tiles_game_loading})`}}/>
                                                <div>
                                                    <div className={css.game_text}>We are setting up the casino for you.</div>
                                                    <div className={css.game_text_small}>The loading may take a while.</div>
                                                    <div className={css.loading_game_text_container}>
                                                        <span className={css.loading_game_text}>   Loading {this.state.currentGameloading}...   </span>
                                                    </div>
                                                </div>

                                            </div>
                                        )}

                                        {this.state.webGLSupport && this.state.webGLSupport.flag &&
                                            !this.state.isCommonError && !this.state.isAccountInfo && (this.props.platform === PLATFORM_FB && !this.state.showHighLow) && (
                                                <div className={css.loading_Game_tiles}>
                                                    <div className={css.game_tiles} style={{ backgroundImage: `url(${tiles_game_loading})`}}/>
                                                    <div>
                                                        <div className={css.game_text}>We are setting up the casino for you.</div>
                                                        <div className={css.game_text_small}>The loading may take a while.</div>
                                                        {getOS() !== 'MacOS' && (
                                                            <div className={css.loading_game_text_container} style={{marginTop:'0.5%'}}>
                                                                <span className={css.loading_game_text}>   Loading {this.state.currentGameloading}...   </span>
                                                            </div>
                                                        )}
                                                        {getOS() === 'MacOS' && (
                                                            <div className={css.loading_game_text_container} style={{marginTop:'4.5%'}}>
                                                                <span className={css.loading_game_text}>   Loading {this.state.currentGameloading}...   </span>
                                                            </div>
                                                        )}
                                                    </div>

                                                </div>
                                            )}
                                        {(this.props.gameIsOver || this.state.gameIsOver) &&
                                            !this.state.isCommonError &&
                                            this.state.webGLSupport && this.state.webGLSupport.flag && this.props.platform === PLATFORM_FB && this.state.showHighLow && (
                                                <Navigation
                                                    switchToContainer={this.showContainer.bind(
                                                        this,
                                                        'auto'
                                                    )}
                                                />
                                            )}
                                    </div>
                                    {!this.state.isCommonError && (<div className={css.progress_bar_placeholder}>
                                        <ProgressBar />
                                    </div>)}
                                    {this.state.isCommonError &&
                                        this.state.webGLSupport &&
                                        this.state.webGLSupport.flag &&
                                        !this.state.showWebGL && (
                                            <CommonError
                                                errType={this.state.isContainerError}
                                                reloadApplication={this.reloadApplication}
                                                browserName={this.state.browserName}
                                                screensize={{height: this.state.renderHeight, width: this.state.renderWidth}}
                                            />
                                        )}
                                    {!this.state.isCommonError && this.state.isAccountInfo && this.props.platform === PLATFORM_GSNCOM && (
                                        <AccountInformation seamUser = {this.state.seamUser} closeAccountInfo = {this.closeAccountInfo}/>
                                    )}
                                    {this.state.webGLSupport &&
                                        !this.state.webGLSupport.flag &&
                                        !this.state.showWebGL && (
                                            <IncompatibleBrowser
                                                webGLSupport={this.state.webGLSupport}
                                                lobbyStartTime={this.state.lobbyStartTime}
                                                seamUser={this.state.seamUser}
                                                country={this.state.fbUserCountryCode}
                                                operatingSystem={this.state.operatingSystem}
                                            />
                                        )}
                                </div>
                            )}
                            {!this.state.isCommonError && this.state.isAccountInfo && this.state.showWebGL && this.props.platform === PLATFORM_GSNCOM && (
                                <div className={`${css['frame-bg-acctInfo']}`} style={{width:acctInfoWidth, height:acctInfoHeight, backgroundImage: `url(${bg_reset})`}}>
                                    <AccountInformation seamUser = {this.state.seamUser} closeAccountInfo = {this.closeAccountInfo}/>
                                </div>
                            )}

                            <div id="gameContainerCover" className={`${css['game-container-style']}`}>
                                <div
                                    id="gameContainer"
                                    className={css.gamecontainer}
                                    style={{
                                        margin: '0 auto',
                                        overflow: 'visible',
                                    }}
                                />
                            </div>
                        </div>
                    )}
                    {authenticateCompleted && (
                        <div className={`${footerPlaceholderCSS}`} style={footerMargin}>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className={css['social-icons']}>
                                        <a
                                            className={`${css['sprite-footer']} ${
                                                css['footer-fb']
                                                } ${css.icon}`}
                                            rel="external"
                                            target="_blank"
                                            href="https://www.facebook.com/GSNcasino"
                                            style={{backgroundImage: `url(${sprite_footer})`}}
                                        />
                                        <a
                                            className={`${css['sprite-footer']} ${
                                                css['footer-instagram']
                                                } ${css.icon}`}
                                            rel="external"
                                            target="_blank"
                                            href="https://www.instagram.com/gsncasinoofficial/"
                                            style={{backgroundImage: `url(${sprite_footer})`}}
                                        />
                                        <a
                                            className={`${css['sprite-footer']} ${
                                                css['footer-twitter']
                                                } ${css.icon}`}
                                            rel="external"
                                            target="_blank"
                                            href="https://twitter.com/gsngames"
                                            style={{backgroundImage: `url(${sprite_footer})`}}
                                        />
                                        <a
                                            className={`${css['sprite-footer']} ${
                                                css['footer-youtube']
                                                } ${css.icon}`}
                                            rel="external"
                                            target="_blank"
                                            href="https://www.youtube.com/user/GSNGamesVideos"
                                            style={{backgroundImage: `url(${sprite_footer})`}}
                                        />
                                        <a
                                            className={`${css['sprite-footer']} ${
                                                css['footer-linkedin']
                                                } ${css.icon}`}
                                            rel="external"
                                            target="_blank"
                                            href="https://www.linkedin.com/company/gsn_games?trk=ppro_cprof"
                                            style={{backgroundImage: `url(${sprite_footer})`}}
                                        />
                                        <a
                                            className={`${css['sprite-footer']} ${
                                                css['footer-apple-store']
                                                } ${css.icon}`}
                                            rel="external"
                                            target="_blank"
                                            href="https://itunes.apple.com/us/app/gsn-casino-wheel-fortune-slots/id469231420?mt=8"
                                            style={{backgroundImage: `url(${sprite_footer})`}}
                                        />
                                        <a
                                            className={`${css['sprite-footer']} ${
                                                css['footer-google-play']
                                                } ${css.icon}`}
                                            rel="external"
                                            target="_blank"
                                            href="https://play.google.com/store/apps/details?id=com.gsn.android.casino&hl=en"
                                            style={{backgroundImage: `url(${sprite_footer})`}}
                                        />
                                        <a
                                            className={`${css['sprite-footer']} ${
                                                css['footer-amazon']
                                                } ${css.icon}`}
                                            rel="external"
                                            target="_blank"
                                            href="http://www.amazon.com/GSN-Casino-Fortune-Ghostbusters-American/dp/B008K6G8CK"
                                            style={{backgroundImage: `url(${sprite_footer})`}}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row" style={{marginBottom: '10px', borderBottom: '4px solid white', width:'80%', marginLeft:'auto', marginRight:'auto', marginTop:'auto'}}>
                                <div className="col-md-12">
                                    <div className={css['support-links']}>
                                        <span
                                            className={css['fb-like-container']}
                                            href="https://www.facebook.com/GSNcasino"
                                            layout="button"
                                            width="49px"
                                        />
                                        <span className="link-container">
                                            <a
                                                className={css.link}
                                                target="_blank"
                                                rel="external"
                                                href={url}
                                            >
                                                Contact Support
                                            </a>
                                        </span>
                                        <span className="link-container">
                                            <a
                                                className={css.link}
                                                rel="external"
                                                href="//gsngamesnetwork.com/terms.html"
                                                target="_new"
                                                data-tracking="legalLinks|tos"
                                            >
                                                Terms of Use
                                            </a>
                                        </span>
                                        {this.props.platform === PLATFORM_GSNCOM && (<span className={`link-container ${css['myAccount']}`} onClick={this.showAccountInfo}>
                                            My Account
                                        </span>)}
                                        {this.props.platform === PLATFORM_GSNCOM && (<span className={`link-container ${css['logout']}`} onClick ={this.logout}>
                                            Log Out
                                        </span>)}
                                        <span className="link-container last">
                                            <a
                                                rel="external"
                                                className={css.link}
                                                href="//gsngamesnetwork.com/privacy.html"
                                                target="_new"
                                                data-tracking="legalLinks|privacy"
                                            >
                                                Privacy Policy
                                            </a>
                                        </span>

                                        <span className={css.copyright}>
                                            &copy; {new Date().getFullYear()} Game Show Network
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-1" />
                                <div className="col-md-5" style={{ marginLeft: '1%' }}>
                                    {showAds_ccpa_users && showAds && showGameAds && this.props.platform === PLATFORM_FB && (
                                        <Ads300X50_1
                                            showGameAds={this.state.showGameAds}
                                            adsToken={this.state.adsToken}
                                            adsChannel={this.state.adsChannel}
                                            refreshEnabled={this.state.refreshEnabled}
                                            adsTargetArguments={this.state.adsTargetArguments}
                                        />
                                    )}
                                </div>
                                <div className="col-md-5">
                                    {showAds_ccpa_users && showAds && showGameAds && this.props.platform === PLATFORM_FB && (
                                        <Ads300X50_2
                                            showGameAds={this.state.showGameAds}
                                            adsToken={this.state.adsToken}
                                            adsChannel={this.state.adsChannel}
                                            refreshEnabled={this.state.refreshEnabled}
                                            adsTargetArguments={this.state.adsTargetArguments}
                                        />
                                    )}
                                </div>
                                <div className="col-md-1" />
                            </div>
                            <div className="row">
                                <div className="col-md-12" style={{ marginBottom: '10px' }}>
                                    {showAds_ccpa_users && showAds && showGameAds && this.props.platform === PLATFORM_FB && (
                                        <AdsGame
                                            showGameAds={this.state.showGameAds}
                                            adsToken={this.state.adsToken}
                                            adsChannel={this.state.adsChannel}
                                            refreshEnabled={this.state.refreshEnabled}
                                            adsTargetArguments={this.state.adsTargetArguments}
                                        />
                                    )}
                                    {showAds_ccpa_users && showAds && !showGameAds && this.props.platform === PLATFORM_FB && (
                                        <AdsHome
                                            showGameAds={this.state.showGameAds}
                                            adsToken={this.state.adsToken}
                                            adsChannel={this.state.adsChannel}
                                            refreshEnabled={this.state.refreshEnabled}
                                            adsTargetArguments={this.state.adsTargetArguments}
                                        />
                                    )}
                                </div>
                            </div>
                            <div>
                                 <AdsVideo
                                     exitFullScreen={this.exitFromFullScreen}
                                     userData={this.state.userFbData}
                                     platform={this.props.platform}
                                 />
                             </div>
                        </div>
                    )}
                </div>}

                {imagePreview && <div id="lobby" style={{backgroundColor:'lightgrey', height:'100%'}}>
                    <ImagePreview />
                </div>}

                {this.state.miniLobby_debug_popup &&
                    this.props.usersToHandle && (
                        <div className="row" style={{ top: '10%', position: 'absolute', left: '5%', zIndex: '9', backgroundColor: 'yellow' }}>
                            <div className="col-md-6">
                                <div className="row" style={{ marginTop: '20px' }}>
                                    <div className="col-md-6" style={{ textAlign: 'left' }}>Width</div>
                                    <div className="col-md-6" style={{ textAlign: 'right' }}>
                                        <input type="text" name="miniW" value={this.state.miniW} onChange={this.updateInputValueW}></input>
                                    </div>
                                </div>
                                <div className="row" style={{ marginTop: '20px' }}>
                                    <div className="col-md-6" style={{ textAlign: 'left' }}>Height</div>
                                    <div className="col-md-6" style={{ textAlign: 'right' }}>
                                        <input type="text" name="miniH" value={this.state.miniH} onChange={this.updateInputValueH}></input>
                                    </div>
                                </div>
                                <div className="row" style={{ marginTop: '20px', marginBottom: '10px' }}>
                                    <div className="col-md-12" style={{ textAlign: 'center', width: '70%', marginLeft: '50%' }}>
                                        <button type="button" onClick={() => this.setResolution()}>Click Me!</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
            </div>
        );
    }
}
