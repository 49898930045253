import { connect } from 'react-redux';
import GameSection from './GameSection';
import {
    openModal,
    registerGameCount,
    isMiniGameOver,
    updateWinCount,
    updateGameRound,
    updateWinText,
    setLeftCardVal,
    setRightCardVal,
    setButtonClicked,
} from '../../store/app/actions';

const mapStateToProps = (state) => ({
    noOfTimesPlayed: state.app.userConfig.noOfTimesPlayed,
    maximumTokens: state.app.userConfig.maximumTokens,
    seamTokenBalance: state.app.userConfig.seamTokenBalance,
    countWin: state.app.userConfig.countWin,
    gameRoundNo: state.app.userConfig.gameRoundNo,
    countWinText: state.app.userConfig.countWinText,
    totalWinAmount: state.app.userConfig.totalWinAmount,
    seamSession: state.app.userConfig.seamSession,
    gsnCredentials: state.app.userConfig.gsnCredentials,
    fbUserId: state.app.userConfig.fbUserId,
    tokensWonPerRound: state.app.userConfig.tokensWonPerRound,
    platform: state.app.platform,
});

const mapDispatchToProps = {
    openModal,
    registerGameCount,
    isMiniGameOver,
    updateWinCount,
    updateGameRound,
    updateWinText,
    setLeftCardVal,
    setRightCardVal,
    setButtonClicked,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GameSection);
