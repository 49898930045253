import * as React from 'react';
import css from './style.css';
export default class ErrorBoundary extends React.Component<PropsT, StateT> {
    constructor(props) {
        super(props);
        this.state = {
            hasError : false,
        };
    }
    componentDidCatch(error, errorInfo) {
        if (errorInfo && errorInfo.componentStack) {
            // The component stack is sometimes useful in development mode
            // In production it can be somewhat obfuscated, so feel free to omit this line.
            console.info(errorInfo.componentStack);
        }
        window.TrackJS && TrackJS.track(error);
        this.setState({hasError:true});
        console.error('We received Error object =  ', error);
        console.error('We received Error Info object =  ', errorInfo);
    }
    render() {
        let bg_with_frame_error =  process.env.AWS_URL + 'Bg-with-frame-tiny.png?ver=' + process.env.IMAGE_VERSION;
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <div className={`row ${css.err_top}`} style={{ backgroundImage: `url(${bg_with_frame_error})`}}>
                    <div className="col-md-12">
                        <div className={`row ${css.err_content}`}>
                            <div className="col-md-12">
                                <div className={css.text_2}> Oops! Something went wrong. </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
          }

          return this.props.children;
    }
}
