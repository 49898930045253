export default function contactSupport(seamUser, FbData, userAgent, token, browserName,browserVersion,osName, osVersion, platform) {
    let baseUrl = 'https://gsnsocial.wufoo.com/forms/gsn-facebook-casino-free-slots-bingo/?';
    let Field32 = '';//Seam ID
    let Field34 = osName + '_' + osVersion + '|' + browserName + '_' + browserVersion + '|' + userAgent; // User Agent
    let field37 = 'No User Information'; // SLA TYPE OF PAYER
    let field40 = token; // Token Balance
    let Field47 = ''; // Facebook
    let response;
    if(platform === process.env.PLATFORM_GSNCOM){
        baseUrl = 'https://gsnsocial.wufoo.com/forms/gsncom-web-support/?';
        if(seamUser){
            Field32 = seamUser ? seamUser._id :'';
            Field47 = FbData && FbData.authResponse && FbData.authResponse.userID;
            if(seamUser.profile && seamUser.profile.isPayer !== undefined && seamUser.profile.isPayer==true){
                field37 ='payer'
            }
            else {
                field37 ='non-payer'
            }
        }else if(FbData){
            Field47 = FbData && FbData.authResponse && FbData.authResponse.userID;;
        }
        const field35 = 'GSN.COM';// Brand
        const field36 = 'GSN.COM';// APP
        const Field67 = userAgent;
        const Field66 = browserName + '_' + browserVersion;
        const Field65 = osName + '_' + osVersion;
        response = `${baseUrl}Field32=${Field32}&Field34=${Field34}&field35=${field35}&field36=${field36}&field37=${field37}&field40=${field40}&Field47=${Field47}&Field65=${Field65}&Field66=${Field66}&Field67=${Field67}`;
    }else{
        if(seamUser){
            Field32 = seamUser ? seamUser._id :'';
            Field47 = seamUser ? seamUser.profile.fbuid : '';
            if(seamUser.profile && seamUser.profile.isPayer !== undefined && seamUser.profile.isPayer==true){
                field37 ='payer'
            }
            else {
                field37 ='non-payer'
            }
        }else if(FbData){
            Field47 = FbData && FbData.authResponse && FbData.authResponse.userID;
        }
        const field35 = 'GSN Casino Canvas';// Brand
        const field36 = 'GSN Casino Canvas';// APP
        const Field59 = userAgent;
        const Field60 = browserName + '_' + browserVersion;
        const Field61 = osName + '_' + osVersion;
        response = `${baseUrl}Field32=${Field32}&Field34=${Field34}&field35=${field35}&field36=${field36}&field37=${field37}&field40=${field40}&Field47=${Field47}&Field59=${Field59}&Field60=${Field60}&Field61=${Field61}`;
    }
    return response;
}
