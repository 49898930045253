import { connect } from 'react-redux';
import MiniLobbyLoader from './MiniLobbyLoader';
import { openModal } from '../../store/app/actions';
const mapStateToProps = (state) => {
    return {
        fbDeatils: state.app.userConfig.fbDeatils,
    };
};

const mapDispatchToProps = { openModal };

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MiniLobbyLoader);
