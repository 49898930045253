/* eslint-disable */
'use strict';
import axios from 'axios';
class NetworkServices {
    constructor() {
        this._netError = 'network not available';
        this.checkNetwork = this.checkNetwork.bind(this);
        this.trackjsError = this.trackjsError.bind(this);
    }

    checkNetwork(){
        return new Promise((resolve,reject) => {
            axios.get('/checknetwork')
              .then((data) => {
                resolve(data)
              }).catch((error) => reject(error) )
        })
    };

    trackjsError(platform, userId){
        return new Promise ((resolve, reject) => {
            axios.post('https://capture.trackjs.com/capture?token=6b297f00f8d94f0e95795e2ae3c6aca1', {
                "customer": {
                    "application": platform === 'gsncom' ? process.env.CASINO_GSNCOM_APPID_TRACKJS : process.env.CASINO_FB_APPID_TRACKJS,
                    "token": "6b297f00f8d94f0e95795e2ae3c6aca1",
                    "userId": userId,
                    "version": platform === 'gsncom' ? process.env.GSNCOM_APP_VERSION : process.env.APP_VERSION
                },
            }).then(data=>resolve(data)).catch((error)=> reject(error));
        })
    }
}

export default new NetworkServices();
