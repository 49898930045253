import { connect } from 'react-redux';
import Ads300X50_1 from './Ads300X50_1';
import { closeModal, setRefreshRate, getRefreshRate } from '../../store/app/actions';
const mapStateToProps = (state) => {
    return {
        seamSession: state.app.userConfig.seamSession,
        refreshRate: state.app.userConfig.refreshRate,
    };
};

const mapDispatchToProps = {
    closeModal,
    setRefreshRate,
    getRefreshRate
 };

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Ads300X50_1);
