import { connect } from 'react-redux';
import Lobby from './Lobby';
import {
    openModal,
    setSeamSession,
    setRefreshRate,
    checkifWebglLoaded,
    checkContainer,
    closeModal,
    setFbData,
    isMiniGameOver,
    updateTokenBalance,
    setFBUserId,
    setGameMathModel,
    toggleLobbyMusic,
    setGSNCredentials,
} from '../../store/app/actions';

const mapStateToProps = (state) => {
    return {
        baseUrl: state.app.gsnConfig.cdnUrl,
        sfxEnabled: state.app.userConfig.sfxEnabled,
        tokenBalance: state.app.userConfig.tokenBalance,
        adImg: state.app.gsnConfig.cdnAdImg,
        adUrl: state.app.gsnConfig.cdnUrlAd,
        webGlUrl: state.app.gsnConfig.webGlUrl,
        seamSession: state.app.userConfig.seamSession,
        gsnCredentials: state.app.userConfig.gsnCredentials,
        refreshRate: state.app.userConfig.refreshRate,
        sToken: state.app.signedRequest,
        fbGameRoomUser: state.app.fbGameRoomUser,
        usersToHandle: state.app.usersToHandle,
        noOfTimesPlayed: state.app.userConfig.noOfTimesPlayed,
        seamTokenBalance: state.app.userConfig.seamTokenBalance,
        gameIsOver: state.app.userConfig.gameIsOver,
        gameRoundNo: state.app.userConfig.gameRoundNo,
        lobbyMusicEnabled: state.app.userConfig.lobbyMusicEnabled,
        unityAppVersion: state.app.unityAppVersion,
        platform: state.app.platform,
    };
};

const mapDispatchToProps = {
    openModal,
    setSeamSession,
    setGSNCredentials,
    setRefreshRate,
    checkifWebglLoaded,
    checkContainer,
    closeModal,
    setFbData,
    isMiniGameOver,
    updateTokenBalance,
    setFBUserId,
    setGameMathModel,
    toggleLobbyMusic,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Lobby);
