
import _ from 'lodash';
import {SCREEN_RATIO, ZOOM_LEVEL_PIX_RATIO, ZOOM_LEVEL_PIX_RATIO_FF, ZOOM_LEVEL_PIX_RATIO_IE, PLATFORM_FB} from '../constants/matcher';
import {getBrowserSupport} from '../managers/browserDetails';
import {getZoomValues} from '../constants/detectZoom';

const scrMargin = 30;
const canvasmargin = 20;
const br_ff = ['Firefox'];
const br_ie = ['Edge','Internet Explorer']
const getMinWidth = ()=>711;
let browserName;
export const toggleFullScreen = () => {
    const elemtoZoom = document.getElementById('gameContainer');
    if (!document.mozFullScreen && !document.webkitFullScreen) {
        if (elemtoZoom.mozRequestFullScreen) {
            elemtoZoom.mozRequestFullScreen();
        } else {
            elemtoZoom.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
        }
    }
};
export const gcd = (w, h) => {
    const r = h == 0 ? w : gcd(w, w % h);
    return r;
};

export const getMinHeight = () =>{
    if(screen.width>=1600){
        return (screen.height / screen.width)*screen.width - 100;
    }else if(screen.width >= 1280){
        return (screen.height / screen.width)*screen.width - 100;
    }else{
        return (screen.height / screen.width)*screen.width - 100;
    }
}
export const getWithWin = (w) => {
    if(screen.width>=4256){
        const maxWidth = 4181;
        const minWidth = 3686;
        let calWidth = w;
        if (w > maxWidth) {
            calWidth = maxWidth;
        } else if (w < minWidth) {
            calWidth = minWidth;
        }
        return calWidth;
    }else if(screen.width>=3200){
        const maxWidth = 2578;
        const minWidth = 2273;
        let calWidth = w;
        if (w > maxWidth) {
            calWidth = maxWidth;
        } else if (w < minWidth) {
            calWidth = minWidth;
        }
        return calWidth;
    }else if(screen.width>=2560){
        const maxWidth = 1938;
        const minWidth = 1709;
        let calWidth = w;
        if (w > maxWidth) {
            calWidth = maxWidth;
        } else if (w < minWidth) {
            calWidth = minWidth;
        }
        return calWidth;
    }else if(screen.width>=1920){
        const maxWidth = 1420;
        const minWidth = 1252;
        let calWidth = w;
        if (w > maxWidth) {
            calWidth = maxWidth;
        } else if (w < minWidth) {
            calWidth = minWidth;
        }
        return calWidth;
    }else if(screen.width>=1600){
        const maxWidth = 1289;
        const minWidth = 1140.6;
        let calWidth = w;
        if (w > maxWidth) {
            calWidth = maxWidth;
        } else if (w < minWidth) {
            calWidth = minWidth;
        }
        return calWidth;
    }else if(screen.width>=1400){
        const maxWidth = 1127;
        const minWidth = 993.9;
        let calWidth = w;
        if (w > maxWidth) {
            calWidth = maxWidth;
        } else if (w < minWidth) {
            calWidth = minWidth;
        }
        return calWidth;
    }else if(screen.width>=1344){
        const maxWidth = 1033;
        const minWidth = 910.9;
        let calWidth = w;
        if (w > maxWidth) {
            calWidth = maxWidth;
        } else if (w < minWidth) {
            calWidth = minWidth;
        }
        return calWidth;
    }else if(screen.width>=1280){
        const maxWidth = 969;
        const minWidth = 854;
        let calWidth = w;
        if (w > maxWidth) {
            calWidth = maxWidth;
        } else if (w < minWidth) {
            calWidth = minWidth;
        }
        return calWidth;
    }else{
        const maxWidth = 1289;
        const minWidth = 1140.6;
        let calWidth = w;
        if (w > maxWidth) {
            calWidth = maxWidth;
        } else if (w < minWidth) {
            calWidth = minWidth;
        }
        return calWidth;
    }

};
export const getWithOther = (w) => {
    if(screen.width>=1920){
        const maxWidth = 1641;
        const minWidth = 1198;
        let calWidth = w;
        if (w > maxWidth) {
            calWidth = maxWidth;
        } else if (w < minWidth) {
            calWidth = minWidth;
        }
        return calWidth;
    }else if(screen.width>=1680){
        const maxWidth = 1401;
        const minWidth = 1048;
        let calWidth = w;
        if (w > maxWidth) {
            calWidth = maxWidth;
        } else if (w < minWidth) {
            calWidth = minWidth;
        }
        return calWidth;
    }else if(screen.width>=1440){
        const maxWidth = 1161;
        const minWidth = 898;
        let calWidth = w;
        if (w > maxWidth) {
            calWidth = maxWidth;
        } else if (w < minWidth) {
            calWidth = minWidth;
        }
        return calWidth;
    }else{
        const maxWidth = 1401;
        const minWidth = 1048;
        let calWidth = w;
        if (w > maxWidth) {
            calWidth = maxWidth;
        } else if (w < minWidth) {
            calWidth = minWidth;
        }
        return calWidth;
    }

};

export const getOS = () => {
    let OSName = 'Unknown OS';
    if (navigator.appVersion.indexOf('Win') != -1) OSName = 'Windows';
    if (navigator.appVersion.indexOf('Mac') != -1) OSName = 'MacOS';
    if (navigator.appVersion.indexOf('X11') != -1) OSName = 'UNIX';
    if (navigator.appVersion.indexOf('Linux') != -1) OSName = 'Linux';
    return OSName;
}
export const getDiff = () =>{
    if(getOS()==='Windows'){
        if(screen.width>=4256){
            return 360;
        }else if(screen.width>=3200){
            return 290;
        }else if(screen.width>=2560){
            return 210;
        }else if(screen.width>=1920){
            return 170;
        }else if(screen.width>=1600){
            return 150;
        }else if(screen.width>=1440){
            return 140;
        }else if(screen.width>=1344){
            return 130;
        }else if(screen.width>=1280){
            return 110;
        }else{
            return 150;
        }
    }else{
        if(screen.width>=1440){
            return 150;
        }else if(screen.width>=1280){
            return 110;
        }else{
            return 150;
        }
    }

}

export const getCanvasResolutionOnLoad = (isUserFBgameroom, platform)=> {
    const resolution = getScreenDetails(isUserFBgameroom, platform);
    const canvas = document.getElementsByTagName('canvas') && document.getElementsByTagName('canvas')[0];
    if (canvas) {
        let diff = getDiff();
        let c_w = resolution.renderWidth && parseInt(resolution.renderWidth) - canvasmargin;
        let c_h = c_w * SCREEN_RATIO;
        canvas.width = c_w;
        canvas.height = c_h-10;//getOS() === 'Windows' ? resolution.renderHeight - diff : c_h;
    }

    console.log('RESOLUTION ON CANVAS LOAD:::', resolution);
    return resolution;
}
export const checkZoom = () => {
    const browDetails = getBrowserSupport();
    browserName = browDetails.browserDetails.browserName;
    let zoomLevel;
    const screenCssPixelRatio = (window.outerWidth - 8) / window.innerWidth;
    let zoom_level_pix_ratio = ZOOM_LEVEL_PIX_RATIO;
    if(br_ff.includes (browserName)){
        zoom_level_pix_ratio = ZOOM_LEVEL_PIX_RATIO_FF;
    }else if(br_ie.includes(browserName)){
        zoom_level_pix_ratio = ZOOM_LEVEL_PIX_RATIO_IE;
    }
    for(let i_count=0; i_count<zoom_level_pix_ratio.length; i_count++){
        if(i_count === 0 && screenCssPixelRatio >= zoom_level_pix_ratio[i_count]._key && screenCssPixelRatio <= zoom_level_pix_ratio[i_count]._key){
            zoomLevel = zoom_level_pix_ratio[i_count]._v;
            break;
        }else if(screenCssPixelRatio <= zoom_level_pix_ratio[i_count]._key){
            zoomLevel = zoom_level_pix_ratio[i_count]._v;
            break;
        }
    }

    let l_getZoomValues = getZoomValues();
    const zoomValue =  l_getZoomValues && l_getZoomValues.zoom ? l_getZoomValues.zoom : '0';
    const zoomInfo = {
        zoomLevel: isNaN(zoomLevel) ? zoomValue : zoomLevel,
        screenCssPixelRatio: screenCssPixelRatio,
        actualZoomLevel : zoomValue,
        actualScreenCssPixelRatio : l_getZoomValues && l_getZoomValues.device ? l_getZoomValues.device : '1',
    }
    return zoomInfo;
}

export const getScreenDetails = (isUserFBgameroom, platform) => {
    let w, h;
    const zoom = checkZoom();
    console.log('zoom.screenCssPixelRatio:::', JSON.stringify(zoom));
    let cal_width = (document.body.clientWidth - scrMargin) * zoom.screenCssPixelRatio;
    let normal_width = (document.body.clientWidth - scrMargin);
    if(browserName === 'Firefox'){
        //cal_width = cal_width;
        if(cal_width <= getMinWidth()){
            cal_width = getMinWidth();
        }
        switch(zoom.zoomLevel){
            case -5:
                cal_width = cal_width/3.5;
                break;
            case -4:
                cal_width = cal_width/2.5;
                break;
            case -3:
                cal_width = cal_width/2;
                break;
            case -2:
                cal_width = cal_width/1.7;
                break;
            case -1:
                cal_width = cal_width/1.5;
                break;
            default:
                cal_width = cal_width;
        }
        w = zoom.zoomLevel >= 0 ? normal_width : cal_width;
    }else if(getOS() !== 'Windows' && browserName === 'Edge'){
        const zoomValue =  zoom.actualZoomLevel;
        console.log('zoomValue::::::::', zoomValue);
        console.log('deviceValue::::::::', zoom.actualScreenCssPixelRatio);
        //cal_width = cal_width;
        if(cal_width <= getMinWidth()){
            cal_width = getMinWidth();
        }
        cal_width = cal_width / zoom.actualScreenCssPixelRatio;
        console.log('Calculated NON WINDOW width::::', cal_width);
        w = zoomValue >= 1 ? normal_width : cal_width;
    }else if (getOS() === 'Windows' && browserName === 'Edge'){
        const zoomValue =  zoom.actualZoomLevel;
        console.log('zoomValue::::::::', zoomValue);
        console.log('deviceValue::::::::', zoom.actualScreenCssPixelRatio);
        //cal_width = cal_width;
        if(cal_width <= getMinWidth()){
            cal_width = getMinWidth();
        }
        cal_width = cal_width*zoom.actualScreenCssPixelRatio;
        console.log('Calculated for WINDOW width::::', cal_width);
        w = zoomValue >= 1 ? normal_width : cal_width;
    }else{
        if(platform===PLATFORM_FB){
            w = (zoom.zoomLevel === 0 || zoom.zoomLevel === 1) ? normal_width : cal_width;
        }else{
            w = (zoom.zoomLevel === 0 || zoom.zoomLevel === 1 || zoom.zoomLevel === 2 || zoom.zoomLevel === 3 || zoom.zoomLevel === 4) ? normal_width : cal_width;
        }
        console.log('I called defecult also :::', w);
    }
    if(w <= getMinWidth()){
        w = getMinWidth();
    }
    console.log('I Final Called ::: ', w);
    h = w * SCREEN_RATIO;
    if (getOS() === 'Windows') {
        //const min_h = getMinHeight();
        h = isUserFBgameroom ? h - 10 : h;
        //h = h <= min_h ? min_h : h;
    }
    console.log('RESOLUTION ON SCREEN LOAD:::', {renderWidth:`${w}px`, renderHeight:`${h}px`});
    return {renderWidth:`${w}px`, renderHeight:`${h}px`}
}

export const getOnResize = (isUserFBgameroom, platform) => {
    console.log('=====================ICALLED  getOnResize ==========================================================');
    const resolution = getScreenDetails(isUserFBgameroom, platform);
    const canvas = document.getElementsByTagName('canvas') && document.getElementsByTagName('canvas')[0];
    if (canvas) {
        let diff = getDiff();
        let c_w = resolution.renderWidth && parseInt(resolution.renderWidth) - canvasmargin;
        let c_h = c_w * SCREEN_RATIO;
        canvas.width = c_w;
        canvas.height = c_h - 10;
    }
    return  resolution;
}

export const setCustomResolution = (miniW, miniH) => {
    const zoom = checkZoom();
    let cal_width = (document.body.clientWidth - scrMargin) * zoom.screenCssPixelRatio;
    if(br_ff.includes(browserName)){
        cal_width = (document.body.clientWidth - scrMargin) * zoom.screenCssPixelRatio/2;
    }
    const w = zoom.zoomLevel >= 0 ? document.body.clientWidth - scrMargin : cal_width;
    let h = w * SCREEN_RATIO;
    const height = miniW && miniH > 0 ? miniH : h;
    const renderWidth = `${w}px`;
    const renderHeight = `${height}px`;
    const canvas =
        document.getElementsByTagName('canvas') && document.getElementsByTagName('canvas')[0];
    if (canvas) {
        let diff = getDiff();
        canvas.width = w - canvasmargin;
        canvas.height = height - diff;
    }
    return {renderWidth:renderWidth, renderHeight:renderHeight}
}
