'use strict';
import uuid from 'uuid';
import {PLATFORM_GSNCOM} from '../constants/matcher';
const getUrlParameter = (name) => {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

class analyticslog {
    constructor() {
        this.analyticsPayload = {
            eventGroup: 'MINILOBBY',
            eventCode: '',
            eventSubCode: '',
            uuid: '',
            timestamp: Date.now(),
            sessionToken: '',
            deviceId: `WEB_ID:`,
            appVersion: process.env.APP_VERSION,
            eventAttributes: '',
            country: '',
            operating_system: '',
        };
        this.setAnalyticsData = this.setAnalyticsData.bind(this);
    }

    setAnalyticsData(data) {
        this.analyticsPayload.eventGroup = data.eventGroup || 'MINILOBBY';
        this.analyticsPayload.eventCode = data.eventCode || null;
        this.analyticsPayload.eventSubCode = data.eventSubCode || null;
        this.analyticsPayload.uuid = uuid.v1();
        this.analyticsPayload.timestamp = Date.now();
        this.analyticsPayload.sessionToken = data.sessionToken || null;
        this.analyticsPayload.eventAttributes = data.eventAttributes || null;
        this.analyticsPayload.deviceId = `WEB_ID:${data.deviceId || null}`;
        this.analyticsPayload.country = data.country || null;
        this.analyticsPayload.operating_system = data.operatingSystem || null;
        this.analyticsPayload.appVersion = data.appVersion ? data.appVersion : (getUrlParameter('appversion') === 'gsncom' ? process.env.GSNCOM_APP_VERSION : process.env.APP_VERSION);
        return this.analyticsPayload;
    }
}
export default analyticslog;
