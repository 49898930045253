/* eslint-disable */
'use strict';
//import { SeamService } from 'seam-sdk-client-javascript';
import adsData from './tests/ads_mock.json';
import _ from 'lodash';
import promises from 'bluebird';
import { FC_ADS_TOGGLE, FC_ADS_REFRESH_RATE, FC_MINI_GAME_MATH, FC_MINI_GAME_MAX_TOKENS, FC_EVENTS_ENGINEERING, FC_ADS_WHITELIST, FC_CONSENT_CONFIG, FC_ADS_RewardVideo, PLATFORM_GSNCOM, FC_SHOW_HILO, FC_LOADING_GAMESLIST, FC_WEBGL_ROLLOUT } from '../constants/matcher';
// eslint-disable-next-line import/no-webpack-loader-syntax
import exec from 'script-loader!./common/seamSdk/index-min.js';
import uuid from 'uuid';
import { async } from 'rxjs/internal/scheduler/async';
import {getOptionalBuild} from '../constants/loadOptionalBuild';

const findItems = (data, criteria) => _.find(data, ['_key', criteria]);

class SeamServices {
    constructor() {
        this._seamService = new SeamService(process.env.SEAM_ENVIRONMENT);
        this._noSeamError = 'No SEAM Service Available';
        this._noSeamErrorAuthFail = 'SEAM Service Authentication Failed';
        this._deviceRegError = 'Error in SEAM Device Registration';
        this._deviceLinkError = 'Error in SEAM Device Linking';
        this._deviceGameTokenError = 'Error in SEAM Game token';
        this._updateTokenBalanceError = 'Error in SEAM token updation';
        this._getTokenBalanceError = 'Error in getting SEAM token Balance';
        this._getGDPR = 'Error in getting GDPR country Code';
        this._getCCPA = 'Error in getting CCPA Retrive';
        this._getFCError = 'Error in getting FC';
        this._getEngineeringEventFlag = false;
        this.authorizeSeam = this.authorizeSeam.bind(this);
        this._seamSlots = slots;
        this._seamStore = store;
        this.responseGame = null;
        this.getSeam = this.getSeam.bind(this);
        this.getSeamUserData = this.getSeamUserData.bind(this);
        this.tokenBalance = this.tokenBalance.bind(this);
        this.getLogAnalytics = this.getLogAnalytics.bind(this);
        this.registerDevice = this.registerDevice.bind(this);
        this.registerDeviceGsnCom = this.registerDeviceGsnCom.bind(this);
        this.linkDevice = this.linkDevice.bind(this);
        this.setTokenBalance = this.setTokenBalance.bind(this);
        this.getUserGameToken = this.getUserGameToken.bind(this);
        this.checkSeamUserMigration = this.checkSeamUserMigration.bind(this);
        this.getFeatureConfigEngineering = this.getFeatureConfigEngineering.bind(this);
        this.getCountriesWhereAdsCanRefresh = this.getCountriesWhereAdsCanRefresh.bind(this);
        this.userConsentInfo = this.userConsentInfo.bind(this);
        this.userConsentInfoGSNCOM = this.userConsentInfoGSNCOM.bind(this);
        this.recordUserConsent = this.recordUserConsent.bind(this);
        this.getOrderSignature = this.getOrderSignature.bind(this);
        this.getUnityAppVersion = this.getUnityAppVersion.bind(this);
    }
    authorizeSeam(facebookResposne) {
        return new Promise((resolve, reject) => {
            if (!this._seamService) {
                reject(this._noSeamError);
                return;
            }
            else {
                const credentials = [
                    {
                        type: process.env.AUTH_PROVIDER,
                        facebookId: facebookResposne.authResponse.userID,
                        accessToken: facebookResposne.authResponse.accessToken,
                    },
                ];
                this._seamService.authenticateCanvasUser(credentials, (data) => {
                    if (data._seamResponse._statusCode ===200 || data._seamResponse._statusCode ===201) {
                        resolve(JSON.parse(data._seamResponse._json));
                    } else {
                        reject(new Error(this._noSeamErrorAuthFail));
                    }
                });
            }
        });
    }
    authorizeSeamGSNCom(gsnComResponse) {
        return new Promise((resolve, reject) => {
            if (!this._seamService) {
                reject(this._noSeamError);
                return;
            }
            else {
                const userModel = gsnComResponse;
                this._seamService.seam_user_client.postUsersAuthenticate(userModel, (data) => {
                    if (data._seamResponse._statusCode === 200 || data._seamResponse._statusCode === 201 || data._seamResponse._statusCode === 204) {
                        resolve(JSON.parse(data._seamResponse._json));
                    } else {
                        const msg = data && data._seamResponse && data._seamResponse._json && JSON.parse(data._seamResponse._json);
                        const code = data && data._seamResponse && data._seamResponse._statusCode;
                        const error = {
                            _statusCode: code ? code : '500',
                            _message: msg ? msg.message : 'Error in autherization',
                        }
                        reject(error);
                    }
                });
            }
        });
    }
    changeEmailPassword(payload, sessionToken) {
        return new Promise((resolve, reject) => {
            if (!this._seamService) {
                reject(this._noSeamError);
                return;
            }
            else {
                const ChangeEmailPasswordModel = payload;
                this._seamService.seam_user_client.changeEmailPassword(ChangeEmailPasswordModel, sessionToken, (data) => {
                    if (data._seamResponse._statusCode === 200 || data._seamResponse._statusCode === 201 || data._seamResponse._statusCode === 204) {
                        resolve();
                    } else {
                        const msg = data && data._seamResponse && data._seamResponse._json && JSON.parse(data._seamResponse._json);
                        const code = data && data._seamResponse && data._seamResponse._statusCode;
                        const error = {
                            _statusCode: code ? code : '500',
                            _message: msg ? msg.message : 'Server error occurred please try later.',
                        }
                        reject(error);
                    }
                });
            }
        });
    }

    checkSeamUserMigration(facebookResposne) {
        return new Promise((resolve, reject) => {
            if (!this._seamService) {
                reject(this._noSeamError);
                return;
            }
            else {
                const pathParams = {};
                const migrationResponse = {
                    status: '',
                    type: ''
                };
                pathParams.getFbid = () => facebookResposne && facebookResposne.authResponse && facebookResposne.authResponse.userID;
                this._seamService.seam_user_client.getCanvasUserByFacebookId(pathParams, async (seamRes) => {
                    migrationResponse.status = await seamRes._seamResponse._statusCode;
                    const seamMigrationStatus = seamRes && seamRes._seamResponse && seamRes._seamResponse._json && JSON.parse(seamRes._seamResponse._json);
                    const statusToResolve = seamMigrationStatus && seamMigrationStatus.migrationStatus;
                    resolve(statusToResolve);
                });
            }
        });
    }

    getUnityAppVersion() {
        let appVersion = getOptionalBuild('facebook');
        if(appVersion == ' ' || appVersion == '' || appVersion==undefined || appVersion==null) {
            appVersion = process.env.APP_VERSION;
        }
        return appVersion;
    }

    getFeatureConfigGlobalToggle() {
        return new Promise((resolve, reject) => {
            if (!this._seamService) {
                reject(this._noSeamError);
                return;
            }
            else {
                const appver = this.getUnityAppVersion();
                const featureContextConfigModel = {
                    keys: [FC_ADS_TOGGLE],
                    context:{"user":{},"device":{"appVersion":appver}}
                }
                this._seamService.seam_featureconfig_client.getContextConfiguration(featureContextConfigModel, (data) => {
                    setTimeout(() => {
                        return resolve(findItems(data._userConfigModel, FC_ADS_TOGGLE))
                    });
                });
            }
        });
    }

    getFeatureConfigDisplayHighLowGame() {
        return new Promise((resolve, reject) => {
            if (!this._seamService) {
                reject(this._noSeamError);
                return;
            }
            else {
                const appver = this.getUnityAppVersion();
                const featureContextConfigModel = {
                    keys: [FC_SHOW_HILO],
                    context:{"user":{},"device":{"appVersion":appver}}
                }
                this._seamService.seam_featureconfig_client.getContextConfiguration(featureContextConfigModel, (data) => {
                    setTimeout(() => {
                        return resolve(findItems(data._userConfigModel, FC_SHOW_HILO))
                    });
                });
            }
        });
    }

    getFeatureConfigGamesList() {
        return new Promise((resolve, reject) => {
            if (!this._seamService) {
                reject(this._noSeamError);
                return;
            }
            else {
                const appver = this.getUnityAppVersion();
                const featureContextConfigModel = {
                    keys: [FC_LOADING_GAMESLIST],
                    context:{"user":{},"device":{"appVersion":appver}}
                }
                this._seamService.seam_featureconfig_client.getContextConfiguration(featureContextConfigModel, (data) => {
                    setTimeout(() => {
                        return resolve(findItems(data._userConfigModel, FC_LOADING_GAMESLIST))
                    });
                });
            }
        });
    }

    getFeatureConfigEngineering() {
        return new Promise((resolve, reject) => {
            if (!this._seamService) {
                reject(this._noSeamError);
                return;
            }
            else {
                const appver = this.getUnityAppVersion();
                const featureContextConfigModel = {
                    keys: [FC_EVENTS_ENGINEERING],
                    context:{"user":{},"device":{"appVersion":appver}}
                }
                this._seamService.seam_featureconfig_client.getContextConfiguration(featureContextConfigModel, (data) => {
                    setTimeout(() => {
                        return resolve(findItems(data._userConfigModel, FC_EVENTS_ENGINEERING))
                    });
                });
            }
        });
    }
    getCountriesWhereAdsCanRefresh() {
        return new Promise((resolve, reject) => {
            if (!this._seamService) {
                reject(this._noSeamError);
                return;
            }
            else {
                const appver = this.getUnityAppVersion();
                const featureContextConfigModel = {
                    keys: [FC_ADS_WHITELIST],
                    context:{"user":{},"device":{"appVersion":appver}}
                }
                this._seamService.seam_featureconfig_client.getContextConfiguration(featureContextConfigModel, (data) => {
                    setTimeout(() => {
                        return resolve(findItems(data._userConfigModel, FC_ADS_WHITELIST))
                    });
                });
            }
        });
    }

    getFeatureConfigRefreshRate() {
        return new Promise((resolve, reject) => {
            if (!this._seamService) {
                reject(this._noSeamError);
                return;
            }
            else {
                const appver = this.getUnityAppVersion();
                const featureContextConfigModel = {
                    keys: [FC_ADS_REFRESH_RATE],
                    context:{"user":{},"device":{"appVersion":appver}}
                }
                this._seamService.seam_featureconfig_client.getContextConfiguration(featureContextConfigModel, (data) => {
                    setTimeout(() => {
                        return resolve(findItems(data._userConfigModel, FC_ADS_REFRESH_RATE))
                    });
                });
            }
        });
    }
    getFeatureConfigConsentConfig() {
        return new Promise((resolve, reject) => {
            if (!this._seamService) {
                reject(this._noSeamError);
                return;
            }
            else {
                const appver = this.getUnityAppVersion();
                const featureContextConfigModel = {
                    keys: [FC_CONSENT_CONFIG],
                    context:{"user":{},"device":{"appVersion":appver}}
                }
                this._seamService.seam_featureconfig_client.getContextConfiguration(featureContextConfigModel, (data) => {
                    setTimeout(() => {
                        return resolve(findItems(data._userConfigModel, FC_CONSENT_CONFIG))
                    });
                });
            }
        });
    }
    getFeatureConfigMiniGameMath() {
        return new Promise((resolve, reject) => {
            if (!this._seamService) {
                reject(this._noSeamError);
                return;
            }
            else {
                const appver = this.getUnityAppVersion();
                const featureContextConfigModel = {
                    keys: [FC_MINI_GAME_MATH],
                    context:{"user":{},"device":{"appVersion":appver}}
                }
                this._seamService.seam_featureconfig_client.getContextConfiguration(featureContextConfigModel, (data) => {
                    setTimeout(() => {
                        return resolve(findItems(data._userConfigModel, FC_MINI_GAME_MATH))
                    });
                });
            }
        });
    }
    getFeatureConfigMaxTokens() {
        return new Promise((resolve, reject) => {
            if (!this._seamService) {
                reject(this._noSeamError);
                return;
            }
            else {
                const appver = this.getUnityAppVersion();
                const featureContextConfigModel = {
                    keys: [FC_MINI_GAME_MAX_TOKENS],
                    context:{"user":{},"device":{"appVersion":appver}}
                }
                this._seamService.seam_featureconfig_client.getContextConfiguration(featureContextConfigModel, (data) => {
                    setTimeout(() => {
                        return resolve(findItems(data._userConfigModel, FC_MINI_GAME_MAX_TOKENS))
                    });
                });
            }
        });
    }
    getSeamUserData() {
        if (!this._seamService) {
            return null;
        }
        else {
            return this._seamService.seam_user_data;
        }
    }
    getSeamBaseURL() {
        if (!this._seamService) {
            return null;
        }
        else {
            return this._seamService.environment.endPoint + '/' + this._seamService.environment.stage;
        }
    }

    tokenBalance(seamAuth) {
        return new Promise((resolve, reject) => {
            if (!this._seamService) {
                reject(this._noSeamError);
                return;
            }
            else {
                const params = {};
                params.getIdProvider = () => "seam";
                params.getAppId = () => "classic-casino";
                params.getEntityType = () => "user";
                params.getEntityId = () => seamAuth._id;
                params.getBalanceType = () => "token";
                this._seamService.seam_balance_client.getBalanceWithParams(params, seamAuth.sessionToken, {}, (data) => {
                    if (data && data._balanceV1BalanceResponseModel && data._balanceV1BalanceResponseModel._value >= 0) {
                        setTimeout(() => resolve(data._balanceV1BalanceResponseModel._value));
                    }
                    else {
                        setTimeout(() => reject(this._getTokenBalanceError));
                    }
                });

            }
        });
    }

    setTokenBalance(v, seamAuth) {
        return new Promise((resolve, reject) => {
            if (!this._seamService) {
                reject(this._noSeamError);
                return;
            }
            else {
                const params = {};
                params.getIdProvider = () => "seam";
                params.getAppId = () => "classic-casino";
                params.getEntityType = () => "user";
                params.getEntityId = () => seamAuth._id;
                params.getBalanceType = () => "token";

                const balanceWithParams = {
                    balanceId: null,
                    value: v,
                    clientCreatedDate: new Date(),
                    clientUUID: uuid.v1(),
                    source: 'highLow',
                    gameId: 'highlow',
                };
                this._seamService.seam_balance_client.postAdjustmentWithParams(balanceWithParams, params, seamAuth.sessionToken, {}, (data) => {
                    if (data) {
                        setTimeout(() => resolve(data));
                    }
                    else {
                        setTimeout(() => reject(this._updateTokenBalanceError));
                    }
                });
            }
        });

    }
    getUserGameToken(seamAuth) {
        return new Promise((resolve, reject) => {
            if (!this._seamService) {
                reject(this._noSeamError);
                return;
            }
            else {
                const params = {};
                params.getIdProvider = () => "seam";
                params.getAppId = () => "classic-casino";
                params.getEntityType = () => "user";
                params.getEntityId = () => seamAuth._id;
                params.getBalanceType = () => 'token';
                const pathParams = {
                    "gameId": 'highlow',
                }
                this._seamService.seam_balance_client.getGameAdjustmentsWithParams(params, seamAuth.sessionToken, pathParams, async (data) => {
                    const code = await data._seamResponse._statusCode;
                    if (code === 200 && data && data._balanceV1GameAdjustmentsResponseModel) {
                        const userToken = data._balanceV1GameAdjustmentsResponseModel._tokens;
                        return resolve(userToken);
                    } else {
                        setTimeout(() => reject(this._deviceGameTokenError));
                    }
                });
            }
        });

    }

    registerDevice(fbId) {
        return new Promise((resolve, reject) => {
            if (!this._seamService) {
                reject(this._noSeamError);
                return;
            }
            else {
                const params = {
                    id: `WEB_ID:${fbId}`,
                    internalDeviceId: null,
                    info: {
                        platform: 'web',
                        device: 'Browser',
                        deviceType: 'Desktop',
                        gamesCanvasId: fbId,
                        appVersion: process.env.APP_VERSION
                    }
                };
                this._seamService.seam_devices_client.register(params, (registResp) => {
                    if (registResp && registResp._devicesV1DevicesResponseModel && registResp._devicesV1DevicesResponseModel._id) {
                        setTimeout(() => resolve(registResp._devicesV1DevicesResponseModel._id));
                    } else {
                        setTimeout(() => reject(this._deviceRegError));
                    }
                });
            }
        });
    }

    registerDeviceWithAppid(fbId, appId) {
        return new Promise((resolve, reject) => {
            if (!this._seamService) {
                reject(this._noSeamError);
                return;
            }
            else {
                const params = {
                    id: `WEB_ID:${fbId}`,
                    internalDeviceId: null,
                    info: {
                        platform: 'web',
                        device: 'Browser',
                        deviceType: 'Desktop',
                        gamesCanvasId: fbId,
                        appVersion: appId
                    }
                };
                this._seamService.seam_devices_client.register(params, (registResp) => {
                    if (registResp && registResp._devicesV1DevicesResponseModel && registResp._devicesV1DevicesResponseModel._id) {
                        setTimeout(() => resolve(registResp._devicesV1DevicesResponseModel._id));
                    } else {
                        setTimeout(() => reject(this._deviceRegError));
                    }
                });
            }
        });
    }

    registerDeviceGsnCom(gsnId) {
        return new Promise((resolve, reject) => {
            if (!this._seamService) {
                reject(this._noSeamError);
                return;
            }
            else {
                const params = {
                    id: `WEB_ID:${gsnId}`,
                    internalDeviceId: null,
                    info: {
                        platform: PLATFORM_GSNCOM,
                        device: 'Browser',
                        deviceType: 'Desktop',
                        gamesCanvasId: gsnId,
                        appVersion: process.env.GSNCOM_APP_VERSION,
                    }
                };
                this._seamService.seam_devices_client.register(params, (registResp) => {
                    if (registResp && registResp._devicesV1DevicesResponseModel && registResp._devicesV1DevicesResponseModel._id) {
                        setTimeout(() => resolve(registResp._devicesV1DevicesResponseModel._id));
                    } else {
                        setTimeout(() => reject(this._deviceRegError));
                    }
                });
            }
        });
    }

    registerDeviceGsnComWithAppid(gsnId, appid) {
        return new Promise((resolve, reject) => {
            if (!this._seamService) {
                reject(this._noSeamError);
                return;
            }
            else {
                const params = {
                    id: `WEB_ID:${gsnId}`,
                    internalDeviceId: null,
                    info: {
                        platform: PLATFORM_GSNCOM,
                        device: 'Browser',
                        deviceType: 'Desktop',
                        gamesCanvasId: gsnId,
                        appVersion: appid,
                    }
                };
                this._seamService.seam_devices_client.register(params, (registResp) => {
                    if (registResp && registResp._devicesV1DevicesResponseModel && registResp._devicesV1DevicesResponseModel._id) {
                        setTimeout(() => resolve(registResp._devicesV1DevicesResponseModel._id));
                    } else {
                        setTimeout(() => reject(this._deviceRegError));
                    }
                });
            }
        });
    }

    linkDevice(sessionToken, userId) {
        return new Promise((resolve, reject) => {
            if (!this._seamService) {
                reject(this._noSeamError);
                return;
            }
            else {
                const pathParams = {
                    getDeviceId: () => `WEB_ID:${userId}`,
                }
                this._seamService.seam_devices_client.link(pathParams, sessionToken, (linkResp) => {
                    if (linkResp && linkResp._devicesV1DevicesResponseModel && linkResp._devicesV1DevicesResponseModel.id) {
                        return setTimeout(() => resolve(linkResp));
                    } else {
                        return setTimeout(() => reject(this._deviceLinkError));
                    }
                })
            }
        });
    }

    getLogAnalytics(logData) {
        return new Promise((resolve, reject) => {
            if (!this._seamService) {
                reject(this._noSeamError);
                return;
            }
            else {
                this._seamService.seam_analytics_client.postAnalyticEventHandler(logData, (data) => {
                    setTimeout(() => resolve(data._seamResponse));
                });
            }
        });
    }

    getSeam() {
        if (!this._seamService) {
            return null;
        }
        else {
            return this._seamService;
        }
    }

    handleFacbookPayment(fbPaymentResponse) {
        return new Promise((resolve, reject) => {
            try {
                this._seamService.seam_store_client.parseCanvasFacebookPostOrder(
                    { payment_id: fbPaymentResponse.payment_id },
                    this._seamService.seam_user_data.sessionToken,
                    (response) => {
                        resolve(response);
                    });
            } catch (error) {
                logger.error('GDPR OPTOUT CONSENT', { error: error.stack });
                reject(error);
            }
        })
    }

    validateAndRetrieveHandler(userId, platform) {
        return new Promise((resolve, reject) => {
            try {
                if (!this._seamService) {
                    reject(this._noSeamError);
                    return;
                }
                else {
                    const ValidateAndRetrieveRequestModel = {
                        "fbuid": platform !== PLATFORM_GSNCOM ? userId : null,
                        "gsnUserId": platform === PLATFORM_GSNCOM ? userId : null,
                    };
                    this._seamService.seam_gdpr_client.validateAndRetrieveHandler(ValidateAndRetrieveRequestModel, (data) => {
                        setTimeout(() => resolve(data._validateAndRetrieveResponseModel));
                    });

                }

            } catch (error) {
                reject(error);
            }

        });
    }

    userConsentInfoGSNCOM(credentials, country) {
        return new Promise((resolve, reject) => {
            try {
                if (!this._seamService) {
                    reject(this._noSeamError);
                    return;
                }
                else {
                    const userConsentRequestModel = {
                        credentials : credentials,
                        country : country,
                        timeZone : '',
                    };
                    this._seamService.seam_gdpr_client.getUserConsentInfo(userConsentRequestModel, async(resp) => {
                        const statuscode = await resp._seamResponse && resp._seamResponse && resp._seamResponse._statusCode;
                        if(statuscode === 200){
                            const responseToResplve = JSON.parse(resp._seamResponse._json);
                            setTimeout(() => resolve(responseToResplve));
                        }else{
                            reject(this._getCCPA);
                        }
                    });

                }

            } catch (error) {
                reject(error);
            }

        });
    }
    userConsentInfo(facebookResposne, country) {
        return new Promise((resolve, reject) => {
            try {
                if (!this._seamService) {
                    reject(this._noSeamError);
                    return;
                }
                else {
                    const userConsentRequestModel = {
                        credentials : [{
                            type: process.env.AUTH_PROVIDER,
                            facebookId: facebookResposne.authResponse.userID,
                            accessToken: facebookResposne.authResponse.accessToken,
                        }],
                        country : country,
                        timeZone : '',
                    };
                    this._seamService.seam_gdpr_client.getUserConsentInfo(userConsentRequestModel, async(resp) => {
                        const statuscode = await resp._seamResponse && resp._seamResponse && resp._seamResponse._statusCode;
                        if(statuscode === 200){
                            const responseToResplve = JSON.parse(resp._seamResponse._json);
                            setTimeout(() => resolve(responseToResplve));
                        }else{
                            reject(this._getCCPA);
                        }
                    });

                }

            } catch (error) {
                reject(error);
            }

        });
    }

    recordUserConsent(consent, seamUserId, sessionToken){
        return new Promise((resolve, reject) => {
            try {
                if (!this._seamService) {
                    reject(this._noSeamError);
                    return;
                }
                else {
                    const userConsentRecordModel = {
                        seamUserId : seamUserId,
                        consent : consent,
                    };
                    this._seamService.seam_gdpr_client.recordUserConsent(userConsentRecordModel, sessionToken, async(resp) => {
                        setTimeout(() => resolve(resp));
                    });
                }

            } catch (error) {
                reject(error);
            }

        });
    }

    getCountryCodeByIPHandler() {
        return new Promise((resolve, reject) => {
            try {
                if (!this._seamService) {
                    reject(this._noSeamError);
                    return;
                }
                else {
                    this._seamService.seam_gdpr_client.getCountryCodeByIP((data) => {
                        if(data && data._seamResponse && data._seamResponse._statusCode ===200){
                            setTimeout(() => resolve( JSON.parse(data._seamResponse._json)));
                        }else{
                            reject(this._getGDPR);
                        }
                    });
                }
            } catch (error) {
                reject(error);
            }

        });
    }

    optInHandler(fbdata, platform) {
        return new Promise((resolve, reject) => {
            try {
                if (!this._seamService) {
                    reject(this._noSeamError);
                    return;
                }
                else {
                    const params = {
                        "fbuid": platform !== PLATFORM_GSNCOM  ? fbdata : null,
                        "gsnUserId": platform === PLATFORM_GSNCOM ? fbdata : null
                    };
                    this._seamService.seam_gdpr_client.optInHandler(params, (data) => {
                        setTimeout(() => resolve(data._optInResponseModel));
                    });

                }

            } catch (error) {
                reject(error);
            }

        });
    }

    optOutHandler(fbdata) {
        return new Promise((resolve, reject) => {
            try {
                if (!this._seamService) {
                    reject(this._noSeamError);
                    return;
                }
                else {
                    const params = {
                        "fbuid": fbdata.id
                    };
                    this._seamService.seam_gdpr_client.optOutHandler(params, (data) => {
                        setTimeout(() => resolve(data));
                    });

                }

            } catch (error) {
                reject(error);
            }

        });
    }

    getOrderSignature(payload,sessionToken){
        return new Promise((resolve, reject) => {
            if (!this._seamService) {
                reject(this._noSeamError);
                return;
            }
            else {
                const params = {};
                params.getId = () => payload.item.item_id;
                this._seamService.seam_store_client.getOrderSignature(params, sessionToken, (data) => {
                    resolve(data._orderSignatureModel);
                });

            }
        });
    }
    getRollOutFC(sessionToken){
        return new Promise((resolve, reject) => {
            if (!this._seamService) {
                reject(this._noSeamError);
                return;
            }
            else {
                this._seamService.seam_featureconfig_client.getConfigurationWithParams(sessionToken, {keys:`["${FC_WEBGL_ROLLOUT}"]`}, (data) => {
                    setTimeout(() => resolve(findItems(data._userConfigModel, FC_WEBGL_ROLLOUT)));
                });
            }
        });
    }
}

export default new SeamServices();
