import { connect } from 'react-redux';
import Ccpa from './Ccpa';
import { closeModal } from '../../store/app/actions';

const mapStateToProps = () => ({});

const mapDispatchToProps = { closeModal };

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Ccpa);
