import { connect } from 'react-redux';
import Card from './Card';

const mapStateToProps = (state) => ({
    gameRoundNo: state.app.userConfig.gameRoundNo,
});

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Card);
