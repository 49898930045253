const winOsVersion = (ua)=>{
    let os = 'unknown';
    let osVersion = 'unknown';
    const clientStrings = [
        {s:'Windows 10', r:/(Windows 10.0|Windows NT 10.0)/},
        {s:'Windows 8.1', r:/(Windows 8.1|Windows NT 6.3)/},
        {s:'Windows 8', r:/(Windows 8|Windows NT 6.2)/},
        {s:'Windows 7', r:/(Windows 7|Windows NT 6.1)/},
        {s:'Windows Vista', r:/Windows NT 6.0/},
        {s:'Windows Server 2003', r:/Windows NT 5.2/},
        {s:'Windows XP', r:/(Windows NT 5.1|Windows XP)/},
        {s:'Windows 2000', r:/(Windows NT 5.0|Windows 2000)/},
        {s:'Windows ME', r:/(Win 9x 4.90|Windows ME)/},
        {s:'Windows 98', r:/(Windows 98|Win98)/},
        {s:'Windows 95', r:/(Windows 95|Win95|Windows_95)/},
        {s:'Windows NT 4.0', r:/(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/},
        {s:'Windows CE', r:/Windows CE/},
        {s:'Windows 3.11', r:/Win16/},
    ];
    for (var id in clientStrings) {
        var cs = clientStrings[id];
        if (cs.r.test(ua)) {
            os = cs.s;
            break;
        }
    }

    if (/Windows/.test(os)) {
        osVersion = /Windows (.*)/.exec(os)[1];
        os = 'Windows';
    }
return osVersion;
}

const arch = () => {
    var userAgent = navigator.userAgent
    if ([
      'x86_64',
      'x86-64',
      'Win64',
      'x64;',
      'amd64',
      'AMD64',
      'WOW64',
      'x64_64'
    ].some(function (str) {
      return userAgent.indexOf(str) > -1
    })) {
      return 'x64'
    }
    var platform = navigator.platform
    if (platform === 'MacIntel' || platform === 'Linux x86_64' || platform ==='MacPPC') {
      return 'x64'
    }

    if (navigator.cpuClass === 'x64') {
      return 'x64'
    }

    return 'x86'
  }

export const  getBrowserDetails = () => {
    let browser;
    let version;
    let mobile;
    let os;
    let osversion;
    const ua = window.navigator.userAgent;
    let platform = window.navigator.platform;
    const osBit =arch();

    const browserDetails = {};
    //Internet Explorer
    if (/MSIE/.test(ua)) {
        browser = 'Internet Explorer';
        if (/IEMobile/.test(ua)) {
            mobile = 1;
        }
        version = /MSIE \d+[.]\d+/.exec(ua)[0].split(' ')[1];
        //edge
    } else if (/Trident/.test(ua)) {
        browser = 'Internet Explorer';
        version = ua.substring(ua.indexOf('rv:') + 3, ua.indexOf('.', ua.indexOf('rv:')));
        //edge
    } else if (/Edge/.test(ua)) {
        browser = 'Edge';
        version = /Edge\/[\d\.]+/.exec(ua)[0].split('/')[1];
        // Opera Browser
    }else if (/Edg/.test(ua)) {
        browser = 'Edge';
        version = /Edg\/[\d\.]+/.exec(ua)[0].split('/')[1];
        // Opera Browser
    }  else if (/Android/.test(ua)) {
        browser = 'Android Webkit Browser';
        mobile = 1;
        os = /Android\s[\.\d]+/.exec(ua)[0];
        //Mozilla firefox
    } else if(/FBAV/.test(ua)){
        browser="Facebook Gameroom";
        version=/FBAV\/[\d\.]+/.exec(ua)[0] ? /FBAV\/[\d\.]+/.exec(ua)[0].split('/')[1] : 'UNKNOWN';

    } else if (/OPR/.test(ua)) {
        browser = 'Opera';
        version = /OPR\/[\d\.]+/.exec(ua)[0].split('/')[1];
        // Opera Chrome
    } else if (/Opera/.test(ua)) {
        browser = 'Opera';
        version = /Opera\/[\d\.]+/.exec(ua)[0].split('/')[1];
        // Opera Chrome
    }else if (/Chrome/.test(ua)) {
        //Chromebooks
        if (/CrOS/.test(ua)) {
            platform = 'CrOS';
        }
        browser = 'Chrome';
        version = /Chrome\/[\d\.]+/.exec(ua)[0].split('/')[1];
        // Opera Browser
    } else if (/Firefox/.test(ua)) {
        browser = 'Firefox';
        if (/Fennec/.test(ua)) {
            mobile = 1;
        }
        version = /Firefox\/[\.\d]+/.exec(ua)[0].split('/')[1];
        //Safari
    } else if (/Safari/.test(ua)) {
        browser = 'Safari';
        if (/iPhone/.test(ua) || /iPad/.test(ua) || /iPod/.test(ua)) {
            os = 'iOS';
            mobile = 1;
        }
    }
    if (!version) {
        version = /Version\/[\.\d]+/.exec(ua);
        if (version) {
            version = version[0].split('/')[1];
        } else {
            version = /Opera\/[\.\d]+/.exec(ua)[0].split('/')[1];
        }
    }

    if (platform === 'MacIntel' || platform === 'MacPPC') {
        os = 'Mac OS X';
        osversion = /10[\.\_\d]+/.exec(ua) ? /10[\.\_\d]+/.exec(ua)[0] : 'UNKNOWN';
        if (/[\_]/.test(osversion)) {
            osversion = osversion.split('_').join('.');
        }
    } else if (platform === 'CrOS') {
        os = 'ChromeOS';
    } else if (platform === 'Win32') {
        os = 'Windows';
        osversion = winOsVersion(ua);
    } else if (platform === 'WOW64' || platform == 'Win64') {
        os = 'Windows';
        osversion = winOsVersion(ua);
    } else if (!os && /Android/.test(ua)) {
        os = 'Android';
    } else if (!os && /Linux/.test(platform)) {
        os = 'Linux';
    } else if (!os && /Windows/.test(ua)) {
        os = 'Windows';
    }
    browserDetails.browserName = browser;
    browserDetails.browserVersion = version;
    browserDetails.osName = os;
    browserDetails.osVersion = osversion;
    browserDetails.userAgent = ua;
    browserDetails.platform = platform;
    browserDetails.osBit = osBit;

    console.log('browserDetails', browserDetails);
    return browserDetails;
};

export const getBrowserSupport = (fbGameRoomUser) => {
    const browserDetails = getBrowserDetails();

    const browserName = browserDetails.browserName;
    let browserVersion = browserDetails.browserVersion;
    browserVersion = parseInt(browserVersion, 10);
    console.log(browserVersion);
    //Default flag is true to supported browser
    const browserSupport = {
        type: '',
        flag: true,
        browserDetails: browserDetails,
    };
    //USB - Unsupported Browser
    //SB - Supported Broswer
    //UPB - Updated Browser
    if (browserName === 'Chrome') {
        //browserSupport.type = browserVersion < 75 ? 'UPB' : 'SB';
        browserSupport.type = browserVersion < 69 ? 'UPB' : 'SB';
    } else if (browserName === 'Edge') {
        browserSupport.type = browserVersion < 17 ? 'UPB' : 'SB';
    } else if (browserName === 'Safari') {
        browserSupport.type = browserVersion < 11 ? 'UPB' : 'SB';
    } else if (browserName === 'Firefox') {
        //browserSupport.type = browserVersion < 67 ? 'UPB' : 'SB';
        browserSupport.type = browserVersion < 65 ? 'UPB' : 'SB';
    } else if(browserName==='Facebook Gameroom'){
        browserSupport.type = fbGameRoomUser ? 'SB' : 'USB';
    } else if(browserName==='Opera'){
        browserSupport.type = browserVersion <43 ? 'USB' : 'SB';
    } else {
        alert('Your current browser is not supported.\nWe recommend that you use the latest version of Chrome, Firefox, Microsoft Edge or Safari for the best GSN Casino experience.');
        browserSupport.type = 'USB';
    }
    browserSupport.flag = browserSupport.type === 'SB' ? true : false;
    return browserSupport;
};
