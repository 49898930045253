//@flow
import * as React from 'react';
import css from './style.css';
import { getOS } from '../../managers/resolutionManager';
import PubSub from 'pubsub-js';

type PropsT = {
    reloadApplication: func,
    errType: number,
};
type StateT = {};

export default class CommonError extends React.Component<PropsT, StateT> {
    constructor(props) {
        super(props);
        this.state = {
            message: '',
            isCopied: false,
        };
        this.continueFromError = this.continueFromError.bind(this);
        this.openContactSupport = this.openContactSupport.bind(this);
        this.copyText = this.copyText.bind(this);
    }
    openContactSupport() {
        PubSub.publish('OPEN_CONTACT_SUPPORT', 'NeedHelp');
    }
    async continueFromError() {
        this.props.reloadApplication();
    }
    copyText(){
        navigator.clipboard.writeText('chrome://settings/system');
        this.setState({isCopied:true});
    }
    render() {
        const common_error_image =  process.env.AWS_URL + 'blankscreen-tiny.png?ver=' + process.env.IMAGE_VERSION;
        const os = getOS();
        const ratio  = os === 'Windows' ? 0.47 : 0.5;
        const errorClass = os === 'Windows'
                ? css.lobby_notready_placeholder
                : css.lobby_notready_placeholder_other;
        const errorScreenClass = this.props.errType === 4 ? css.blanck_screen_error : css.other_screen_error;
        const widthErrorScreen = this.props.screensize.width ? (this.props.screensize.width.replace('px','') - 300) : 1101;
        const heightErrorScreen = widthErrorScreen * ratio;

        const errorblank = {
            height: heightErrorScreen + 'px',
            width: widthErrorScreen + 'px',
            backgroundImage: `url(${common_error_image})`
        }
        return (
            <div className={`row ${errorClass} ${errorScreenClass}`}>
                <div className={` col-md-12`}>
                    <div className={`row`}>
                        {(this.props.errType === 2 || this.props.errType === 3) && (
                            <div className={`col-md-12 ${css.text1}`}>
                                Unable to load your lobby.
                            </div>
                        )}
                    </div>
                    <div className={`row`}>
                        <div className={` col-md-12 ${css.text_2}`}>
                            {this.props.errType === 1 && (
                                <div className={css.text_wifi}>
                                    Network Connection Lost. Please re-connect to continue playing.
                                </div>
                            )}
                            {this.props.errType === 2 && (
                                <div className={css.text_seam_err}>
                                    Please Refresh your browser, if it still does not work, Contact
                                    Support Team for help.
                                </div>
                            )}
                            {this.props.errType === 3 && (
                                <div className={css.text_wifi}>
                                    Contact Player Services for help.
                                </div>
                            )}
                            {this.props.errType === 4 &&
                                this.props.browserName ==='Chrome' && (
                                    <div className="row" style={{width:'94%', height:'40%', zIndex:'9'}} >
                                        <div className="col-md-12" >
                                            <div className="row">
                                                <div className={` col-md-9 ${css.text_blankscreen}`}  >
                                                    Your current browser settings cannot run our game. To troubleshoot, please follow the steps below:
                                                </div>
                                                <div className={` col-md-3`}></div>
                                            </div>
                                            <div className="row" style={{position:'relative'}}>
                                                <div className={` col-md-12 ${css.imgblankscreen}`} style={errorblank} >
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                        </div>
                    </div>
                    {(this.props.errType === 1 || this.props.errType === 2 || this.props.errType === 3) && (<div className={`row ${css.button_container}`}>
                        <div className={` col-md-5`} />
                        <div className={` col-md-4`}>
                            <button className={css.button}>
                                {this.props.errType === 1 && (
                                    <div onClick={this.continueFromError}>Refresh</div>
                                )}
                                {(this.props.errType === 2 || this.props.errType === 3) && (
                                    <div onClick={this.openContactSupport}>Need Help</div>
                                )}
                            </button>
                        </div>
                        <div className={` col-md-3`} />
                    </div>)}
                </div>
            </div>
        );
    }
}
