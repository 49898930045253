import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import { setModalState } from '../../../store/app/actions';
import Gdpr from '../../Gdpr';
import Ccpa from '../../Ccpa';
import PayPalCheckOut from '../../PayPalCheckOut';

const actions = { setModalState };
const modalComponentLookupTable = {
    Gdpr,
    Ccpa,
    PayPalCheckOut,
};

const mapState = (state) => ({
    currentModals: state.app.modalQueue,
    isModalOpen: state.app.isModalOpen,
});
export class ModalManager extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalOpen: props.isModalOpen,
        };
        Modal.setAppElement('#app');
    }
    render() {
        const { currentModals } = this.props;
        let renderedModal = null;
        if (currentModals && currentModals.length > 0) {
            // Get the first one from the queue.
            const { modalType, modalProps = {} } = currentModals[0];
            const ModalComponent = modalComponentLookupTable[modalType];
            if (!ModalComponent) return null;
            renderedModal = <ModalComponent {...modalProps} />;
        }
        return renderedModal;
    }
}

export default connect(
    mapState,
    actions
)(ModalManager);
