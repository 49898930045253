import { connect } from 'react-redux';
import HighLowResult from './HighLowResult';
import {
    registerGameCount,
    updateTokenBalance,
    updateWinCount,
    isMiniGameOver,
    calculateTotalWinAmount,
    updateTokensWonPerRound,
} from '../../store/app/actions';

const mapStateToProps = (state) => ({
    noOfTimesPlayed: state.app.userConfig.noOfTimesPlayed,
    maximumTokens: state.app.userConfig.maximumTokens,
    countWin: state.app.userConfig.countWin,
    gameRoundNo: state.app.userConfig.gameRoundNo,
    totalWinAmount: state.app.userConfig.totalWinAmount,
    WebglLoaded: state.app.userConfig.isWebGlLoaded,
    tokensWonPerRound: state.app.userConfig.tokensWonPerRound,
    gameMathModel: state.app.userConfig.gameMathModel,
    seamSession: state.app.userConfig.seamSession,
    gsnCredentials: state.app.userConfig.gsnCredentials,
    fbUserId: state.app.userConfig.fbUserId,
    leftCardValue: state.app.userConfig.leftCardVal,
    rightCardValue: state.app.userConfig.rightCardVal,
    buttonClicked: state.app.userConfig.highorLowBtn,
    seamTokenBalance: state.app.userConfig.seamTokenBalance,
    platform: state.app.platform,
});

const mapDispatchToProps = {
    registerGameCount,
    updateTokenBalance,
    updateWinCount,
    isMiniGameOver,
    calculateTotalWinAmount,
    updateTokensWonPerRound,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HighLowResult);
