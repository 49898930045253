'use strict';
import SeamServices from '../services/seamService';
import logger from '../services/common/logger';
class analyticsManager {
    constructor() {
        if (!analyticsManager.analyticsManagerInstance) {
            analyticsManager.analyticsManagerInstance = this;
        }
        return analyticsManager.analyticsManagerInstance;
    }

    logAnalytics(data) {
        return new Promise((resolve, reject) => {
            SeamServices.getLogAnalytics(data)
                .then(resolve)
                .catch((error) => {
                    logger.error('LOG ANALYTICS',{error: error.stack});
                    reject(error);
                });
        });
    }
}

const analyticsManagerInstance = new analyticsManager();
export default analyticsManagerInstance;
