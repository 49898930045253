// @flow
import * as React from 'react';
import Lobby from '../Lobby';
import css from './style.css';

type PropsT = {
    cookies: any,
};
type StateT = {};

const hostComponentLookupTable = {
    Lobby,
};

export default class Host extends React.Component<PropsT, StateT> {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const properties = this.props;
        let HostComponent = hostComponentLookupTable.Lobby;
        let renderedModal = null;
        let hostProps = {
            cookies: this.props.cookies,
        };
        const hosts = properties.hosts;
        renderedModal = <HostComponent {...hostProps} />;

        if (hosts.length > 0) {
            const newComponentToShow = hosts[0];
            hostProps = { ...newComponentToShow.hostProps, ...this.props };
            HostComponent = hostComponentLookupTable[newComponentToShow.hostType];
            if (!HostComponent) return null;
            renderedModal = (
                <div className={`row ${css['middle-parent-div']}`}>
                        <HostComponent {...hostProps} />;
                </div>
            );
        }
        return renderedModal;
    }
}
