//@flow
import * as React from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import css from './style.css';

type PropsT = {
    reloadApplication: func,
    errType: number,
    closeModal: func,
};
type StateT = {};

export default class ImagePreview extends React.Component<PropsT, StateT> {
    constructor(props) {
        super(props);
        var exampleItems = [...Array(150).keys()].map(i => ({ id: (i+1), name: 'Item ' + (i+1) }));
        this.state = {
            show:false,
            pageCount: 0,
            maxPage: 0,
            minPage: 0,
            displayTooltip: false,
            exampleItems: exampleItems,
            pageOfItems: [],
            pageNo:1,
            gotoPage: undefined,
            lobbyVersion: 'v2',
            tilesInPage: 5
        };
        this.container = React.createRef();
        this.textArea =  React.createRef();
        this.gotInptRef =  React.createRef();
        this.createTilesGroup = this.createTilesGroup.bind(this);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.goto = this.goto.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.updateLobbyVersion = this.updateLobbyVersion.bind(this);
    }
    componentDidMount(){
    }

    next(){
        const pageCount =  this.state.pageCount+1;
        const pageNo =  this.state.pageNo;
        if(this.state.maxPage > pageCount){
            console.log("No Next Page pageCount = "+ pageCount + " pageNo = "+pageNo);
            this.setState({pageCount: pageCount});
            this.setState({pageNo: pageNo+1});
            this.clearGoto();
            this.createTilesGroup(pageCount);
        }else{
            console.log("No Next Page pageCount = "+ pageCount + " pageNo = "+pageNo);
        }
    }

    updateLobbyVersion(e) {
        const newVal = e.target.value;
        this.setState({ lobbyVersion: newVal });
        if ( newVal === 'v3a' ) {
            this.setState({ tilesInPage: 4 });
        } else if ( newVal === 'v3b' ) {
            this.setState({ tilesInPage: 3 });
        } else {
            this.setState({ tilesInPage: 5 });
        }
    }

    previous(){
        const pageCount =  this.state.pageCount === 0 ? this.state.pageCount : this.state.pageCount -1;
        const pageNo =  this.state.pageNo;
        if(this.state.minPage <= pageCount){
            console.log("No Next Page pageCount = "+ pageCount + " pageNo = "+pageNo);
            this.setState({pageCount: pageCount});
            this.setState({pageNo: pageNo >1 ? pageNo-1 : 1});
            this.clearGoto();
            this.createTilesGroup(pageCount);
        }
    }

    goto(){
        if(isNaN(this.gotInptRef.current.value)){
            console.log ("Paage number should be integer" );

        }else if(this.gotInptRef.current.value > 0){
            const gotoPage =  this.gotInptRef.current.value -1;
            console.log ("gotoPage = "+gotoPage );
            if(gotoPage < this.state.maxPage && gotoPage >=   this.state.minPage){
                console.log("maxPage = "+ this.state.maxPage + " minPage = "+this.state.minPage);
                this.setState({pageCount: gotoPage});
                this.setState({pageNo: gotoPage+1});
                this.createTilesGroup(gotoPage);
            }else{
                console.log ("Sorry page no in range.");
            }
        }else{
            console.log ("Sorry page no in range.");
        }


    }

    clearGoto(){
        this.gotInptRef.current.value =  "";
    }

    handleChange (event) {
        this.setState({gotoPage: event.target.value});
    }

    createTilesGroup(pageCount){
        this.setState({show:true});
        while (this.container.current.firstChild) {
            this.container.current.removeChild(this.container.current.firstChild);
        }
        var textValue, data, finalData = [];
        try {
            textValue = JSON.parse(this.textArea.current.value);
            //remove promotile and take only game tiles in new lobby v3
            if(textValue && textValue.dashboardTiles && this.state.lobbyVersion !== 'v2') {
                textValue.dashboardTiles.forEach((eachtile) => {
                    if(eachtile && eachtile.hasOwnProperty('GameID')) {
                        finalData.push(eachtile);
                    }
                })
            }else{
                finalData = textValue.dashboardTiles;
            }
            data = finalData && this.getConfigurableTileInGroup(finalData);
        } catch (error) {
            console.log("This json is not in correct format!", error)

        }

        if(!data){
            console.log("not a correct format");
            this.container.current.appendChild(this.createError());

        }else{
            const maxLength = data.length%this.state.tilesInPage === 0 ? parseInt(data.length/this.state.tilesInPage) : parseInt(data.length/this.state.tilesInPage)+1;
            const groupArr = this.chunkMaxLength(data, this.state.tilesInPage,maxLength );
            const tileData = pageCount ? groupArr[pageCount] : groupArr[0];

            this.container.current.appendChild(this.createRowElement(tileData, pageCount ? pageCount : 0));

            this.setState({maxPage:maxLength});
        }

    }

    createError(){
        const rowEle = document.createElement("div");
        rowEle.className = `row ${css.marginLeft}`;
        rowEle.appendChild(document.createTextNode("ERROR: Unsupported or incorrect JSON input."));
        rowEle.className=`${css.error}`;
        return rowEle;
    }

    createRowElement(tilesData, pageCount){
        const rowEle = document.createElement("div");
        rowEle.className = `row ${css.marginLeft}`;
        for(var c = 0; c < tilesData.length; c++){
            rowEle.appendChild(this.createColElement(tilesData[c],c+pageCount*this.state.tilesInPage));
        }
        return rowEle;
    }
    createColElement(tileData,index){
        const colEle = document.createElement("div");
        if(!Array.isArray(tileData)){
            const eleCircle = document.createElement("div");
            eleCircle.className=`${css.numberCircle}`;
            eleCircle.appendChild(document.createTextNode(index+1));
            colEle.append(eleCircle);

            const  colFullTile = document.createElement("div");
            colFullTile.className = `${css.fullTile}`;

            const pre = document.createElement("pre");
            pre.innerHTML = JSON.stringify(tileData, undefined, 2);
            if( tileData && tileData.HasJackpot ) {
                pre.className = `${css.highlightJackpot} ${css.preEle}`;
            }else{
                pre.className = `${css.preEle}`;
            }
            pre.id = "col"+index;
            colFullTile.appendChild(pre);
            colEle.className = `${css.fullTileWithHeader}`;
            colEle.style.width = 98.83385/this.state.tilesInPage + '%';
            colEle.appendChild(colFullTile);
        }else{
            const eleCircle = document.createElement("div");
            eleCircle.className=`${css.numberCircle}`;
            eleCircle.appendChild(document.createTextNode(index+1));
            colEle.append(eleCircle);

            const  colHalfTile1 = document.createElement("div");
            const pre1 = document.createElement("pre");
            pre1.innerHTML = JSON.stringify(tileData[0], undefined, 2);
            colHalfTile1.appendChild(pre1);
            if( tileData[0] && tileData[0].HasJackpot ) {
                pre1.className = `${css.highlightJackpot} ${css.preEle}`;
            }else{
                pre1.className = `${css.preEle}`;
            }
            colHalfTile1.className = `${css.halfTile1}`;
            const  colHalfTile2 = document.createElement("div");
            const pre2 = document.createElement("pre");
            pre2.innerHTML = tileData[1] ? JSON.stringify(tileData[1], undefined, 2) : "Undefined Tile.";
            if( tileData[1] && tileData[1].HasJackpot ) {
                pre2.className = `${css.highlightJackpot} ${css.preEle}`;
            }else{
                pre2.className = `${css.preEle}`;
            }
            colHalfTile2.appendChild(pre2);
            colHalfTile2.className =  tileData[1] ? `${css.halfTile2}` : `${css.halfTile2} ${css.blankTile}`;
            colEle.className = `${css.fullTileWithHeader}`;
            colEle.style.width = 98.83385/this.state.tilesInPage + '%';
            colEle.appendChild(colHalfTile1);
            colEle.appendChild(colHalfTile2);
        }

        return colEle;
    }

    chunkMaxLength(arr, chunkSize, maxLength){
        return Array.from({length: maxLength}, () => arr.splice(0,chunkSize));
    }

    getConfigurableTileInGroup(arr){
        const tileArr = [];
        var prevTileCompleted = true;
        var halfTile = [];
        arr.forEach(element => {
            if((element.HalfTile === undefined || element.HalfTile === false) && prevTileCompleted){
                tileArr.push(element);
                prevTileCompleted = true;
            } else if(element.HalfTile === true) {
                halfTile.push(element);
                if(halfTile.length === 2){
                    tileArr.push(halfTile);
                    prevTileCompleted = true;
                    halfTile=[];
                } else {
                    prevTileCompleted = false;
                }
            } else {
                tileArr.push(halfTile);
                halfTile=[];
                tileArr.push(element)
                prevTileCompleted = true;
            }
        });

        return tileArr;
    }

    render() {
        const show = this.state.show;
        return (
            <div className={`row`} style={{backgroundColor:'#672846', height:'100%', color: 'white'}}>
                <div className="col-md-12">
                    <div className={`row ${css.fontfamily}`}>
                        <div className={`col-md-1`} onClick={ () => this.previous()}>
                            <span style={{position: 'relative', top: '30%', fontSize:'5rem', fontWeight:'bold',color: 'white'}}> &#8678;  </span>
                        </div>
                        <div className={` col-md-10 `}>
                            <div className={`row`}>
                                <div className={`col-md-12 ${css.headerArea}`}>
                                    Dashboard Tiles Previewer
                                </div>
                            </div>
                            <div className={`row`}>
                                <div className={`col-md-12 ${css.headerArea}`}>
                                    Select Lobby version:
                                    <select className={`${css.dropdown}`} onChange={this.updateLobbyVersion} >
                                        <option value="v2">Lobby Version V2</option>
                                        <option value="v3a">Lobby Version V3 16*9</option>
                                        <option value="v3b">Lobby Version V3 4*3</option>
                                        select
                                    </select>
                                </div>
                            </div>
                            <div className={`row ${ !show ? css.show : css.block}`} >
                                <div id="container" className={`col-md-12 ${css.tilesPreviewArea}`}>
                                    Preview tiles Area
                                </div>
                            </div>
                            <div className={`${ show ? css.show : css.block}`} ref={this.container} ></div>
                        </div>
                        <div className={`col-md-1`} onClick={ () => this.next()}><span style={{position: 'relative', top: '30%', fontSize:'5rem', fontWeight:'bold', color: 'white'}}>&#8680;</span></div>
                    </div>
                    <div className={`row ${ show ? css.show : css.block}`}>
                        <div className="col-md-3"></div>
                        <div className={'col-md-1'} style={{textAlign: 'center'}}> <button type="button" className={`btn btn-link`} style={{fontWeight:'bold', color: 'white'}} onClick={ () => this.previous()}>&laquo; Previous</button> </div>
                        <div className={'col-md-1'} style={{textAlign: 'center'}}> <button type="button" className={`btn btn-link`} style={{fontWeight:'bold', color: 'white'}}>Current Page: {this.state.pageNo} </button></div>
                        <div className={'col-md-1'} style={{textAlign: 'center'}}> <button type="button" className={`btn btn-link`} style={{fontWeight:'bold', color: 'white'}}>Total Pages: {this.state.maxPage} </button></div>
                        <div className={'col-md-1'} style={{textAlign: 'center'}}> <button type="button" className={`btn btn-link`} style={{fontWeight:'bold', color: 'white'}} onClick={ () => this.next()}>Next&raquo;</button> </div>
                        <div className={'col-md-1'} style={{textAlign: 'center'}}> <button type="button" className={`btn btn-link`} style={{fontWeight:'bold', color: 'white'}} onClick={ () => this.goto()}>GO TO</button> </div>
                        <div className={'col-md-1'} style={{textAlign: 'center'}}> <input type="text" className={`form-control`} id="goto" ref={this.gotInptRef}/></div>
                        <div className="col-md-3"></div>
                    </div>
                    <div className={`row`}>
                        <div className={`col-md-1`}></div>
                        <div className={`col-md-10`}>
                            <TextareaAutosize  minRows={2} maxRows={9} style={{width: '100%', color: 'black'}} ref={this.textArea}/>
                        </div>
                        <div className={`col-md-1`}></div>
                    </div>

                    <div className={`row`}>
                        <div className={`col-md-9`}></div>
                        <div className={`col-md-2`}>
                            <button type="button" style={{width: '100%', color: 'white', backgroundColor:'green'}} onClick={() => this.createTilesGroup()}>Proceed</button>
                        </div>
                        <div className={`col-md-1`}></div>
                    </div>

                </div>
            </div>

        );
    }
}
