/* eslint-disable */
'use strict';
import SeamServices from '../services/seamService';

class GdprServices {
    constructor() {
        this._noGDPRDataError = 'No data in fb signed request';
        this.optInHandler = this.optInHandler.bind(this);
        this.optOutHandler = this.optOutHandler.bind(this);
    }

    optInHandler(fbData, platform){
        return SeamServices.optInHandler(fbData, platform).then(data=>{
            return data;
        })
    }

    optOutHandler(fbData){
        return SeamServices.optOutHandler(fbData).then(data=>{
            return data;
        })
    }

}

export default new GdprServices();
