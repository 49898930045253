import React from 'react';
import {createBrowserHistory} from 'history';
import { hydrate } from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter as Router, routerMiddleware } from 'react-router-redux';
import App from '../shared/App';
import ErrorBoundary from '../shared/components/ErrorBoundary'
import { configureStore } from '../shared/store';
import 'bootstrap/dist/css/bootstrap.min.css';
import { CookiesProvider } from 'react-cookie';
const browserHistory = window.browserHistory || createBrowserHistory();
const reactStore =
    window.reactStore ||
    configureStore({
        initialState: window.__PRELOADED_STATE__,
        middleware: [routerMiddleware(browserHistory)],
    });

hydrate(
    <ErrorBoundary>
        <CookiesProvider>
            <Provider store={reactStore}>
                <Router history={browserHistory}>
                    <App />
                </Router>
            </Provider>
        </CookiesProvider>
    </ErrorBoundary>,
    document.getElementById('app')
);

if (process.env.NODE_ENV === 'development') {
    if (module.hot) {
        module.hot.accept();
    }

    if (!window.reactStore || !window.browserHistory) {
        window.browserHistory = browserHistory;
        window.reactStore = reactStore;
    }
}

