import { connect } from 'react-redux';
import Navigation from './Navigation';
import { isMiniGameOver, updateTokenBalance } from '../../store/app/actions';

const mapStateToProps = (state) => ({
    noOfTimesPlayed: state.app.userConfig.noOfTimesPlayed,
    isWebGlLoaded: state.app.userConfig.isWebGlLoaded,
    gameIsOver: state.app.userConfig.gameIsOver,
    gameRoundNo: state.app.userConfig.gameRoundNo,
});

const mapDispatchToProps = { isMiniGameOver, updateTokenBalance };

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Navigation);
