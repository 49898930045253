//@flow
import * as React from 'react';
import css from './style.css';

type StateT = {};
export default class MiniLobbyLoader extends React.Component<PropsT, StateT> {
    constructor(props) {
        super(props);
        this.imageBG = null;
    }
    componentDidMount() {
        const bgImage =  process.env.AWS_URL + 'lobby-loading-screen-tiny.jpg?ver=' + process.env.IMAGE_VERSION;
        //if (this.state.isEuUser && count===1) this.props.openModal('Gdpr', {});
        const bgLoaderImg = new Image();
        bgLoaderImg.src = bgImage;
        bgLoaderImg.onload = () => {
            this.imageBG && this.imageBG.setAttribute(
                'style',
                `background-image: url('${bgImage}'); background-size: 100% 100%; background-repeat: no-repeat;`
            );
            this.imageBG && this.imageBG.classList.add(css['iron-image-fade-in']);
        }
    }

    render() {
        const LoadingContainer = {
            position: 'relative',
            top: '1%',
            left: '0',
            width: '100%',
            height: '100%',
            zIndex: '9001',
        }
        return (
            <div id="app-loading-container" className={css[' iron-image-container']} style={LoadingContainer}>
                {/* <div className={css['app-loading-backdrop']} id="app-loading-backdrop" /> */}

                <div
                    className={css["iron-image-loaded"]}
                    ref={imageLoadedElem => this.imageBG = imageLoadedElem}>
                </div>

                <div
                    className={css["iron-image-preload"]}
                //style={{ backgroundImage: `url('${bgImage}')` }}
                >
                </div>
            </div>
        );
    }
}
