import { connect } from 'react-redux';
import ProgressBar from './ProgressBar';
const mapStateToProps = (state) => {
    return { isWebGlLoaded: state.app.userConfig.isWebGlLoaded };
};
const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProgressBar);
