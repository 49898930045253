import { connect } from 'react-redux';
import AdsHome from './AdsHome';
import { closeModal, setRefreshRate, getRefreshRate } from '../../store/app/actions';
const mapStateToProps = (state) => {
    return {
        seamSession: state.app.userConfig.seamSession,
        refreshRate: state.app.userConfig.refreshRate,
    };
};

const mapDispatchToProps = {
    closeModal,
    setRefreshRate,
    getRefreshRate
 };

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AdsHome);
