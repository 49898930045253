'use strict';
import { Logger, LOG_LEVEL } from 'beaver-logger';

const $logger = Logger({
    prefix: process.env.CLIENT_LOG_IDENTIFIER,
    logLevel: LOG_LEVEL.INFO,
    url: '/client/log',
});

export default $logger;
